"use strict";
export const ALABAMA = "Alabama";
export const AL = "Al";
export const GEORGIA = "Georgia";
export const GE = "Ga";
export const FLORIDA = "Florida";
export const FL = "Fl";
export const MISSOURI = "Missouri";
export const MI = "Mo";
export const MISSISSIPPI = "Mississippi";
export const MS = "Ms";
export const SOUTH_CAROLINA = "SouthCarolina";
export const SC = "Sc";
export const MINNESOTA = "Minnesota";
export const MN = "Mn";
export const COLORADO = "Colorado";
export const CO = "Co";
export const KANSAS = "Kansas";
export const KS = "Ks";
export const TEXAS = "Texas";
export const TX = "Tx";
export const WISCONSIN = "Wisconsin";
export const WI = "Wi";
export const UTAH = "Utah";
export const UT = "Ut";
export const NEVADA = "Nevada";
export const NE = "Nv";
export const NEWMEXICO = "NewMexico";
export const NM = "Nm";
// Ids of states
export const ALABAMA_ID = "AL";
export const GEORGIA_ID = "GA";
export const FLORIDA_ID = "FL";
export const MISSOURI_ID = "MO";
export const MISSISSIPPI_ID = "MS";
export const SOUTH_CAROLINA_ID = "SC";
export const MINNESOTA_ID = "MN";
export const COLORADO_ID = "CO";
export const KANSAS_ID = "KS";
export const TEXAS_ID = "TX";
export const WISCONSIN_ID = "WI";
export const UTAH_ID = "UT";
export const NEVADA_ID = "NV";
export const NEWMEXICO_ID = "NM";

// need to update this array if new state added
export const stateArray = {
    al: ALABAMA,
    alabama: ALABAMA,
    georgia: GEORGIA,
    ga: GEORGIA,
    florida: FLORIDA,
    fl: FLORIDA,
    missouri: MISSOURI,
    mo: MISSOURI,
    mississippi: MISSISSIPPI,
    ms: MISSISSIPPI,
    southcarolina: SOUTH_CAROLINA,
    sc: SOUTH_CAROLINA,
    minnesota: MINNESOTA,
    mn: MINNESOTA,
    colorado: COLORADO,
    co: COLORADO,
    kansas: KANSAS,
    ks: KANSAS,
    texas: TEXAS,
    tx: TEXAS,
    wisconsin: WISCONSIN,
    wi: WISCONSIN,
    ut: UTAH,
    utah: UTAH,
    nevada: NEVADA,
    nv: NEVADA,
    newmexico: NEWMEXICO,
    nm: NEWMEXICO
};

// need to update this array if new state added
export const getVisibleNameStateArray = {
    alabama: ALABAMA,
    al: ALABAMA,
    georgia: GEORGIA,
    ga: GEORGIA,
    florida: FLORIDA,
    fl: FLORIDA,
    missouri: MISSOURI,
    mo: MISSOURI,
    mississippi: MISSISSIPPI,
    ms: MISSISSIPPI,
    southcarolina: SOUTH_CAROLINA,
    sc: SOUTH_CAROLINA,
    minnesota: MINNESOTA,
    mn: MINNESOTA,
    colorado: COLORADO,
    co: COLORADO,
    kansas: KANSAS,
    ks: KANSAS,
    texas: TEXAS,
    tx: TEXAS,
    wisconsin: WISCONSIN,
    wi: WISCONSIN,
    utah: UTAH,
    ut: UTAH,
    nevada: NEVADA,
    nv: NEVADA,
    newmexico: NEWMEXICO,
    nm: NEWMEXICO    
};
