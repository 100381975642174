import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subscription } from "rxjs";
import { Constants } from "../../Constants/Constants";
import { BasicConfigModel } from "../../Models/BasicConfigModel";
import { EndorsementModel } from "../../Models/EndorsementModel";
import { RateTypeModel } from "../../Models/RateTypeModel";
import { ShareCalculatedPremiumModel } from "../../Models/ShareCalculatedPremiumModel";
import { SharePremiumDataModel } from "../../Models/SharePremiumDataModel";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";

@Component({
    selector: "app-owner",
    templateUrl: "./owner.component.html",
    styleUrls: ["./owner.component.css"],
    // styles: ['owner.component.css']
})
export class OwnerComponent implements OnInit, OnDestroy {

    // @Output()
    //     onOwnerTotalAmountChanged: EventEmitter<number> = new EventEmitter<number>();
    ownerRateTypes: Array<RateTypeModel> = [];
    @Input()
    basicConfigJson: BasicConfigModel;
    ownerTotalAmount = 0;
    coverageAmount = 10;
    ownerEndorsement: Array<EndorsementModel>;
    selectedEndorsementsArray: Array<EndorsementModel> = [];
    tempOwnerEndorsements: Array<EndorsementModel> = new Array<EndorsementModel>();
    tempOwnerRatesArray: Array<RateTypeModel> = [];
    hideOwnerPriorPolicyInput = false;
    selectedEndorsementsIdsArray = "";
    endorsementsTotal = 0;
    priorPolicyAmount = 0;
    loanAmount = 0;
    selectedRateTypeModel: RateTypeModel = new RateTypeModel();
    selectedRateTypeCode = "";
    selectedRateType = "";
    selectedRateTypeIndex = 0;
    ownerPremiumAllData: SharePremiumDataModel = new SharePremiumDataModel();
    calculatedPremiumData: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    maxEffectiveDate: NgbDateStruct = null;
    minEffectiveDate: NgbDateStruct = null;
    effectiveDate: any;
    effectiveDateInString: string;
    showComponent = true;
    selectedPropertyType = "";
    disableCoverageAmount = false;
    disableRateType = false;
    disableEndorsementBtn = false;
    premiumCalculated = false;
    fromRateType = false;
    resetFieldsFromThis = false;
    selectedEndorsementsIds: Array<string> = [];
    selectedRegionId: string;

    endorsementDataSubscription: Subscription;
    getSelectedEndorsementsSubscription: Subscription;
    rateTypeDataSubscripton: Subscription;
    onLoanDataChangedSubscription: Subscription;
    onApiSuccessCalculatedPremiumSubscription: Subscription;
    reselAllFieldsSubscription: Subscription;
    resetFieldsForCalculatedPremiumSubscription: Subscription;
    selectedRegionSubscription: Subscription;
    loanSimultaneous = "";

    constructor(public commonFunctions: CommonFunctions,
        public dataService: DataServiceService,
        public cdr: ChangeDetectorRef,
        public constants: Constants,
        @Inject(LOCAL_STORAGE) public localStorage: WebStorageService) {

        this.calculatedPremiumData = new ShareCalculatedPremiumModel();

    }

    ngOnInit() {
        const date = new Date();
        this.maxEffectiveDate = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
        this.minEffectiveDate = { year: 1950, month: 1, day: 1 };
        getSelectedRegion(this);
        getAndSetOwnerRateType(this, false);
        getAllEndorsements(this);
        getSelectedEndorsements(this);

        getRateType(this);
        getLoanChangedData(this);
        getCalculatedPremium(this);
        registerResetAllFields(this);
        resetFields(this);
        registerResetAllFieldsForCal(this);
    }

    onOwnerOpenEndorsementClick(event) {
        getSelectedEndorsementsIdsFromLocalStorage(this);
        const endorsementsWithTypeFlag = {
            endorsements: this.ownerEndorsement,
            endorsementType: this.constants.endorsementTypeOwner,
            tempEndorsement: this.tempOwnerEndorsements,
            selectedEndorsementsIds: this.selectedEndorsementsIds
        };
        this.dataService.onShowEndorsementDataChanged(JSON.stringify(endorsementsWithTypeFlag));
    }

    onOwnerRateTypeDropDownChangedChanged(index) {
        setAndGetEndorsements(false, "", this);
        this.onOwnerRateTypeChanged(index);
    }
    onOwnerRateTypeChanged(index) {
        if (this.ownerRateTypes.length > 0) {
            this.fromRateType = true;
            this.resetDataAfterCalcuatePremium(true);
            this.selectedRateTypeModel = this.ownerRateTypes[index];
            this.hideOwnerPriorPolicyInput = !this.selectedRateTypeModel.priorPolicyAmountFlag;
            this.selectedRateTypeCode = this.selectedRateTypeModel.ownerRateTypeCode;
            this.selectedRateType = this.selectedRateTypeModel.description;
            if (this.hideOwnerPriorPolicyInput) {
                this.priorPolicyAmount = 0;
            }
            if (!this.selectedRateTypeModel.priorPolicyDateFlag) {
                this.effectiveDateInString = "";
            }
            this.calculateAndSendTotalAmount();
        }
    }

    onCoverageAmountChange(value) {
        this.fromRateType = false;
        this.resetDataAfterCalcuatePremium(false);
        this.commonFunctions.printLog("basic Config " + JSON.stringify(this.basicConfigJson), true);
        this.coverageAmount = value;
        checksimo(this);

    }



    onPriorPolicyAmountChange(value) {
        this.fromRateType = false;
        this.resetDataAfterCalcuatePremium(false);
        this.priorPolicyAmount = value;
        this.calculateAndSendTotalAmount();
    }

    onEffectiveDateChanged(value) {
        this.resetDataAfterCalcuatePremium(false);
        getEffectiveDate(this, value);
        this.calculateAndSendTotalAmount();
    }

    onEndorsementAmountChange(value) {
        this.endorsementsTotal = this.endorsementsTotal;
        this.calculateAndSendTotalAmount();
    }

    calculateAndSendTotalAmount() {
        const json = createJsonForSendDataToStatePage(this);
        this.dataService.onOwnerDataChanged(json);
    }

    onShowHideClick(event) {
        this.showComponent = !this.showComponent;
    }
    onShowCalculatedEndorsementClick(event) {
        const calculatedEndorsements = this.commonFunctions.getEndorsementDetail(
            this.selectedEndorsementsArray, this.calculatedPremiumData.premiumOEndorsDetail);
        const calculatedEndoPremDetail = {
            endorsements: calculatedEndorsements,
            callFrom: this.constants.owner
        };
        this.dataService.onShowCalculatedEndorsementDetailClick(calculatedEndoPremDetail);

    }

    public resetDataAfterCalcuatePremium(fromRateType: boolean) {
        if (this.premiumCalculated) {
            this.premiumCalculated = false;
            this.resetFieldsFromThis = true;
            resetFieldsAfterCalculation(this, fromRateType);
            this.dataService.onResetFieldsForCalculatedPremium();
        }
    }

    getAmount(value: number) {
        return this.commonFunctions.getFormattedAmount(value);
    }

    ngOnDestroy(): void {
        if (this.endorsementDataSubscription && !this.endorsementDataSubscription.closed) {
            this.endorsementDataSubscription.unsubscribe();
        }
        if (this.getSelectedEndorsementsSubscription && !this.getSelectedEndorsementsSubscription.closed) {
            this.getSelectedEndorsementsSubscription.unsubscribe();
        }
        if (this.rateTypeDataSubscripton && !this.rateTypeDataSubscripton.closed) {
            this.rateTypeDataSubscripton.unsubscribe();
        }
        if (this.onLoanDataChangedSubscription && !this.onLoanDataChangedSubscription.closed) {
            this.onLoanDataChangedSubscription.unsubscribe();
        }
        if (this.onApiSuccessCalculatedPremiumSubscription && !this.onApiSuccessCalculatedPremiumSubscription.closed) {
            this.onApiSuccessCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.reselAllFieldsSubscription && !this.reselAllFieldsSubscription.closed) {
            this.reselAllFieldsSubscription.unsubscribe();
        }
        if (this.resetFieldsForCalculatedPremiumSubscription && !this.resetFieldsForCalculatedPremiumSubscription.closed) {
            this.resetFieldsForCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.selectedRegionSubscription && !this.selectedRegionSubscription.closed) {
            this.selectedRegionSubscription.unsubscribe();
        }
    }
}


function getSelectedRegion(context: OwnerComponent) {
    context.selectedRegionSubscription = context.dataService.shareSelectedRegionObservable.subscribe((selectedRegion: string) => {
        context.selectedRegionId = selectedRegion;
        if (context.ownerRateTypes && context.ownerRateTypes.length > 0) {
            context.ownerEndorsement = context.commonFunctions.getEndorsements(
                context.constants.ownerEndorsements, context.selectedPropertyType, context.selectedRateTypeCode, context.selectedRegionId);
            context.ownerRateTypes = context.commonFunctions.getRateTypes(context.tempOwnerRatesArray, context.selectedRegionId);
        }
    });
}

function getAllEndorsements(context: OwnerComponent) {
    context.endorsementDataSubscription = context.dataService.endorsementDataObservable.subscribe(endorsements => {
        try {
            if (endorsements) {
                // resetFields(context);
                const ownerEndorsements = JSON.stringify(JSON.parse(endorsements).ownerEndorsements);
                context.tempOwnerEndorsements = JSON.parse(ownerEndorsements);
                const saveEndorsement = !context.commonFunctions.checkEndorsements(context.constants.ownerEndorsements);
                setAndGetEndorsements(saveEndorsement, ownerEndorsements, context);
            }
        } catch (error) {

        }
    });
}

function getSelectedEndorsementsIdsFromLocalStorage(context: OwnerComponent) {
    context.selectedEndorsementsIds = context.commonFunctions.getSelectedEndorsementsIds(
        context.constants.ownerEndorsements, context.selectedPropertyType);
}

function getSelectedEndorsements(context: OwnerComponent) {
    context.getSelectedEndorsementsSubscription = context.dataService.getSelectedEndorsementsObservable.subscribe(
        endorsementsWithSelectedEndorsementsArray => {
            try {
                context.resetDataAfterCalcuatePremium(false);
                const parsedEndorsements = JSON.parse(endorsementsWithSelectedEndorsementsArray);
                if (parsedEndorsements.endorsementType === context.constants.endorsementTypeOwner) {
                    context.ownerEndorsement = parsedEndorsements.endorsements;
                    setSelectedEndorsements(context, parsedEndorsements);
                }
            } catch (error) {

            }

        });
}

function setSelectedEndorsements(context: OwnerComponent, parsedEndorsements: any) {
    context.selectedEndorsementsIdsArray = parsedEndorsements.selectedArray;
    context.commonFunctions.setSelectedEndorsementIds(context.constants.ownerEndorsements,
        context.selectedEndorsementsIdsArray, context.selectedPropertyType);
    context.endorsementsTotal = parsedEndorsements.endorsementsTotal;
    context.selectedEndorsementsArray = parsedEndorsements.selectedEndorsementsArray;
    context.calculateAndSendTotalAmount();
}

function setAndGetEndorsements(setEndorsements: boolean, ownerEndorsements: string, context: OwnerComponent) {
    if (setEndorsements) {
        context.commonFunctions.setEndorsements(context.constants.ownerEndorsements, ownerEndorsements);
    }
    context.ownerEndorsement = context.commonFunctions.getEndorsements(
        context.constants.ownerEndorsements, context.selectedPropertyType, context.selectedRateTypeCode, context.selectedRegionId);
}

function getRateType(context: OwnerComponent) {
    context.rateTypeDataSubscripton = context.dataService.rateTypeDataObservable.subscribe(rateTypes => {
        try {
            if (rateTypes) {
                const allRateTypes = JSON.parse(rateTypes);
                if (allRateTypes.hasOwnProperty("ownerRateType")) {
                    context.selectedPropertyType = allRateTypes.propertyTypecode;
                    context.tempOwnerRatesArray = allRateTypes.ownerRateType;
                    context.ownerRateTypes = context.commonFunctions.getRateTypes(context.tempOwnerRatesArray, context.selectedRegionId);

                    context.onOwnerRateTypeChanged(0);
                }
            }
        } catch (error) {

        }

    });
}

function getLoanChangedData(context: OwnerComponent) {
    context.onLoanDataChangedSubscription = context.dataService.onLoanDataChangedObservable.subscribe(loanChangedDataJson => {
        if (loanChangedDataJson) {
            try {
                if (context.loanAmount !== loanChangedDataJson.coverageAmount) {
                    context.loanAmount = loanChangedDataJson.coverageAmount;
                    enableDisableFields(context, context.loanAmount > 0);
                }
                if (context.loanSimultaneous !== loanChangedDataJson.simultaneous) {
                    context.loanSimultaneous = loanChangedDataJson.simultaneous;
                    context.loanAmount = loanChangedDataJson.coverageAmount;
                    checksimo(context);

                }
            } catch (error) {

            }
        }
    });
}



function checksimo(context: OwnerComponent) {
    // if (context.loanAmount && context.loanAmount > 0 && context.coverageAmount && context.coverageAmount > 0) {
    if (context.loanSimultaneous === context.constants.yes) {
        getAndSetOwnerRateType(context, true);
        context.ownerPremiumAllData.simultaneous = context.constants.yes;
    } else {
        getAndSetOwnerRateType(context, false);
        context.ownerPremiumAllData.simultaneous = context.constants.no;
    }
}

function getAndSetOwnerRateType(context: OwnerComponent, showOnlySimo: boolean) {
    let filteredOnwerRates: Array<RateTypeModel> = [];
    const simo = showOnlySimo ? context.constants.yes : context.constants.no;
    if (simo !== context.ownerPremiumAllData.simultaneous) {
        if (showOnlySimo) {
            filteredOnwerRates = context.commonFunctions.getSimoRateTypes(context.tempOwnerRatesArray, context.selectedRegionId);
            // if (filteredOnwerRates.length == context.constants.SIMULTANEOUS_RATE_TYPE_THRESHOLD) {
            //   if (!context.basicConfigJson.manualSimo)
            //     context.selectedRateTypeCode = filteredOnwerRates[0].ownerRateTypeCode;
            // }
        } else {
            filteredOnwerRates = context.commonFunctions.getRateTypes(context.tempOwnerRatesArray, context.selectedRegionId);
            // if (filteredOnwerRates.length > 0) {
            //   context.selectedRateTypeCode = filteredOnwerRates[0].ownerRateTypeCode;
            // }
        }
        context.ownerRateTypes = filteredOnwerRates;
        context.commonFunctions.printLog("filtered Owner rates length " + filteredOnwerRates.length, true);
        context.selectedRateTypeIndex = getSelectedRateTypeIndex(context);
        context.onOwnerRateTypeChanged(context.selectedRateTypeIndex);
    } else {
        context.calculateAndSendTotalAmount();
    }

}

function getSelectedRateTypeIndex(context: OwnerComponent) {
    let selectedIndex = 0;
    context.ownerRateTypes.forEach((element, index) => {
        if (element.ownerRateTypeCode === context.selectedRateTypeCode) {
            selectedIndex = index;
        }
    });

    return selectedIndex;
}

function createJsonForSendDataToStatePage(context: OwnerComponent) {

    context.ownerPremiumAllData.selectedEndorsementsArray = context.selectedEndorsementsArray;
    context.ownerPremiumAllData.coverageAmount = context.coverageAmount;
    context.ownerPremiumAllData.priorPolicyAmount = context.priorPolicyAmount;
    context.ownerPremiumAllData.selectedRateTypeCode = context.selectedRateTypeCode;
    context.ownerPremiumAllData.effectiveDate = context.effectiveDateInString;
    context.ownerPremiumAllData.selectedRateType = context.selectedRateType;
    context.ownerPremiumAllData.enablePriorPolicyAmount = !context.hideOwnerPriorPolicyInput;
    context.ownerPremiumAllData.effectiveDateAvailable = context.selectedRateTypeModel.priorPolicyDateFlag;

    return context.ownerPremiumAllData;
}

function getCalculatedPremium(context: OwnerComponent) {
    context.onApiSuccessCalculatedPremiumSubscription = context.dataService.onApiSuccessCalculatedPremiumObservable.subscribe(
        calculatedPremium => {
            context.calculatedPremiumData = calculatedPremium;
            context.premiumCalculated = true;
        });
}

function getEffectiveDate(context: OwnerComponent, value) {
    if (value.day < 10) {
        value.day = "0" + value.day;
    }

    if (value.month < 10) {
        value.month = "0" + value.month;
    }
    value.year = JSON.stringify(value.year).substring(2, 4);
    context.effectiveDateInString = value.month + "/" + value.day + "/" + value.year;
}

function registerResetAllFields(context: OwnerComponent) {
    context.reselAllFieldsSubscription = context.dataService.reselAllFieldsObservable.subscribe(function () {
        resetFields(context);
    });
}

function registerResetAllFieldsForCal(context: OwnerComponent) {
    context.resetFieldsForCalculatedPremiumSubscription = context.dataService.resetFieldsOnCalculatedPremiumObservable.subscribe(
        function () {
            context.premiumCalculated = false;
            if (!context.resetFieldsFromThis) {
                resetFieldsAfterCalculation(context, false);
            } else {
                context.resetFieldsFromThis = false;
            }
        });
}

function resetFields(context: OwnerComponent) {
    context.selectedEndorsementsArray = [];
    context.endorsementsTotal = 0;
    context.selectedEndorsementsIdsArray = "";
    context.priorPolicyAmount = 0;
    context.coverageAmount = 0;
    context.calculatedPremiumData = new ShareCalculatedPremiumModel();
    context.onOwnerRateTypeChanged(0);
    context.selectedRateTypeIndex = 0;
    context.selectedRegionId = "";
}

function resetFieldsAfterCalculation(context: OwnerComponent, fromRateType: boolean) {
    // context.selectedEndorsementsArray = [];
    // //context.ownerEndorsement = context.tempOwnerEndorsements;
    // context.endorsementsTotal = 0;
    // context.selectedEndorsementsIdsArray = "";
    // context.priorPolicyAmount = 0;
    // context.coverageAmount = 0;
    context.calculatedPremiumData = new ShareCalculatedPremiumModel();
    // if (!fromRateType) {
    //   context.selectedRateTypeIndex = 0;
    //   context.onOwnerRateTypeChanged(0);
    // }
}

function enableDisableFields(context: OwnerComponent, disableAllFields: boolean) {
    if (!disableAllFields) {
        disablefields(context, false);
    } else {
        if (context.selectedPropertyType === "C" && context.basicConfigJson.disableOtherPremiumOnConstruction) {
            resetFields(context);
            disablefields(context, true);
        } else {
            disablefields(context, false);
        }

    }
}

function disablefields(context: OwnerComponent, disable: boolean) {
    context.disableCoverageAmount = disable;
    context.disableRateType = disable;
    context.disableEndorsementBtn = disable;
}



