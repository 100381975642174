import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { CallWebApi } from "../../ApiCallInterface/CallWebApi";
import { API } from "../../Constants/API";
import { BasicConfigStatewise } from "../../Constants/BasicConfigStatewise";
import { Constants } from "../../Constants/Constants";
import * as states from "../../Constants/States";
import { ApiResponseCallback } from "../../Interfaces/ApiResponseCallback";
import { BasicConfigModel } from "../../Models/BasicConfigModel";
import { RateTypeModel } from "../../Models/RateTypeModel";
import { ShareCplDataModel } from "../../Models/ShareCplDataModel";
import { SharePremiumDataModel } from "../../Models/SharePremiumDataModel";
import { StringFormatPipe } from "../../Pipes/string-format/string-format.pipe";
import { ApiService } from "../../services/apiService/api.service";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";

@Component({
    selector: "app-florida",
    templateUrl: "./florida.component.html",
    styleUrls: ["./florida.component.css"]
})
export class FloridaComponent implements OnInit, OnDestroy {
    hideAppOwner = true;
    hideAppLoan = true;
    hideAppSecondLoan = true;
    ownerRateTypeArray: Array<RateTypeModel> = [];
    loanRateTypeArray: Array<RateTypeModel> = [];
    secondLoanRateTypeArray: Array<RateTypeModel> = [];
    // noneRateTypeModel: RateTypeModel = null;
    rateTypes: any;
    ownerTotalAmount = 0;
    onwerPremiumAllData: SharePremiumDataModel;
    loanPremiumAllData: SharePremiumDataModel;
    secondLoanPremiumAllData: SharePremiumDataModel = new SharePremiumDataModel();
    cplAllData: ShareCplDataModel = new ShareCplDataModel();
    basicConfigJson: BasicConfigModel;
    apiResponseCallback: ApiResponseCallback;
    selectedPropertyType = "";

    rateTypeDataSubscription: Subscription = null;
    ownerSubscription: Subscription = null;
    loanSubscription: Subscription = null;
    secondLoanSubscription: Subscription = null;
    cplSubscription: Subscription = null;

    constructor(
        private routes: ActivatedRoute,
        public commonFunctions: CommonFunctions,
        public dataservice: DataServiceService,
        private basicConfig: BasicConfigStatewise,
        public constants: Constants,
        public datePipe: DatePipe,
        public stringFormat: StringFormatPipe,
        public apiService: ApiService,
        public callWebApi: CallWebApi,
        public api: API
    ) { }

    ngOnInit() {
        this.basicConfigJson = this.basicConfig.getFloridaBasicConfig();
        this.rateTypeDataSubscription = this.dataservice.rateTypeDataObservable.subscribe(rateTypes => {
            try {
                this.rateTypes = JSON.parse(rateTypes);
                this.selectedPropertyType = this.rateTypes.propertyTypecode;
                this.hidePremiumType();
                registerAndGetAllPremiumData(this);
            } catch (error) { }
        });
    }

    hidePremiumType() {
        if (this.rateTypes.hasOwnProperty("ownerRateType")) {
            this.hideAppOwner = false;
        } else {
            this.hideAppOwner = true;
        }
        if (this.rateTypes.hasOwnProperty("loanRateType")) {
            this.hideAppLoan = false;
        } else {
            this.hideAppLoan = true;
        }
        if (this.rateTypes.hasOwnProperty("scndLoanRateType")) {
            this.hideAppSecondLoan = true;
        } else {
            this.hideAppSecondLoan = true;
        }
    }

    onOwnerTotalAmountChanged(totalAmount: number) {
        this.ownerTotalAmount = totalAmount;
        this.commonFunctions.printLog("total amount " + this.ownerTotalAmount, true);
    }

    onCalculateClick(event) {
        if (validateData(this)) {
            this.callWebApi.callPremiumCalculateApi(createParamsForCalculatePremium(this),
                states.FLORIDA_ID, this);
        }
    }
    onSuccess(response: any) {
        this.dataservice.onHideShowLoader(false);
        this.dataservice.onCalculatedDataApiSuccess(response);
    }

    onError(errorCode: number, errorMsg: string) {
        this.commonFunctions.showAlertDialog(this.constants.alertErrorTitle, errorMsg);
    }

    ngOnDestroy(): void {
        if (this.rateTypeDataSubscription && !this.rateTypeDataSubscription.closed) {
            this.rateTypeDataSubscription.unsubscribe();
        }
        if (this.ownerSubscription && !this.ownerSubscription.closed) {
            this.ownerSubscription.unsubscribe();
        }
        if (this.loanSubscription && !this.loanSubscription.closed) {
            this.loanSubscription.unsubscribe();
        }
        if (this.secondLoanSubscription && !this.secondLoanSubscription.closed) {
            this.secondLoanSubscription.unsubscribe();
        }
        if (this.cplSubscription && !this.cplSubscription.closed) {
            this.cplSubscription.unsubscribe();
        }
        this.commonFunctions.printLog("Florida destroyed", true);
    }
}

function registerAndGetAllPremiumData(context: FloridaComponent) {
    context.ownerSubscription = context.dataservice.onOwnerDataChangedObservable.subscribe(onwerData => {
        if (onwerData) {
            context.onwerPremiumAllData = onwerData;
        }
    });

    context.loanSubscription = context.dataservice.onLoanDataChangedObservable.subscribe(loanData => {
        if (loanData) {
            context.loanPremiumAllData = loanData;
            context.hideAppSecondLoan = !context.loanPremiumAllData.secondLoanAdded;
            context.commonFunctions.printLog("added second loan " + context.loanPremiumAllData.secondLoanAdded, true);
        }
    });

    context.secondLoanSubscription = context.dataservice.onSecondLoanDataChangedObservable.subscribe(secondloanData => {
        if (secondloanData) {
            context.secondLoanPremiumAllData = secondloanData;
        }
    });

    context.cplSubscription = context.dataservice.onCplDataChangedObservable.subscribe(cplData => {
        if (cplData) {
            context.cplAllData = cplData;
        }
    });
}

function validateData(context: FloridaComponent) {
    // Owner page validation
    if (context.onwerPremiumAllData.selectedRateTypeCode !== context.constants.na) {
        if (context.onwerPremiumAllData.selectedEndorsementsArray.length > 0 && context.onwerPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorOwnerCoverageAmount);
            return false;
        }

        if (context.onwerPremiumAllData.selectedRateTypeCode !== context.constants.none &&
            context.onwerPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorOwnerCoverageAmount);
            return false;
        }

        if (context.onwerPremiumAllData.coverageAmount > 0 && context.onwerPremiumAllData.selectedRateTypeCode === context.constants.none) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorOwnerRateType);
            return false;
        }
        if (
            context.onwerPremiumAllData.enablePriorPolicyAmount &&
            (!context.onwerPremiumAllData.priorPolicyAmount || context.onwerPremiumAllData.priorPolicyAmount <= 0)
        ) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorPriorPolicyAmount);
            return false;
        }
    }

    // Loan page validation
    if (context.loanPremiumAllData.selectedRateTypeCode !== context.constants.na) {
        if (context.loanPremiumAllData.selectedEndorsementsArray.length > 0 && context.loanPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorLoanCoverageAmount);
            return false;
        }

        if (context.loanPremiumAllData.selectedRateTypeCode !== context.constants.none && context.loanPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorLoanCoverageAmount);
            return false;
        }
        if (context.loanPremiumAllData.coverageAmount > 0 && context.loanPremiumAllData.selectedRateTypeCode === context.constants.none) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorLoanRateType);
            return false;
        }
        if (
            context.loanPremiumAllData.enablePriorPolicyAmount &&
            (!context.loanPremiumAllData.priorPolicyAmount || context.loanPremiumAllData.priorPolicyAmount <= 0)
        ) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorPriorPolicyAmount);
            return false;
        }
    }

    if (context.loanPremiumAllData.effectiveDateAvailable && !context.loanPremiumAllData.effectiveDate) {
        context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorLoanEffectiveDate);
        return false;
    }
    if (
        context.onwerPremiumAllData.selectedRateTypeCode !== context.constants.na &&
        context.loanPremiumAllData.selectedRateTypeCode !== context.constants.na &&
        !context.onwerPremiumAllData.coverageAmount &&
        !context.loanPremiumAllData.coverageAmount
    ) {
        context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorNoDataEntered);
        return false;
    }
    return true;
}

function createParamsForCalculatePremium(context: FloridaComponent) {
    if (context.onwerPremiumAllData.coverageAmount == null) {
        context.onwerPremiumAllData.coverageAmount = 0;
    }
    if (context.loanPremiumAllData.coverageAmount == null) {
        context.loanPremiumAllData.coverageAmount = 0;
    }

    let paramsString = "";
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.ownerRateType,
        context.onwerPremiumAllData.selectedRateTypeCode,
        false
    );
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.ownerRateTypeCode,
        context.onwerPremiumAllData.selectedRateType,
        false
    );
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.ownerCoverageAmount,
        context.onwerPremiumAllData.coverageAmount,
        false
    );
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.ownerReissueAmount,
        context.onwerPremiumAllData.priorPolicyAmount,
        false
    );
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.ownerEndorsements,
        context.commonFunctions.convertEndorsementsToUrlParams(context.onwerPremiumAllData.selectedEndorsementsArray),
        false
    );

    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.loanRateType,
        context.loanPremiumAllData.selectedRateTypeCode,
        false
    );
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.loanRateTypeCode,
        context.loanPremiumAllData.selectedRateType,
        false
    );
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.loanCoverageAmount,
        context.loanPremiumAllData.coverageAmount,
        false
    );
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.loanReissueAmount,
        context.loanPremiumAllData.priorPolicyAmount,
        false
    );
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.loanEndorsements,
        context.commonFunctions.convertEndorsementsToUrlParams(context.loanPremiumAllData.selectedEndorsementsArray),
        false
    );

    paramsString = context.commonFunctions.createParams(paramsString, context.constants.propertyType, context.selectedPropertyType, false);
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.region, context.constants.regionValue, false);
    if (context.onwerPremiumAllData.effectiveDateAvailable) {
        paramsString = context.commonFunctions.createParams(
            paramsString,
            context.constants.priorEffectiveDate,
            context.onwerPremiumAllData.effectiveDate,
            false
        );
    }
    if (context.loanPremiumAllData.effectiveDateAvailable) {
        paramsString = context.commonFunctions.createParams(
            paramsString,
            context.constants.loanPriorEffectiveDate,
            context.loanPremiumAllData.effectiveDate,
            false
        );
    }
    paramsString = context.commonFunctions.createParams(
        paramsString,
        context.constants.simultaneous,
        context.loanPremiumAllData.simultaneous,
        true
    );

    return paramsString;
}
