import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { UrlSerializer } from "@angular/router";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { ANIMATION_TYPES, LoadingModule } from "ngx-loading";
import { CallWebApi } from "./ApiCallInterface/CallWebApi";
import { AppComponent } from "./app.component";
import { appRoutes } from "./app.routing";
import { API } from "./Constants/API";
import { BasicConfigStatewise } from "./Constants/BasicConfigStatewise";
import { Constants } from "./Constants/Constants";
import { AlertDialogComponent } from "./customDialog/alert-dialog/alert-dialog.component";
import { PremiumParser } from "./parsers/PremiumParser";
import { EndorsementsSearchPipe } from "./Pipes/endorsement-search-pipe/endorsements-search.pipe";
import { StateSearchPipe } from "./Pipes/search-pipe/state-search.pipe";
import { StringFormatPipe } from "./Pipes/string-format/string-format.pipe";
import { ApiService } from "./services/apiService/api.service";
import { DataServiceService } from "./services/dataService/data-service.service";
import { ModalService } from "./services/modelService";
import { AlabamaComponent } from "./States/alabama/alabama.component";
import { ColoradoComponent } from "./States/colorado/colorado.component";
import { FloridaComponent } from "./States/florida/florida.component";
import { GeorgiaComponent } from "./States/georgia/georgia.component";
import { MinnesotaComponent } from "./States/minnesota/minnesota.component";
import { MississippiComponent } from "./States/mississippi/mississippi.component";
import { MissouriComponent } from "./States/missouri/missouri.component";
import { NotFoundStateComponent } from "./States/not-found-state/not-found-state.component";
import { SouthcarolinaComponent } from "./States/southcarolina/southcarolina.component";
import { CommonFunctions } from "./Utils/CommonFunctions";
import { LowerCaseUrlSerializer } from "./Utils/LowerCaseUrlSerializer";
import { NgbDateCustomParserFormatter } from "./Utils/ngb-date-custom-parser-formatter.service";
import { Utilities } from "./Utils/Utilities";
import { CalculatedEndorsementsDetailComponent } from "./Views/calculated-endorsements-detail/calculated-endorsements-detail.component";
import { CplComponent } from "./Views/cpl/cpl.component";
import { EmailModelComponent } from "./Views/email-model/email-model.component";
import { EndorsementModelComponent } from "./Views/endorsement-model/endorsement-model.component";
import { HomeViewComponent } from "./Views/home-view/home-view.component";
import { LoanComponent } from "./Views/loan/loan.component";
import { OnlyCplComponent } from "./Views/only-cpl/only-cpl.component";
import { CommitmentComponent } from "./Views/commitment/commitment.component";
import { OwnerComponent } from "./Views/owner/owner.component";
import { PdfGenerationFormComponent } from "./Views/pdf-generation-form/pdf-generation-form.component";
import { PremiumRateQuotesComponent } from "./Views/premium-rate-quotes/premium-rate-quotes.component";
import { SecondLoanComponent } from "./Views/second-loan/second-loan.component";
import { StateModelComponent } from "./Views/state-model/state-model.component";
import { TotalComponent } from "./Views/total/total.component";
import { KansasComponent } from "./States/kansas/kansas.component";
import { DecimalPipe, CurrencyPipe } from "@angular/common";
import { RegionModelComponent } from "./Views/region-model/region-model.component";
import { TexasComponent } from "./States/texas/texas.component";
import { HttpClientModule } from "@angular/common/http";
import { StorageServiceModule } from "ngx-webstorage-service";
import { UtahComponent } from "./States/utah/utah/utah.component";
import { WisconsinComponent } from "./States/wisconsin/wisconsin/wisconsin.component";
import { BrowserIncompatibilityComponent } from "./Views/browser-incompatibility/browser-incompatibility.component";
import { NevadaComponent } from './States/nevada/nevada.component';
import { NewmexicoComponent } from './States/newmexico/newmexico.component';


export const customCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "left",
    allowNegative: true,
    decimal: ".",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: ","
};

@NgModule({
    declarations: [
        AppComponent,
        HomeViewComponent,
        StateModelComponent,
        EndorsementModelComponent,
        EmailModelComponent,
        OwnerComponent,
        LoanComponent,
        SecondLoanComponent,
        CplComponent,
        TotalComponent,
        AlabamaComponent,
        NotFoundStateComponent,
        StateSearchPipe,
        StringFormatPipe,
        EndorsementsSearchPipe,
        GeorgiaComponent,
        CalculatedEndorsementsDetailComponent,
        OnlyCplComponent,
        CommitmentComponent,
        FloridaComponent,
        MissouriComponent,
        PdfGenerationFormComponent,
        PremiumRateQuotesComponent,
        AlertDialogComponent,
        MississippiComponent,
        SouthcarolinaComponent,
        MinnesotaComponent,
        ColoradoComponent,
        KansasComponent,
        RegionModelComponent,
        TexasComponent,
        UtahComponent,
        WisconsinComponent,
        BrowserIncompatibilityComponent,
        NevadaComponent,
        NewmexicoComponent

    ],
    imports: [
        appRoutes,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        CurrencyMaskModule,
        CommonModule,
        StorageServiceModule,
        NgbModule,
        ReactiveFormsModule,

        LoadingModule.forRoot({
            animationType: ANIMATION_TYPES.cubeGrid,
            backdropBackgroundColour: "rgba(0,0,0,0.7)",
            backdropBorderRadius: "1px",
            primaryColour: "#ffffff",
            secondaryColour: "#ffffff",
            tertiaryColour: "#ffffff",
            fullScreenBackdrop: true
        }),

    ],
    entryComponents: [AlertDialogComponent],
    providers: [ApiService, CommonFunctions, API, DataServiceService, Constants, BasicConfigStatewise,
        DatePipe, StringFormatPipe, CallWebApi, PremiumParser, Utilities, ModalService,
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: customCurrencyMaskConfig
        },
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        }, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }, DecimalPipe,
        CurrencyPipe],
    bootstrap: [AppComponent]
})
export class AppModule { }
