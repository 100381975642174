import { Injectable } from "@angular/core";
import { ApiResponseCallback } from "../../Interfaces/ApiResponseCallback";
import { CommonFunctions } from "../../Utils/CommonFunctions";
import { Constants } from "../../Constants/Constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// const DOMParser = require("xmldom").DOMParser;

@Injectable({
    providedIn: "root"
})
export class ApiService {

    constructor(private http: HttpClient, private commonFunctions: CommonFunctions, private constants: Constants) { }

    getSelectedStateJson(url) {
        return this.http.get(url);
    }

    hitGetApi(url, apiResponseCallback: ApiResponseCallback) {

        if (navigator.onLine) {
            const self = this;
            // var headers = new Headers();
            // headers.set('Accept', 'text/json');
            // headers.set('Content-Type', 'text/json');
            // const options = new RequestOptions({
            //   headers: headers,
            //   responseType: ResponseContentType.Text
            // });
            const headers = new HttpHeaders({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                "Content-Type": "application/text"
            });
            const options = {
                headers: headers
            };

            this.http.get(url, options)
                .subscribe(result => {
                    if (result) {
                        try {
                            apiResponseCallback.onSuccess(result);
                        } catch (error) {
                            apiResponseCallback.onError(101, error.toString());
                            // var xml = result.text();
                            // var domParser = new DOMParser().parseFromString(xml);
                            // var xmlTojson = this.commonFunctions.xml2json(domParser, '');
                            // apiResponseCallback.onSuccess(JSON.parse(xmlTojson));
                        }
                        // var reverseXML = this.commonFunctions.json2xml(JSON.parse(json), '');
                        // this.commonFunctions.printLog("reverse xml "+reverseXML, true);


                        // parser.parseString(xml, (err, result) => {
                        //   console.log(result);
                        //   apiResponseCallback.onSuccess(result.toString());
                        // });
                    } else {
                        apiResponseCallback.onError(500, "Error");
                    }

                }, function (error: Response) {
                    if (error.status === 0) {
                        apiResponseCallback.onError(error.status, self.constants.errorNoInternetConnecton);
                    } else {
                        apiResponseCallback.onError(error.status, error.statusText);
                    }
                });
        } else {
            apiResponseCallback.onError(this.constants.noInternetConnectionErrorCode, this.constants.errorNoInternetConnecton);
        }
    }

    hitPostApi(url, data, apiResponseCallback: ApiResponseCallback) {
        this.http.post(url, data)
            .subscribe(result => {
                if (result) {
                    apiResponseCallback.onSuccess(result);
                } else {
                    apiResponseCallback.onError(500, Error.toString());
                }

            }, error => {
                apiResponseCallback.onError(400, error.toString());
            });
    }
}
