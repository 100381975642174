import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { Constants } from "../../Constants/Constants";
import { BasicConfigModel } from "../../Models/BasicConfigModel";
import { EndorsementModel } from "../../Models/EndorsementModel";
import { RateTypeModel } from "../../Models/RateTypeModel";
import { ShareCalculatedPremiumModel } from "../../Models/ShareCalculatedPremiumModel";
import { SharePremiumDataModel } from "../../Models/SharePremiumDataModel";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";
@Component({
    selector: "app-second-loan",
    templateUrl: "./second-loan.component.html",
    styleUrls: ["./second-loan.component.css"]
})
export class SecondLoanComponent implements OnInit, OnDestroy {

    secondLoanRateTypes: Array<RateTypeModel> = [];
    @Input()
    basicConfigJson: BasicConfigModel;
    tempSecondLoanRatesArray: Array<RateTypeModel> = [];
    secondLoanAmount = 0;
    tempSecondLoanEndorsements: Array<EndorsementModel> = new Array<EndorsementModel>();
    secondLoanEndorsement: Array<EndorsementModel> = new Array<EndorsementModel>();

    selectedEndorsementsArray: Array<EndorsementModel> = [];
    hideLoanPriorPolicyInput = false;
    selectedEndorsementsIdsArray = "";
    endorsementsTotal = 0;
    priorPolicyAmount = 0;
    ownerAmount = 0;
    selectedRateTypeCode = "";
    selectedRateType = "";
    secondLoanAllData: SharePremiumDataModel = new SharePremiumDataModel();
    calculatedPremiumData: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    showComponent = true;
    premiumCalculated = false;
    resetFieldsFromThis = false;
    selectedPropertyType = "";
    selectedEndorsementsIds: Array<string> = [];
    selectedRegionId: string;
    loanSimultaneous: any = false;
    selectedRateTypeIndex = 0;

    endorsementDataSubscription: Subscription;
    getSelectedEndorsementsSubscription: Subscription;
    rateTypeDataSubscripton: Subscription;
    onApiSuccessCalculatedPremiumSubscription: Subscription;
    reselAllFieldsSubscription: Subscription;
    resetFieldsForCalculatedPremiumSubscription: Subscription;
    selectedRegionSubscription: Subscription;
    onLoanDataChangedSubscription: Subscription;
    clearFieldsOnHideSecondLoanSubscription: Subscription;


    constructor(public commonFunctions: CommonFunctions,
        public dataService: DataServiceService,
        public constants: Constants,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        getSelectedRegion(this);
        getAndSetLoanRateType(this, false);
        getAllEndorsements(this);
        getSelectedEndorsements(this);
        getRateType(this);
        getLoanChangedData(this);
        getCalculatedPremium(this);
        registerResetAllFields(this);
        resetFields(this);
        registerResetAllFieldsForCal(this);
        registerClearFieldsOnHideSecondLoan(this);

    }
    onLoanRateTypeDropDownChanged(index) {
        this.onLoanRateTypeChanged(index);
        setAndGetEndorsements(false, "", this);
    }
    onLoanRateTypeChanged(index) {

        if (this.secondLoanRateTypes.length > 0) {
            this.resetDataAfterCalcuatePremium(true);
            const selectedRateTypeModel = this.secondLoanRateTypes[index];
            this.hideLoanPriorPolicyInput = !selectedRateTypeModel.priorPolicyAmountFlag;
            this.selectedRateTypeCode = selectedRateTypeModel.scndLoanRateTypecode;
            this.selectedRateType = selectedRateTypeModel.description;
            this.priorPolicyAmount = 0;
            this.calculateAndSendTotalAmount();
            this.cdr.markForCheck();
        }
    }
    onLoanOpenEndorsementClick(event) {
        getSelectedEndorsementsIdsFromLocalStorage(this);
        const endorsementsWithTypeFlag = {
            endorsements: this.secondLoanEndorsement,
            endorsementType: this.constants.endorsementTypeSecondLoan,
            tempEndorsement: this.tempSecondLoanEndorsements,
            selectedEndorsementsIds: this.selectedEndorsementsIds
        };
        this.dataService.onShowEndorsementDataChanged(JSON.stringify(endorsementsWithTypeFlag));
    }

    onCoverageAmountChange(value) {
        this.resetDataAfterCalcuatePremium(false);
        this.secondLoanAmount = value;
        this.calculateAndSendTotalAmount();
    }

    onPriorPolicyAmountChange(value) {
        this.resetDataAfterCalcuatePremium(false);
        this.priorPolicyAmount = value;
        this.calculateAndSendTotalAmount();
    }

    calculateAndSendTotalAmount() {
        const json = createJsonForSendDataToStatePage(this);
        this.dataService.onSecondLoanDataChanged(json);
    }
    onShowHideClick(event) {
        this.showComponent = !this.showComponent;
    }
    onShowCalculatedEndorsementClick(event) {
        const calculatedEndorsements = this.commonFunctions.getEndorsementDetail(
            this.selectedEndorsementsArray, this.calculatedPremiumData.premiumSEndorsDetail);
        const calculatedEndoPremDetail = {
            endorsements: calculatedEndorsements,
            callFrom: this.constants.secondLoan
        };
        this.dataService.onShowCalculatedEndorsementDetailClick(calculatedEndoPremDetail);

    }

    public resetDataAfterCalcuatePremium(fromRateType: boolean) {
        if (this.premiumCalculated) {
            this.premiumCalculated = false;
            this.resetFieldsFromThis = true;
            resetFieldsAfterCalculation(this, fromRateType);
            this.dataService.onResetFieldsForCalculatedPremium();
        }
    }

    getAmount(value: number) {
        return this.commonFunctions.getFormattedAmount(value);
    }

    ngOnDestroy(): void {
        if (this.endorsementDataSubscription && !this.endorsementDataSubscription.closed) {
            this.endorsementDataSubscription.unsubscribe();
        }
        if (this.getSelectedEndorsementsSubscription && !this.getSelectedEndorsementsSubscription.closed) {
            this.getSelectedEndorsementsSubscription.unsubscribe();
        }
        if (this.rateTypeDataSubscripton && !this.rateTypeDataSubscripton.closed) {
            this.rateTypeDataSubscripton.unsubscribe();
        }
        if (this.onApiSuccessCalculatedPremiumSubscription && !this.onApiSuccessCalculatedPremiumSubscription.closed) {
            this.onApiSuccessCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.reselAllFieldsSubscription && !this.reselAllFieldsSubscription.closed) {
            this.reselAllFieldsSubscription.unsubscribe();
        }
        if (this.resetFieldsForCalculatedPremiumSubscription && !this.resetFieldsForCalculatedPremiumSubscription.closed) {
            this.resetFieldsForCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.selectedRegionSubscription && !this.selectedRegionSubscription.closed) {
            this.selectedRegionSubscription.unsubscribe();
        }
        if (this.clearFieldsOnHideSecondLoanSubscription && !this.clearFieldsOnHideSecondLoanSubscription.closed) {
            this.clearFieldsOnHideSecondLoanSubscription.unsubscribe();
        }
    }
}

function getSelectedRegion(context: SecondLoanComponent) {
    context.selectedRegionSubscription = context.dataService.shareSelectedRegionObservable.subscribe((selectedRegion: string) => {
        context.selectedRegionId = selectedRegion;
        if (context.secondLoanRateTypes && context.secondLoanRateTypes.length > 0) {
            context.secondLoanEndorsement = context.commonFunctions.getEndorsements(context.constants.secondLoanEndorsements,
                context.selectedPropertyType, context.selectedRateTypeCode, context.selectedRegionId);
            context.secondLoanRateTypes = context.commonFunctions.getRateTypes(context.tempSecondLoanRatesArray, context.selectedRegionId);
        }
    });
}
function getAllEndorsements(context: SecondLoanComponent) {
    context.endorsementDataSubscription = context.dataService.endorsementDataObservable.subscribe(endorsements => {
        try {
            if (endorsements) {
                // resetFields(context);
                const secondLoanEndorsements = JSON.stringify(JSON.parse(endorsements).secondLoanEndorsements);
                context.tempSecondLoanEndorsements = JSON.parse(secondLoanEndorsements);
                const saveEndorsement = !context.commonFunctions.checkEndorsements(context.constants.secondLoanEndorsements);
                setAndGetEndorsements(saveEndorsement, secondLoanEndorsements, context);
            }
        } catch (error) {

        }
    });
}
function getSelectedEndorsementsIdsFromLocalStorage(context: SecondLoanComponent) {
    context.selectedEndorsementsIds = context.commonFunctions.getSelectedEndorsementsIds(context.constants.secondLoanEndorsements,
        context.selectedPropertyType);
}


function getSelectedEndorsements(context: SecondLoanComponent) {
    context.getSelectedEndorsementsSubscription = context.dataService.getSelectedEndorsementsObservable.subscribe(
        endorsementsWithSelectedEndorsementsArray => {
            try {
                context.resetDataAfterCalcuatePremium(false);
                const parsedEndorsements = JSON.parse(endorsementsWithSelectedEndorsementsArray);
                if (parsedEndorsements.endorsementType === context.constants.endorsementTypeSecondLoan) {
                    context.secondLoanEndorsement = parsedEndorsements.endorsements;
                    setSelectedEndorsements(context, parsedEndorsements);
                }
            } catch (error) {

            }
        });
}

function setSelectedEndorsements(context: SecondLoanComponent, parsedEndorsements: any) {
    context.selectedEndorsementsIdsArray = parsedEndorsements.selectedArray;
    // setAndGetEndorsements(true, JSON.stringify(parsedEndorsements.endorsements), context);
    context.commonFunctions.setSelectedEndorsementIds(context.constants.secondLoanEndorsements,
        context.selectedEndorsementsIdsArray, context.selectedPropertyType);
    context.endorsementsTotal = parsedEndorsements.endorsementsTotal;
    context.selectedEndorsementsArray = parsedEndorsements.selectedEndorsementsArray;
    context.calculateAndSendTotalAmount();
}


function setAndGetEndorsements(setEndorsements: boolean, secondLoanEndorsements: string, context: SecondLoanComponent) {
    if (setEndorsements) {
        context.commonFunctions.setEndorsements(context.constants.secondLoanEndorsements, secondLoanEndorsements);
    }
    context.secondLoanEndorsement = context.commonFunctions.getEndorsements(context.constants.secondLoanEndorsements,
        context.selectedPropertyType, context.selectedRateTypeCode, context.selectedRegionId);
}

function getRateType(context: SecondLoanComponent) {
    context.rateTypeDataSubscripton = context.dataService.rateTypeDataObservable.subscribe(rateTypes => {
        try {
            if (rateTypes) {
                const allRateTypes = JSON.parse(rateTypes);
                if (allRateTypes.hasOwnProperty("scndLoanRateType")) {
                    context.selectedPropertyType = allRateTypes.propertyTypecode;
                    context.tempSecondLoanRatesArray = allRateTypes.scndLoanRateType;

                    context.secondLoanRateTypes = context.commonFunctions.getRateTypes(context.tempSecondLoanRatesArray,
                        context.selectedRegionId);

                    context.onLoanRateTypeChanged(0);
                }
            }
        } catch (error) {

        }

    });
}

function getLoanChangedData(context: SecondLoanComponent) {
    context.onLoanDataChangedSubscription = context.dataService.onLoanDataChangedObservable.subscribe(loanChangedDataJson => {
        if (loanChangedDataJson) {
            try {
                if (context.loanSimultaneous !== loanChangedDataJson.simultaneous) {
                    context.loanSimultaneous = loanChangedDataJson.simultaneous;
                    if (context.loanSimultaneous === "no") {
                        context.secondLoanRateTypes = context.commonFunctions.getRateTypes(context.tempSecondLoanRatesArray,
                            context.selectedRegionId);
                    } else {
                        context.secondLoanRateTypes = context.commonFunctions.getSimoRateTypes(context.tempSecondLoanRatesArray,
                            context.selectedRegionId);
                    }

                }
            } catch (error) {

            }
        }
    });
}

function getAndSetLoanRateType(context: SecondLoanComponent, showOnlySimo: boolean) {
    let filteredSecondLoanRates: Array<RateTypeModel> = [];
    if (showOnlySimo) {
        context.tempSecondLoanRatesArray.forEach(element => {
            if (element.simo) {
                filteredSecondLoanRates.push(element);
            }
        });
    } else {
        filteredSecondLoanRates = context.tempSecondLoanRatesArray;
    }
    context.secondLoanRateTypes = filteredSecondLoanRates;
}


function getCalculatedPremium(context: SecondLoanComponent) {
    context.onApiSuccessCalculatedPremiumSubscription = context.dataService.onApiSuccessCalculatedPremiumObservable.subscribe(
        calculatedPremium => {
            context.calculatedPremiumData = calculatedPremium;
            context.premiumCalculated = true;
        });
}

function registerResetAllFields(context: SecondLoanComponent) {
    context.reselAllFieldsSubscription = context.dataService.reselAllFieldsObservable.subscribe(function () {
        resetFields(context);
    });
}

function registerResetAllFieldsForCal(context: SecondLoanComponent) {
    context.resetFieldsForCalculatedPremiumSubscription = context.dataService.resetFieldsOnCalculatedPremiumObservable.subscribe(
        function () {
            context.premiumCalculated = false;
            if (!context.resetFieldsFromThis) {
                resetFieldsAfterCalculation(context, false);
            } else {
                context.resetFieldsFromThis = false;
            }
        });
}

function registerClearFieldsOnHideSecondLoan(context: SecondLoanComponent) {
    context.clearFieldsOnHideSecondLoanSubscription = context.dataService.clearSecondLoanFieldObservable.subscribe(clear => {
        resetFields(context);
    });
}

function resetFields(context: SecondLoanComponent) {
    context.selectedEndorsementsArray = [];
    context.endorsementsTotal = 0;
    context.onLoanRateTypeChanged(0);
    context.selectedEndorsementsIdsArray = "";
    context.priorPolicyAmount = 0;
    context.secondLoanAmount = 0;
    context.calculatedPremiumData = new ShareCalculatedPremiumModel();
    context.calculateAndSendTotalAmount();
    context.selectedRateTypeIndex = 0;
    context.selectedRegionId = "";

}
function resetFieldsAfterCalculation(context: SecondLoanComponent, fromRateType: boolean) {
    // context.selectedEndorsementsArray = [];
    // //context.secondLoanEndorsement = context.tempSecondLoanEndorsements;
    // context.endorsementsTotal = 0;
    // context.selectedEndorsementsIdsArray = "";
    // context.priorPolicyAmount = 0;
    // context.secondLoanAmount = 0;
    context.calculatedPremiumData = new ShareCalculatedPremiumModel();
    // if (!fromRateType)
    //   context.onLoanRateTypeChanged(0);

}



function createJsonForSendDataToStatePage(context: SecondLoanComponent) {
    context.secondLoanAllData.selectedEndorsementsArray = context.selectedEndorsementsArray;
    context.secondLoanAllData.coverageAmount = context.secondLoanAmount;
    context.secondLoanAllData.priorPolicyAmount = context.priorPolicyAmount;
    context.secondLoanAllData.selectedRateTypeCode = context.selectedRateTypeCode;
    context.secondLoanAllData.selectedRateType = context.selectedRateType;
    context.secondLoanAllData.enablePriorPolicyAmount = !context.hideLoanPriorPolicyInput;

    return context.secondLoanAllData;
}
