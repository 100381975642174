<div class="modal fade" id="EndorsementsModal" tabindex="-1" role="dialog" aria-labelledby="EndorsementsModalLabel"
  aria-hidden="true">
  <div class="modal-dialog EndorsementsModal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="EndorsementsModalLabel">Endorsements</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeEndorsementModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center EndorsementsSearchBox">
          <form class="form-inline">
            <div class="section-one">
              <input class="form-control mr-sm-2" type="search" placeholder="Search Endorsements" aria-label="Search"
                [(ngModel)]="endorsementSearchText" [ngModelOptions]="{standalone: true}" (ngModelChange)="onSearchChange()">
            </div>
            <div class="section-two">
              <button type="button" class="btn btn-danger margin-right-ten" (click)="onSelectUnselectAllCheckedChanged(true)">Select
                All</button>
              <button type="button" class="btn btn-danger" (click)="onSelectUnselectAllCheckedChanged(false)">Unselect
                All</button>
              <!-- <input type="checkbox" data-toggle="toggle" data-size="normal"  data-on="Select All" data-off="Deselect All" data-onstyle="success" data-offstyle="danger" #selectAllCheck [(ngModel)]="allEndorsementsChecked"
                (change)="onSelectAllCheckedChanged($event)" [ngModelOptions]="{standalone: true}"> -->

            </div>
          </form>

        </div>
        <div class="EndorsementsList">
          <ul>
            <ng-container *ngFor="let endorsement of endorsements; let i = index">
              <li *ngIf="endorsement.visible">
                <div class="li-div">
                  <div class="form-check">
                    <label>
                      <input type="checkbox" class="form-check-input" id="exampleCheck1"
                        [checked]="isEndorsementChecked(endorsement)"  [(ngModel)]="endorsement.checked" (change)="onEndorsementCheckChanged($event,i,endorsement)">
                      <a title="{{endorsement.description}}">{{endorsement.description}} </a></label>
                  </div>
                  <div *ngIf="getVisibleEndorsement(endorsement.showProposedRate)">
                    <input currencyMask class="form-control form-control-sm" type="text" [ngClass]="{'endorsement-input-enable':endorsement.checked,'endorsement-input-disable':!endorsement.checked}"
                    placeholder="Proposed Rate" [(ngModel)]="endorsement.proposedRate" [attr.disabled]="endorsement.checked ? null :''"
                    maxlength="10" *ngIf="endorsement.proposedRate !== false">
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-success" (click)="onApplyClick($event)">Apply</button>
            <!-- <button type="button" class="btn hide-second-loan-btn" (click)="onClearClick($event)">Clear</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
