export class BasicConfigModel {
    availableOwnerComponent: boolean;
    availableLoanComponent: boolean;
    availableSecondLoanComponent: boolean;
    availableCplComponent: boolean;
    availableOwnerEndorsements: boolean;
    availableLoanEndorsements: boolean;
    availableSecondLoanEndorsements: boolean;
    availableOwnerReissue: boolean;
    availableLoanReissue: boolean;
    availableSecondLoanReissue: boolean;
    availableOnlyCpl: boolean;
    availableLenderCpl: boolean;
    availableBuyerCpl: boolean;
    availableSellerCpl: boolean;
    manualSimo = false;
    disableOtherPremiumOnConstruction = false;
    endorsementProposedRateFlag = true;
    regionAvailable = false;
}
