import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { Constants } from "./Constants/Constants";
import * as stateNames from "./Constants/StatePaths";
import { AlabamaComponent } from "./States/alabama/alabama.component";
import { ColoradoComponent } from "./States/colorado/colorado.component";
import { FloridaComponent } from "./States/florida/florida.component";
import { GeorgiaComponent } from "./States/georgia/georgia.component";
import { MinnesotaComponent } from "./States/minnesota/minnesota.component";
import { MississippiComponent } from "./States/mississippi/mississippi.component";
import { MissouriComponent } from "./States/missouri/missouri.component";
import { NotFoundStateComponent } from "./States/not-found-state/not-found-state.component";
import { SouthcarolinaComponent } from "./States/southcarolina/southcarolina.component";
import { HomeViewComponent } from "./Views/home-view/home-view.component";
import { KansasComponent } from "./States/kansas/kansas.component";
import { TexasComponent } from "./States/texas/texas.component";
import { ModuleWithProviders } from "@angular/compiler/src/core";
import { UtahComponent } from "./States/utah/utah/utah.component";
import { WisconsinComponent } from "./States/wisconsin/wisconsin/wisconsin.component";
import { NevadaComponent } from "./States/nevada/nevada.component";
import { NewmexicoComponent } from "./States/newmexico/newmexico.component";

let constants: Constants;

const appRoutesArray: Routes =
  [
      { path: "home/states", component: AppComponent },
      { path: "home/:statename", component: AppComponent },
      { path: "", component: HomeViewComponent },
      { path: stateNames.PATH_TO_ALABAMA, component: AlabamaComponent },
      { path: stateNames.PATH_TO_AL, component: AlabamaComponent },
      { path: stateNames.PATH_TO_GEORGIA, component: GeorgiaComponent },
      { path: stateNames.PATH_TO_GE, component: GeorgiaComponent },
      { path: stateNames.PATH_TO_FLORIDA, component: FloridaComponent },
      { path: stateNames.PATH_TO_FL, component: FloridaComponent },
      { path: stateNames.PATH_TO_MISSOURI, component: MissouriComponent },
      { path: stateNames.PATH_TO_MI, component: MissouriComponent },
      { path: stateNames.PATH_TO_MISSISSIPPI, component: MississippiComponent },
      { path: stateNames.PATH_TO_MS, component: MississippiComponent },
      { path: stateNames.PATH_TO_SOUTH_CAROLINA, component: SouthcarolinaComponent },
      { path: stateNames.PATH_TO_SC, component: SouthcarolinaComponent },
      { path: stateNames.PATH_TO_MINNESOTA, component: MinnesotaComponent },
      { path: stateNames.PATH_TO_MN, component: MinnesotaComponent },
      { path: stateNames.PATH_TO_COLORADO, component: ColoradoComponent },
      { path: stateNames.PATH_TO_CO, component: ColoradoComponent },
      { path: stateNames.PATH_TO_KANSAS, component: KansasComponent },
      { path: stateNames.PATH_TO_KS, component: KansasComponent },
      { path: stateNames.PATH_TO_TEXAS, component: TexasComponent },
      { path: stateNames.PATH_TO_TX, component: TexasComponent },
      { path: stateNames.PATH_TO_WISCONSIN, component: WisconsinComponent },
      { path: stateNames.PATH_TO_WI, component: WisconsinComponent },
      { path: stateNames.PATH_TO_UTAH, component: UtahComponent },
      { path: stateNames.PATH_TO_UT, component: UtahComponent },
      { path: stateNames.PATH_TO_NEVADA, component: NevadaComponent },
      { path: stateNames.PATH_TO_NE, component: NevadaComponent },
      { path: stateNames.PATH_TO_NEWMEXICO, component: NewmexicoComponent },
      { path: stateNames.PATH_TO_NM, component: NewmexicoComponent },
      { path: "**", component: NotFoundStateComponent },
      { path: stateNames.PATH_TO_NOT_FOUND, component: NotFoundStateComponent }
  ];

export const appRoutes: ModuleWithProviders = RouterModule.forRoot(
    appRoutesArray, { onSameUrlNavigation: "reload", relativeLinkResolution: "legacy" });
