<div class="modal fade" id="RegionModal" tabindex="-1" role="dialog" aria-labelledby="RegionModalLabel"
  aria-hidden="true">
  <div class="modal-dialog EndorsementsModal RegionModal" role="document" style="width:350px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="RegionModalLabel">Select Your Region</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeARegionModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="state-list-box">
          <ul>
            <li *ngFor="let item of regionsArray;let i=index" style="width: 100%;">
              <div class="form-check form-check-inline">
                <label class="region-wp">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                    (change)="radioChange(i)">
                 
                    <span style="font-size: 16px;font-weight: bold">{{item.regionCode}}</span>
                    <div class="regionDesc">{{item.description}}</div>                 
                </label>

              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-success" (click)="onApplyClick()">Apply</button>
            <button type="button" class="btn hide-second-loan-btn" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
