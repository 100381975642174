<div class="modal fade" id="quoteModel" tabindex="-1" role="dialog" aria-labelledby="quoteModelModalLabel" aria-hidden="true">
  <div class="modal-dialog quoteModel" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="quoteModelLabel">PREMIUM RATE QUOTE</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pre-rate-q" id="quote">
        <img src="../../../assets/logo_horizontal.png" class="pdf-logo">
        <div class="row span-style">
          <div class="col-md-6">
            <span class="text-right span-style-35">File Number: </span>
            <span class="span-style2">  {{pdfFormModel.fileNumber}}</span>
          </div>

          <div class="col-md-6 text-right" *ngIf="onwerPremiumAllData.coverageAmount && onwerPremiumAllData.coverageAmount>0">
            <span class="text-right span-style2">Owner Policy: $</span>
            <span class="span-style3">{{ownerTotal | currency:' ':''}}</span>
          </div>
        </div>

        <div class="row span-style">
          <div class="col-md-6">
            <span class="text-right span-style-35">Seller:</span>
            <span class="span-style2">  {{pdfFormModel.seller}}</span>
          </div>
          <div class="col-md-6 text-right" *ngIf="loanPremiumAllData.coverageAmount && loanPremiumAllData.coverageAmount>0">
            <span class="text-right span-style2">Loan Policy: $</span>
            <span class="span-style3">{{loanTotal | currency:' '}}</span>
          </div>
        </div>

        <div class="row span-style">
          <div class="col-md-6">
            <span class="text-right span-style-35">Buyer: </span>
            <span class="span-style2">  {{pdfFormModel.buyer}}</span>
          </div>

          <div class="col-md-6 text-right" *ngIf="basicConfigJson.availableSecondLoanComponent&&!hideAppSecondLoan">
            <span class="text-right span-style2">2nd Loan Policy: $</span>
            <span class="span-style3">{{secondLoanTotal | currency:' '}}</span>
          </div>
        </div>

        <div class="row span-style">
          <div class="col-md-6">
            <span class="text-right span-style-35">Reference:</span>
            <span class="span-style3"></span>
          </div>

          <div class="col-md-6 text-right">
            <span class="text-right span-style2">Total Premium: $</span>
            <span class="span-style3">{{grandTotal | currency:' '}}</span>
          </div>
        </div>
        <div class="row span-style">

          <div class="col-md-6">
            <span class="text-right span-style-35">Property: </span>
            <span class="span-style2">  {{pdfFormModel.property}}</span>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="row span-style">

          <div class="col-md-6">
            <span class="text-right span-style-35">State: </span>
            <span class="span-style2">  {{pdfFormModel.selectedState}}</span>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="row span-style">

          <div class="col-md-6">
            <span class="text-right span-style-35">County: </span>
            <span class="span-style2">  {{pdfFormModel.county}}</span>
          </div>
          <div class="col-md-6"></div>
        </div>

        <div class="row" *ngIf="onwerPremiumAllData.coverageAmount && onwerPremiumAllData.coverageAmount>0">
          <div class="col-md-12">
            <h2 class="pdf-heading">Owner Policy</h2>
          </div>
        </div>

        <div class="outer-box" *ngIf="onwerPremiumAllData.coverageAmount && onwerPremiumAllData.coverageAmount>0">

          <div class="row">
            <div class="col-md-6">
              <span>Coverage Amount: $</span>
              <span>{{onwerPremiumAllData.CoverageAmount | currency:' ' }}</span>
            </div>
            <div class="col-md-6 text-right">
              <span class="text-right span-style2">Policy Premium: $</span>
              <span class="span-style3">{{calculatedPremiumData.premiumOwner | currency:' '}}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <span>Rate Type: {{onwerPremiumAllData.SelectedRateTypeCode}}</span>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row" *ngIf="basicConfigJson.availableOwnerReissue">
            <div class="col-md-6">
              <span>Prior Policy Amount: $</span>
              <span>{{onwerPremiumAllData.PriorPolicyAmount | currency:' '}}</span>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row" *ngIf="basicConfigJson.availableOwnerEndorsements">
            <div class="col-md-12 ">
              <span>Endorsement: $</span>
              <span>{{calculatedPremiumData.premiumOEndors | currency:' '}} </span>
            </div>
          </div>

          <div class="grey-box" *ngIf="basicConfigJson.availableOwnerEndorsements">

            <div class="head">
              <strong>Endorsement</strong>
            </div>
            <div class="row">
              <div class="pdf-endorsement-box">
                <ul>
                  <li *ngFor="let endorsement of ownerEndorsements">
                    <span>{{endorsement.endorsementCode}} - $</span>
                    <span>{{endorsement.proposedRate | currency:' '}} </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right padr">
                <span>Endorsement Premium: $</span>
                <span>{{calculatedPremiumData.premiumOEndors | currency:' '}} </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6 text-right padding-right">
              <span>
                <strong>Total for Owner Policy: $</strong>
              </span>
              <span>{{ownerTotal | currency:' '}} </span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="loanPremiumAllData.coverageAmount && loanPremiumAllData.coverageAmount>0">
          <div class="col-md-12">
            <h2 class="pdf-heading">LOAN POLICY</h2>
          </div>
        </div>
        <div class="outer-box" *ngIf="loanPremiumAllData.coverageAmount && loanPremiumAllData.coverageAmount>0">

          <div class="row">
            <div class="col-md-6">
              <span>Coverage Amount: $</span>
              <span>{{loanPremiumAllData.CoverageAmount | currency:' '}}</span>
            </div>
            <div class="col-md-6 text-right">
              <span class="text-right span-style2">Policy Premium: $</span>
              <span class="span-style3">{{calculatedPremiumData.premiumLoan | currency:' '}}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <span>Rate Type: {{loanPremiumAllData.SelectedRateTypeCode}}</span>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row">
            <div class="col-md-6" *ngIf="basicConfigJson.availableLoanReissue">
              <span>Prior Policy Amount: $</span>
              <span>{{loanPremiumAllData.PriorPolicyAmount | currency:' '}}</span>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row" *ngIf="basicConfigJson.availableLoanEndorsements">
            <div class="col-md-12 ">
              <span>Endorsement: $</span>
              <span>{{calculatedPremiumData.premiumLEndors | currency:' '}} </span>
            </div>
          </div>

          <div class="grey-box" *ngIf="basicConfigJson.availableLoanEndorsements">

            <div class="head">
              <strong>Endorsement</strong>
            </div>

            <div class="row">
              <div class="pdf-endorsement-box">
                <ul>
                  <li *ngFor="let endorsement of loanEndorsements">
                    <span class="pdf-endorsement-code-label">{{endorsement.endorsementCode}} - $</span>
                    <span>{{endorsement.proposedRate | currency:' '}} </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right padr">
                <span>Endorsement Premium: $</span>
                <span>{{calculatedPremiumData.premiumLEndors | currency:' '}} </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6 text-right padding-right">
              <span>
                <strong>Total for Loan Policy: $</strong>
              </span>
              <span>{{loanTotal | currency:' '}} </span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="basicConfigJson.availableSecondLoanComponent && !hideAppSecondLoan">
          <div class="col-md-12">
            <h2 class="pdf-heading">SECOND LOAN POLICY</h2>
          </div>
        </div>
        <div class="outer-box" *ngIf="basicConfigJson.availableSecondLoanComponent && !hideAppSecondLoan">

          <div class="row">
            <div class="col-md-6">
              <span>Coverage Amount: $</span>
              <span>{{secondLoanPremiumAllData.CoverageAmount | currency:' '}}</span>
            </div>
            <div class="col-md-6 text-right">
              <span class="text-right span-style2">Policy Premium: $</span>
              <span class="span-style3">{{calculatedPremiumData.premiumScnd | currency:' '}}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <span>Rate Type: {{secondLoanPremiumAllData.SelectedRateTypeCode}}</span>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row" [hidden]="true">
            <div class="col-md-6">
              <span>Prior Policy Amount: </span>
              <span>$zzz,zzz,zz9</span>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row" *ngIf="basicConfigJson.availableSecondLoanEndorsements">
            <div class="col-md-12 ">
              <span>Endorsement: $</span>
              <span>{{calculatedPremiumData.premiumSEndors | currency:' '}} </span>
            </div>
          </div>

          <div class="grey-box" *ngIf="basicConfigJson.availableSecondLoanEndorsements">

            <div class="head">
              <strong>Endorsement</strong>
            </div>

            <div class="row">
              <div class="pdf-endorsement-box">
                <ul>
                  <li *ngFor="let endorsement of secondLoanEndorsements">
                    <span>{{endorsement.endorsementCode}} - $</span>
                    <span>{{endorsement.proposedRate | currency:' '}} </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right padr">
                <span>Endorsement Premium: $</span>
                <span>{{calculatedPremiumData.premiumSEndors | currency:' '}} </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6 text-right padding-right">
              <span>
                <strong>Total for Second Loan policy: $</strong>
              </span>
              <span>{{secondLoanTotal | currency:' '}} </span>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="basicConfigJson.availableOnlyCpl || basicConfigJson.availableLenderCpl">
          <div class="col-md-12">
            <h2 class="pdf-heading">CLOSING PROTECTION LETTER</h2>
          </div>
        </div>
        <div class="row" *ngIf="basicConfigJson.availableOnlyCpl || basicConfigJson.availableLenderCpl">
          <div class="col-md-2"></div>
          <div class="col-md-10 text-right">
            <div>
              <div class="row">
                <div class="pdf-cpl-amount-box" *ngIf="basicConfigJson.availableOnlyCpl">
                  <span class="amt-value">
                    <span>{{calculatedPremiumData.premiumCPL | currency:' '}}</span>
                  </span>
                  <span class="amt-label">
                    <strong>CPL</strong>
                  </span>
                </div>
                <div class="pdf-cpl-amount-box" *ngIf="basicConfigJson.availableLenderCpl">
                  <span class="amt-value">
                    <span>$ {{calculatedPremiumData.premiumLenderCPL | currency:' '}}</span>
                  </span>
                  <span class="amt-label">
                    <strong>Lender CPL</strong>
                  </span>
                </div>
                <div class="pdf-cpl-amount-box" *ngIf="basicConfigJson.availableBuyerCpl">
                  <span class="amt-value">
                    <span>$ {{calculatedPremiumData.premiumBuyerCPL}}</span>
                  </span>
                  <span class="amt-label">
                    <strong>Buyer CPL</strong>
                  </span>
                </div>
                <div class="pdf-cpl-amount-box" *ngIf="basicConfigJson.availableSellerCpl">
                  <span class="amt-value">
                    <span>$ {{calculatedPremiumData.premiumSellerCPL | currency:' '}}</span>
                  </span>
                  <span class="amt-label">
                    <strong>Seller CPL</strong>
                  </span>
                </div>
              </div>
              <div>
                <span>
                  <strong>Total for Closing Protection Letters:</strong>
                </span>
                <span>$ {{cplTotal | currency:' '}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12 text-center">
          <button type="button" class="btn btn-success" (click)="onDownloadPdfClick()">Download PDF</button>
        </div>
      </div>


    </div>
