<div class="container">
  <section class="final-calcualation">
    <div class="row justify-content-md-center">
      <div class="col-12 text-center">
        <button class="btn btn-primary calculate-btn" type="submit"
          (click)="onCalculateButtonClick()">Calculate</button>
      </div>
      <div class="col-12">
        <div class="final-calcualation-box">
          <div class="divider-two"></div>
          <div class="row justify-content-md-center">
            <div class="final-amount-box final-last "
              *ngIf="basicConfigJson.availableOwnerComponent && ownerComponentAvaialable">
              <span class="amt-value">
                <span currencyMask maxlength="10" *ngIf="ownerTotal % 1 ===0 ; else showDecimal">{{ownerTotal}}</span>
              </span>
              <ng-template #showDecimal>
                <span currencyMask>{{ownerTotal| number:'1.2-2'}}
              </span>
            </ng-template>
              <span class="amt-label">Owner Total</span>
            </div>
            <div class="final-amount-box"
              [ngClass]="{'final-two':ownerComponentAvaialable,'':!ownerComponentAvaialable}"
              *ngIf="basicConfigJson.availableLoanComponent">
              <span class="amt-value">
                <span currencyMask maxlength="10" *ngIf="loanTotal % 1 ===0 ; else showDecimal">{{loanTotal}}</span>
              </span>
              <ng-template #showDecimal>
                <span currencyMask>{{loanTotal| number:'1.2-2'}}
              </span>
            </ng-template>
              <span class="amt-label">First Loan Total</span>
            </div>
            <div class="final-amount-box final-three"
              *ngIf="basicConfigJson.availableSecondLoanComponent && secondLoanComponentAvaialable">
              <span class="amt-value">
                <span currencyMask maxlength="10" *ngIf="secondLoanTotal % 1 ===0 ; else showDecimal">{{secondLoanTotal}}</span>
              </span>
              <ng-template #showDecimal>
                <span currencyMask>{{secondLoanTotal| number:'1.2-2'}}
              </span>
            </ng-template>
              <span class="amt-label">Second Loan Total</span>
            </div>
            <div class="final-amount-box final-one" *ngIf="basicConfigJson.availableCplComponent">
              <span class="amt-value">
                <span currencyMask maxlength="10" *ngIf="cplTotal % 1 ===0 ; else showDecimal">{{cplTotal}}</span>
              </span>
              <ng-template #showDecimal>
                <span currencyMask>{{cplTotal| number:'1.2-2'}}
              </span>
            </ng-template>
              <span class="amt-label">{{selectedStateName == "NewMexico" ? "Commitment Total" : "CPL Total"}}</span>
            </div>
          </div>
          <div class="divider-two"></div>
          <div class="row justify-content-md-center">
            <div class="col-sm-12">
              <div class="final-amount-box final-four">
                <span class="amt-value">
                  <span currencyMask maxlength="10" *ngIf="grandTotal % 1 ===0 ; else showDecimal">{{grandTotal}}</span>
                </span>
                <ng-template #showDecimal>
                  <span currencyMask>{{grandTotal| number:'1.2-2'}}
                </span>
              </ng-template>
                <span class="amt-label">Grand Total</span>
              </div>
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-sm-12 text-center">
              <button type="button" #EmailPdfButton class="btn btn-success email-btn" data-toggle="modal"
                [attr.data-target]="'#'+currentPdfModal" [disabled]="disableEmailPdf">Generate Rate Sheet</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <div class="bottom-bar"> or
          <br>
          <button type="button" class="btn btn-dark clear-btn" (click)="onClearAllClick($event)">Clear</button>
        </div>
      </div>
    </div>
  </section>

</div>
<footer>The Alliant National Title Calculator (ANTC) is an Internet-based platform, which provides our customers with
  a user-friendly method of
  obtaining estimates for certain categories of settlement related costs. There may be variables that need to be
  considered in the determining
  the final rate to be charged, including geographic and transaction-specific items, which are beyond the
  functionality provided by the ANTC.
  All estimates obtained using this calculator are dependent upon the accuracy of the information input into the
  calculator and no guarantee
  of issuance is expressed or implied. Please contact your local Alliant National Title agent to confirm your quote.
  Contact information for
  <span>Alliant National Title </span>agent offices in your area is available at www.alliantnational.com.</footer>
<app-pdf-generation-form (generatePdfClick)="generatePdfClick()"></app-pdf-generation-form>
<app-premium-rate-quotes [basicConfigJson]="basicConfigJson"></app-premium-rate-quotes>