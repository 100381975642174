import { EndorsementModel } from "./EndorsementModel";

export class SharePremiumDataModel {
    selectedEndorsementsArray: Array<EndorsementModel> = [];
    coverageAmount = 0;
    priorPolicyAmount = 0;
    selectedRateTypeCode = "none";
    selectedRateType = "none";
    secondLoanAdded = false;
    simultaneous = "no";
    effectiveDate = "";
    enablePriorPolicyAmount = false;
    effectiveDateAvailable = false;
}
