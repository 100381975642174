export class PdfFormModel {
    fileNumber = "";
    email = "";
    seller = "";
    buyer = "";
    reference = "";
    property = "";
    county = "";
    selectedState = "";
    selectedStateIndex = 0;
}
