import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Observable, Subscription } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { CallWebApi } from "../../ApiCallInterface/CallWebApi";
import { API } from "../../Constants/API";
import { BasicConfigStatewise } from "../../Constants/BasicConfigStatewise";
import { Constants } from "../../Constants/Constants";
import { ApiResponseCallback } from "../../Interfaces/ApiResponseCallback";
import { PdfFormModel } from "../../Models/PdfFormModel";
import { RequestResponseModel } from "../../Models/RequestResponseModel";
import { ShareCalculatedPremiumModel } from "../../Models/ShareCalculatedPremiumModel";
import { StateModel } from "../../Models/StateModel";
import { StringFormatPipe } from "../../Pipes/string-format/string-format.pipe";
import { ApiService } from "../../services/apiService/api.service";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { ModalService } from "../../services/modelService";
import { CommonFunctions } from "../../Utils/CommonFunctions";

@Component({
    selector: "app-pdf-generation-form",
    templateUrl: "./pdf-generation-form.component.html",
    styleUrls: ["./pdf-generation-form.component.css"]
})
export class PdfGenerationFormComponent implements OnInit, OnDestroy, ApiResponseCallback {
    states: Array<StateModel> = [];
    pdfFormModel: PdfFormModel = new PdfFormModel();
    @ViewChild("closePdfFormModal") closePdfFormModal: ElementRef;

    @Output() generatePdfClick = new EventEmitter<string>();
    @ViewChild("instance") instance: NgbTypeahead;

    fileNumberArray: Array<string> = [];
    selectedState = "";
    pdfFormModelArray: Array<PdfFormModel> = [];
    tempPdfFormModelArray: Array<PdfFormModel> = [];
    autoCompletePopupOpened = false;
    selectedStateSubscription: Subscription = null;

    calculatedPremiumData: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    requestResponseModel: RequestResponseModel = new RequestResponseModel();

    downloadPdfYes = "yes";
    downloadPdfNo = "no";
    email: FormControl;
    form: FormGroup;
    fileName = "";
    unFormattedString = "";

    apiSuccessCalculatedPremiumSubscription: Subscription = null;
    requestResponseSubscription: Subscription = null;
    reselAllFieldsSubscription: Subscription = null;

    search = (text$: Observable<string>) =>
        text$.pipe(
            distinctUntilChanged(),
            map(term => (term.length < 1 ? [] : this.fileNumberArray.filter(
                v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
        );

    constructor(
        private modalService: ModalService,
        public commonFunctions: CommonFunctions,
        public api: API,
        public stringFormat: StringFormatPipe,
        public dataservice: DataServiceService,
        private basicConfig: BasicConfigStatewise,
        public apiService: ApiService,
        @Inject(LOCAL_STORAGE) public localStorage: WebStorageService,
        private dataService: DataServiceService,
        public callWebApi: CallWebApi,
        public constants: Constants,
        public alertModalService: NgbModal,
        private formBuilder: FormBuilder,
        public cdr: ChangeDetectorRef
    ) {
        this.modalService.add(this);
    }

    ngOnInit() {
        getStates(this);
        getPdfFileArray(this);
        registerAndGetAllPremiumData(this);
        registerResetAllFields(this);

        this.form = this.formBuilder.group({
            email: [null, [Validators.required, Validators.email]]
        });

        this.email = new FormControl(null, {
            validators: Validators.required,
            updateOn: "blur"
        });
    }
    onSuccess(response: any) {
        this.states = response;
        this.onStateChange(0);
        getSelectedState(this);
        // getInputFromLocalStorage(this);
    }
    onError(errorCode: number, errorMsg: string) { }

    onStateChange(index) {
        // this.pdfFormModel.selectedState = this.states[index].state_name;
        // this.pdfFormModel.selectedStateIndex = index;
    }

    onFileNumberChange(fileNumber) {
        setTimeout(() => {
            if (this.instance.isPopupOpen() && !this.autoCompletePopupOpened) {
                this.autoCompletePopupOpened = this.instance.isPopupOpen();
            }
            if (!this.instance.isPopupOpen() && this.autoCompletePopupOpened) {
                console.log("file number " + this.pdfFormModel.fileNumber);
                getAndSetPdfData(this);
                this.autoCompletePopupOpened = false;
            }
        }, 100);
    }
    onDownloadPdfClick() {
        processPdfRequest(this, this.downloadPdfYes);
    }

    onEmailPdfClick() {
        processPdfRequest(this, this.downloadPdfNo);
    }
    ngOnDestroy(): void {
        if (this.apiSuccessCalculatedPremiumSubscription && !this.apiSuccessCalculatedPremiumSubscription.closed) {
            this.apiSuccessCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.requestResponseSubscription && !this.requestResponseSubscription.closed) {
            this.requestResponseSubscription.unsubscribe();
        }
        if (this.reselAllFieldsSubscription && !this.reselAllFieldsSubscription.closed) {
            this.reselAllFieldsSubscription.unsubscribe();
        }
    }
}

function getStates(context: PdfGenerationFormComponent) {
    context.apiService.hitGetApi(context.api.states, context);
}

function getSelectedState(context: PdfGenerationFormComponent) {
    context.selectedStateSubscription = context.dataservice.shareSelectedStateObservable.subscribe(selectedState => {
        if (selectedState) {
            context.pdfFormModel.selectedState = selectedState.split(/(?=[A-Z])/).join(" ");
            context.cdr.markForCheck();
        }
    });
}

function setInputsToLocalStorage(json: any, context: PdfGenerationFormComponent) {
    context.localStorage.set(context.constants.pdfGenerationPreFormData, json);
}
// function getInputFromLocalStorage(context: PdfGenerationFormComponent) {
//   let inputFormData = context.localStorage.get(context.constants.PDF_GENERATION_PRE_FORM_DATA);
//   if (inputFormData)
//     context.pdfFormModel = JSON.parse(inputFormData);
//   context.onStateChange(context.pdfFormModel.selectedStateIndex);
// }

function setPdfFileArray(context: PdfGenerationFormComponent) {
    localStorage.setItem(context.constants.pdfFormDataWithFileNumber, JSON.stringify(context.tempPdfFormModelArray));
}

function getPdfFileArray(context: PdfGenerationFormComponent) {
    context.pdfFormModelArray = JSON.parse(localStorage.getItem(context.constants.pdfFormDataWithFileNumber));
    createFileNumberArray(context);
}

function createFileNumberArray(context: PdfGenerationFormComponent) {
    if (context.pdfFormModelArray) {
        context.fileNumberArray = [];
        context.pdfFormModelArray.forEach(element => {
            context.fileNumberArray.push(element.fileNumber);
        });
    } else {
        context.pdfFormModelArray = [];
    }
}

function getAndSetPdfData(context: PdfGenerationFormComponent) {
    if (context.pdfFormModelArray && context.pdfFormModelArray.length > 0) {
        context.pdfFormModelArray.forEach(element => {
            if (context.pdfFormModel.fileNumber === element.fileNumber) {
                context.pdfFormModel = element;
                context.onStateChange(context.pdfFormModel.selectedStateIndex);
            }
        });
    }
}

function registerAndGetAllPremiumData(context: PdfGenerationFormComponent) {
    context.requestResponseSubscription = context.dataservice.requestResponseObservable.subscribe(requestResponseModel => {
        context.requestResponseModel = requestResponseModel;
    });
    context.apiSuccessCalculatedPremiumSubscription = context.dataservice.onApiSuccessCalculatedPremiumObservable.subscribe(
        calculatedPremium => {
            context.calculatedPremiumData = calculatedPremium;
        }
    );
}

function processPdfRequest(context: PdfGenerationFormComponent, downloadFlag: string) {

    context.unFormattedString = context.pdfFormModel.fileNumber;
    const downloadPdfFlag: boolean = downloadFlag === context.downloadPdfYes;
    if (validateData(context, downloadPdfFlag)) {
        if (context.pdfFormModelArray && context.pdfFormModel.fileNumber) {
            const itemIndex: number = isFileNumberSaved(context);
            if (itemIndex === -1 && context.pdfFormModelArray.length <= 5) {
                context.tempPdfFormModelArray.push(context.pdfFormModel);
            }
            setPdfFileArray(context);
        }
        createRequestJson(context, downloadFlag);
        getPdfFileArray(context);
    }
}

function validateData(context: PdfGenerationFormComponent, downloadPdfFlag: boolean) {
    let validationMsg = "";
    // if (!context.pdfFormModel.fileNumber || context.pdfFormModel.fileNumber == "")
    //   validationMsg = "File number can not left blank";
    if (!downloadPdfFlag) {
        if (!context.pdfFormModel.email || context.pdfFormModel.email === "") {
            validationMsg = "Email can not left blank";
        }
    }
    // else if (!context.pdfFormModel.seller || context.pdfFormModel.seller == "")
    //   validationMsg = "Seller can not left blank";
    // else if (!context.pdfFormModel.buyer || context.pdfFormModel.buyer == "")
    //   validationMsg = "Buyer can not left blank";
    // else if (!context.pdfFormModel.reference || context.pdfFormModel.reference == "")
    //   validationMsg = "Reference can not left blank";
    // else if (!context.pdfFormModel.property || context.pdfFormModel.property == "")
    //   validationMsg = "Property can not left blank";
    // else if (!context.pdfFormModel.selectedState || context.pdfFormModel.selectedState == "")
    //   validationMsg = "State can not left blank";
    // else if (!context.pdfFormModel.county || context.pdfFormModel.county == "")
    //   validationMsg = "County can not left blank";

    if (validationMsg) {
        context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, validationMsg);
        return false;
    } else {
        return true;
    }
}

function isFileNumberSaved(context: PdfGenerationFormComponent) {
    let fileNumberFound = false;
    let itemIndex = -1;
    context.tempPdfFormModelArray = context.pdfFormModelArray;
    if (!context.tempPdfFormModelArray) {
        context.tempPdfFormModelArray = [];
    }
    if (context.tempPdfFormModelArray && context.tempPdfFormModelArray.length > 0) {
        context.tempPdfFormModelArray.forEach((element, index) => {
            if (!fileNumberFound) {
                if (context.pdfFormModel.fileNumber === element.fileNumber) {
                    fileNumberFound = true;
                    itemIndex = index;
                }
            }
        });
    }

    return itemIndex;
}

function registerResetAllFields(context: PdfGenerationFormComponent) {
    context.reselAllFieldsSubscription = context.dataservice.reselAllFieldsObservable.subscribe(function () {
        resetFields(context);
    });
}

function createRequestJson(context: PdfGenerationFormComponent, downloadPdfFlag: string) {
    const calculatedDataString = context.requestResponseModel.response.replace(/=/g, "^");

    let paramsString = "";
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.fileNumber, context.pdfFormModel.fileNumber, false);
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.seller, context.pdfFormModel.seller, false);
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.buyer, context.pdfFormModel.buyer, false);
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.reference, context.pdfFormModel.reference, false);
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.property, context.pdfFormModel.property, false);
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.county, context.pdfFormModel.county, false);
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.batchProcess, true, false);
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.state, context.pdfFormModel.selectedState, true);

    paramsString = paramsString.split("&").join("%26");

    context.callWebApi.callPdfGenerationApi(
        context.requestResponseModel.stateId,
        calculatedDataString,
        context.requestResponseModel.request,
        paramsString,
        context.pdfFormModel.email,
        downloadPdfFlag,
        {
            onSuccess(response) {
                // var byteCharacters = atob(response);
                // var byteNumbers = new Array(byteCharacters.length);
                // for (var i = 0; i < byteCharacters.length; i++) {
                //   byteNumbers[i] = byteCharacters.charCodeAt(i);
                // }
                // var byteArray = new Uint8Array(byteNumbers);
                // var blob = new Blob([byteArray], { type: 'application/pdf' });
                // var blobUrl = URL.createObjectURL(blob);
                // let windowsObj: Window = window.open(blobUrl, "_blank",'location=yes,height=570,width=520,scrollbars=yes,status=yes');

                if (downloadPdfFlag === context.downloadPdfYes) {
                    downloadPdf(context, response);
                } else {
                    context.commonFunctions.showAlertDialog(
                        context.constants.emailSentTitle,
                        context.stringFormat.transform(context.constants.pdfSent, context.pdfFormModel.email)
                    );
                }
                context.closePdfFormModal.nativeElement.click();
            },
            onError(errorCode, errormsg) {
                context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, errormsg);
            }
        }
    );
}

function resetFields(context: PdfGenerationFormComponent) {
    context.pdfFormModel = new PdfFormModel();
}

function downloadPdf(context: PdfGenerationFormComponent, response: any) {
    const currentSelectedState = context.localStorage.get(context.constants.selectedState);
    const newBlob = new Blob([response], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob); // For IE browser
    }
    const linkSource = "data:application/pdf;base64," + response;
    const downloadLink = document.createElement("a");
    const regex = /^[a-zA-Z0-9]*$/;
    // write method to correct the file number //
    if (!regex.test(context.pdfFormModel.fileNumber)) {
        const str = context.pdfFormModel.fileNumber;
        let newStr = "";
        for (let i = 0; i < str.length; i++) {
            const char = str.charAt(i);
            if (regex.test(char)) {
                newStr = newStr + char;
            }
        }
        context.fileName = newStr.toUpperCase();
    } else {
        context.fileName = context.pdfFormModel.fileNumber;
    }
    if (context.pdfFormModel.fileNumber.trim() !== "") {
        context.fileName = currentSelectedState.replace(" ", "_") + "_Premium_Rate_Quote" + "_" +
            "for_file_" + context.fileName.toUpperCase();
    } else {
        context.fileName = currentSelectedState.replace(" ", "_") + "_Premium_Rate_Quote";
    }
    // let fileNumber = context.pdfFormModel.fileNumber ? "_" + context.pdfFormModel.fileNumber : "";
    // const fileName = currentSelectedState.replace(" ", "_") + "_Premium_Rate_Quote";
    // context.stringFormat.transform(context.constants.DOWNLOAD_PDF_TITLE, currentSelectedState, fileNumber);
    context.pdfFormModel.fileNumber = context.unFormattedString;



    downloadLink.href = linkSource;
    downloadLink.download = context.fileName;
    downloadLink.click();
    context.fileName = "";
}
