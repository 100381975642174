<div class="container">
  <section>
    <h2 class="h2Color">Loan
      <a href="#collapseLoan" data-toggle="collapse" aria-expanded="true" aria-controls="collapseLoan"
        [ngClass]="{'':showComponent,'show':!showComponent}"
        (click)="onShowHideClick($event)">{{showComponent?'Collapse':'Expand'}}</a>
    </h2>
    <main id="collapseLoan" class="collapse show">
      <div class="simultaneous_policy">
        <div class="row" *ngIf="!hideSecondLoanButton && (ownerComponentAvaialable || secondLoanComponentAvaialable)">
          <div class="col-12 text-center">
            <span>This is a Simultaneous Policy. </span>
          </div>
        </div>
        <div class="row"
          *ngIf="basicConfigJson.manualSimo && (ownerComponentAvaialable || secondLoanComponentAvaialable)">
          <div class="col-12 text-center">
            <input type="checkbox" id="1" [ngModel]="simoCheck" (ngModelChange)="onSimoCheckChange($event)">
            Simultaneous
          </div>
        </div>

        <div class="divider" *ngIf="!hideSecondLoanButton || basicConfigJson.manualSimo"></div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Loan Amount</label>
            <input currencyMask type="text" class="form-control" placeholder="0" [(ngModel)]="loanAmount"
              (ngModelChange)="onCoverageAmountChange($event)" [options]="{ precision: 0 }" maxlength="11"
              [disabled]="disableCoverageAmount">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Rate Type</label>
            <select class="form-control" (change)="onLoanRateTypeDropDownChanged($event.target.value)"
              [(ngModel)]="selectedRateTypeIndex" [disabled]="disableRateType">
              <option *ngFor="let item of loanRateTypes;let i = index" [value]="i">{{item.description}}</option>

            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group" *ngIf="basicConfigJson.availableLoanReissue&&!hideLoanPriorPolicyInput">
            <div *ngIf="nevadaLoanParcel !== 'builder'">
              <label>Prior Policy Amount</label>
              <input currencyMask type="text" class="form-control" placeholder="0" [(ngModel)]="priorPolicyAmount"
              (ngModelChange)="onPriorPolicyAmountChange($event)" [options]="{ precision: 0 }" maxlength="11">
            </div>

            <div *ngIf="nevadaLoanParcel === 'builder'">
              <label>Number of Parcels</label>
              <input type="number" class="form-control" [(ngModel)]="priorPolicyAmount"
                (ngModelChange)="onPriorPolicyAmountChange($event)" [options]="{ precision: 0 }" maxlength="11">
            </div>
            
          </div>
        </div>
        <!-- changes by me start here -->
        <div class="col-md-4" *ngIf="selectedRateTypeModel.priorPolicyDateFlag">
          <div class="form-group">
            <label>Effective Date</label>
            <input class="form-control calendar-text-box" placeholder="mm-dd-yyyy" name="dp" [(ngModel)]="effectiveDate"
              ngbDatepicker #d="ngbDatepicker" [maxDate]="maxEffectiveDate" [minDate]="minEffectiveDate"
              (click)="d.toggle()" (ngModelChange)="onEffectiveDateChanged($event)" readonly>
          </div>
        </div>
        <!-- end here -->
      </div>
      <div class="divider"></div>
      <div class="row">
        <div class="col-sm-12">
          <div class="endorsement-box" *ngIf="basicConfigJson.availableLoanEndorsements">
            <label>Endorsements:</label>
            <div class="clearfix"></div>
            <input type="text" class="form-control large" placeholder="" [(ngModel)]="selectedEndorsementsIdsArray"
              readonly [disabled]="disableEndorsementBtn">
            <button type="button" class="btn btn-primary btn-lg btn-endorse" data-toggle="modal"
              data-target="#EndorsementsModal" (click)="onLoanOpenEndorsementClick($event)"
              [disabled]="disableEndorsementBtn">Select Endorsements</button>
          </div>
        </div>
      </div>
      <div class="calcualation-box">
        <div class="row">
          <div class="amount-box one" *ngIf="basicConfigJson.availableLoanEndorsements">
            <span class="amt-value">
              <span currencyMask *ngIf="calculatedPremiumData.premiumLoan % 1 ===0 ; else showDecimal">{{(calculatedPremiumData.premiumLoan)}}</span>
            </span>

            <ng-template #showDecimal>
              <span currencyMask>{{(calculatedPremiumData.premiumLoan)| number:'1.2-2'}}
            </span>
            </ng-template>

            <span class="amt-label">Premium Amount</span>
          </div>
          <div class="amount-box two" *ngIf="basicConfigJson.availableLoanEndorsements">
            <span class="amt-value ">
              <span currencyMask *ngIf="calculatedPremiumData.premiumLEndors % 1 ===0 ; else showDecimal">{{(calculatedPremiumData.premiumLEndors)}}</span>
            </span>
          <ng-template #showDecimal>
              <span currencyMask>{{(calculatedPremiumData.premiumLEndors)| number:'1.2-2'}}
            </span>
          </ng-template>
            
            <span class="btn-align" title="View Endorsment Premium Details" data-toggle="tooltip" data-placement="top">
              <button type="button" class="btn btn-primary btn-endor-pre" data-toggle="modal"
                data-target="#Endorsementspremium" (click)="onShowCalculatedEndorsementClick($event)"
                [disabled]="disableEndorsementBtn"></button>
            </span>
            <span class="amt-label">Endorsement Amount</span>
          </div>
          <div class="amount-box three">
            <span class="amt-value">
              <span
                currencyMask *ngIf = "(calculatedPremiumData.premiumLoan+calculatedPremiumData.premiumLEndors) % 1===0 ; else showDecimal">{{(calculatedPremiumData.premiumLoan+calculatedPremiumData.premiumLEndors)}}</span>
            </span>

            <ng-template #showDecimal>
              <span currencyMask>{{(calculatedPremiumData.premiumLoan+calculatedPremiumData.premiumLEndors)| number:'1.2-2'}}
            </span>
          </ng-template>
            <span class="amt-label">Total Loan Amount</span>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="second-loan-btn-box">
        <div class="divider"></div>
        <div class="row">
          <div class="col-12 text-center">
            <button type="button" [ngClass]="showSecondLoanBtnCss"
              *ngIf="!hideSecondLoanButton && basicConfigJson.availableSecondLoanComponent && secondLoanComponentAvaialable"
              (click)="onShowHideSecondButtonClick()"
              [disabled]="disableSecondLoanBtn">{{showSecondButtonText}}</button>
          </div>
        </div>
      </div>
    </main>
  </section>
</div>