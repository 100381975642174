/* eslint-disable guard-for-in */
import { Constants } from "../Constants/Constants";
import { CommonFunctions } from "./CommonFunctions";
import { Injectable } from "@angular/core";
@Injectable({
    providedIn: "root"
})
export class Utilities {

    constructor(public constants: Constants,
        public commonFunctions: CommonFunctions) { }

    convertResponseToJson(response: string) {
        const responseArray = response.split(",");
        const json: any = {};
        responseArray.forEach(element => {
            const elementArray = element.split("=");
            const key = elementArray[0];
            let value: any = elementArray[1];
            const valueInNumber = Number(value);
            if (!isNaN(valueInNumber)) {
                value = valueInNumber;
            }
            json[key] = value;
        });
        return json;
    }

    convertRequestToGeneratePdfRequest(request: string) {

        const requestArray = request.split(",");
        const json: any = {};
        requestArray.forEach(element => {
            const elementArray = element.split("^");
            const key = elementArray[0];
            let value: any = elementArray[1];
            const valueInNumber = Number(value);
            if (!isNaN(valueInNumber)) {
                value = valueInNumber;
            }
            getValuesFromJson(key, value, json, this);

        });

        return convertRequestJsonToString(json, this);
    }
}

function getValuesFromJson(key: string, value, json: any, context: Utilities) {
    switch (key) {
        case context.constants.ownerRateType:
            key = context.constants.ownerRateTypeCode;
            break;
        case context.constants.ownerRateTypeCode:
            key = context.constants.ownerRateType;
            break;
        case context.constants.loanRateType:
            key = context.constants.loanRateTypeCode;
            break;
        case context.constants.loanRateTypeCode:
            key = context.constants.loanRateType;
            break;
        case context.constants.secondLoanRateType:
            key = context.constants.secondLoanRateTypeCode;
            break;
        case context.constants.secondLoanRateTypeCode:
            key = context.constants.secondLoanRateType;
            break;

    }
    json[key] = value;
}

function convertRequestJsonToString(json: any, context: Utilities) {
    let requestString = "";
    // for (let key = 0, n = json.length; key < n; key++) {
    for (const key in json) {
        requestString = context.commonFunctions.createParams(requestString, key.toString(), json[key], false);
    }

    return requestString;
}
