"use strict";
import * as states from "./States";

export const STATES = "states";
export const LAUNCHING_PATH = "/states";
export const DEFAULT_PATH = "";
export const PATH_TO_ALABAMA = DEFAULT_PATH + states.ALABAMA;
export const PATH_TO_AL = DEFAULT_PATH + states.AL;
export const PATH_TO_GEORGIA = DEFAULT_PATH + states.GEORGIA;
export const PATH_TO_GE = DEFAULT_PATH + states.GE;
export const PATH_TO_FLORIDA = DEFAULT_PATH + states.FLORIDA;
export const PATH_TO_FL = DEFAULT_PATH + states.FL;
export const PATH_TO_MISSOURI = DEFAULT_PATH + states.MISSOURI;
export const PATH_TO_MI = DEFAULT_PATH + states.MI;
export const PATH_TO_MISSISSIPPI = DEFAULT_PATH + states.MISSISSIPPI;
export const PATH_TO_MS = DEFAULT_PATH + states.MS;
export const PATH_TO_SOUTH_CAROLINA = DEFAULT_PATH + states.SOUTH_CAROLINA;
export const PATH_TO_SC = DEFAULT_PATH + states.SC;
export const PATH_TO_MINNESOTA = DEFAULT_PATH + states.MINNESOTA;
export const PATH_TO_MN = DEFAULT_PATH + states.MN;
export const PATH_TO_COLORADO = DEFAULT_PATH + states.COLORADO;
export const PATH_TO_CO = DEFAULT_PATH + states.CO;
export const PATH_TO_KANSAS = DEFAULT_PATH + states.KANSAS;
export const PATH_TO_KS = DEFAULT_PATH + states.KS;
export const PATH_TO_TEXAS = DEFAULT_PATH + states.TEXAS;
export const PATH_TO_TX = DEFAULT_PATH + states.TX;
export const PATH_TO_WISCONSIN = DEFAULT_PATH + states.WISCONSIN;
export const PATH_TO_WI = DEFAULT_PATH + states.WI;
export const PATH_TO_UTAH = DEFAULT_PATH + states.UTAH;
export const PATH_TO_UT = DEFAULT_PATH + states.UT;
export const PATH_TO_NEVADA = DEFAULT_PATH + states.NEVADA;
export const PATH_TO_NE = DEFAULT_PATH + states.NE;
export const PATH_TO_NEWMEXICO = DEFAULT_PATH + states.NEWMEXICO;
export const PATH_TO_NM = DEFAULT_PATH + states.NM;
export const PATH_TO_NOT_FOUND = DEFAULT_PATH + "notFound";
