import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-not-found-state",
    templateUrl: "./not-found-state.component.html",
    styleUrls: ["./not-found-state.component.css"]
})
export class NotFoundStateComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
