import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subscription } from "rxjs";
import { Constants } from "../../Constants/Constants";
import { BasicConfigModel } from "../../Models/BasicConfigModel";
import { ShareCalculatedPremiumModel } from "../../Models/ShareCalculatedPremiumModel";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";
import * as states from "../../Constants/States";

@Component({
    selector: "app-total",
    templateUrl: "./total.component.html",
    styleUrls: ["./total.component.css"]
})
export class TotalComponent implements OnInit, OnDestroy {
    @Input()
    basicConfigJson: BasicConfigModel;
    @Input()
    selectedState: string;
    @Output()
    calculateClick: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("EmailPdfButton")
    emailPdfButton: ElementRef;

    calculatedPremiumData: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    ownerTotal = 0;
    loanTotal = 0;
    secondLoanTotal = 0;
    cplTotal = 0;
    grandTotal = 0;
    totalPremium = 0;
    selectedStateName = "";
    currentPdfModal = "quoteModel";
    disableEmailPdf = false;
    premiumCalculated = false;
    ownerComponentAvaialable = true;
    secondLoanComponentAvaialable = true;
    rateTypeDataSubscripton: Subscription;
    apiSuccessCalculatedPremiumSubscription: Subscription;
    resetFieldsForCalculatedPremiumSubscription: Subscription;
    selectedStateSubscription: Subscription;

    constructor(public dataService: DataServiceService,
        @Inject(LOCAL_STORAGE) public localStorage: WebStorageService,
        public constants: Constants,
        public modalService: NgbModal,
        public commonFunctions: CommonFunctions) { }

    ngOnInit() {

        getCalculatedPremium(this);
        registerPropertyTypeChangeObservable(this);
        setEmailPdfView(this);
        registerResetAllFieldsForCal(this);
        getRateType(this);
        getSelectedState(this);
    }

    onCalculateButtonClick() {
        this.calculateClick.emit(1);
    }

    onClearAllClick(event) {
        resetAndSendResetAllDataBroadcast(this);

    }
    getAmount(value: number) {
        return this.commonFunctions.getFormattedAmount(value);
    }

    ngOnDestroy(): void {

        if (this.rateTypeDataSubscripton && !this.rateTypeDataSubscripton.closed) {
            this.rateTypeDataSubscripton.unsubscribe();
        }
        if (this.apiSuccessCalculatedPremiumSubscription && !this.apiSuccessCalculatedPremiumSubscription.closed) {
            this.apiSuccessCalculatedPremiumSubscription.unsubscribe();
        }

        if (this.resetFieldsForCalculatedPremiumSubscription && !this.resetFieldsForCalculatedPremiumSubscription.closed) {
            this.resetFieldsForCalculatedPremiumSubscription.unsubscribe();
        }

    }
    onEmailClick() {

    }
    generatePdfClick() {
        setEmailPdfView(this);
        this.currentPdfModal = "quoteModel";
        setTimeout(() => {
            this.emailPdfButton.nativeElement.click();
            this.currentPdfModal = "EmailModal";
        }, 500);
    }
}

function getCalculatedPremium(context: TotalComponent) {
    context.apiSuccessCalculatedPremiumSubscription = context.dataService.onApiSuccessCalculatedPremiumObservable.subscribe(
        calculatedPremium => {
            context.calculatedPremiumData = calculatedPremium;
            context.premiumCalculated = true;            
            context.disableEmailPdf = (context.calculatedPremiumData.success).toLowerCase() === "y" ? false : true;
            calculateTotal(context);
        });
}

function calculateTotal(context: TotalComponent) {
    context.ownerTotal = context.calculatedPremiumData.premiumOwner + context.calculatedPremiumData.premiumOEndors;
    context.loanTotal = context.calculatedPremiumData.premiumLoan + context.calculatedPremiumData.premiumLEndors;
    context.secondLoanTotal = context.calculatedPremiumData.premiumScnd + context.calculatedPremiumData.premiumSEndors;
    context.cplTotal = context.calculatedPremiumData.premiumLenderCPL + context.calculatedPremiumData.premiumBuyerCPL +
        context.calculatedPremiumData.premiumSellerCPL + context.calculatedPremiumData.premiumCPL;

    if (context.selectedState === "Colorado" && context.calculatedPremiumData.totalPremium !== 0) {
        context.grandTotal = context.calculatedPremiumData.totalPremium;
    } else if ((context.selectedStateName === states.NEWMEXICO)) {
        context.grandTotal = context.ownerTotal + context.loanTotal + context.secondLoanTotal + context.cplTotal;
        context.grandTotal = Number(context.grandTotal.toFixed());
    } else {
        context.grandTotal = context.ownerTotal + context.loanTotal + context.secondLoanTotal + context.cplTotal;
        context.grandTotal = Number(context.grandTotal.toFixed(2));
    }
}


function registerPropertyTypeChangeObservable(context: TotalComponent) {
    context.rateTypeDataSubscripton = context.dataService.rateTypeDataObservable.subscribe(endorsements => {
        resetFields(context);
    });
}
function resetAndSendResetAllDataBroadcast(context: TotalComponent) {
    resetFields(context);
    context.dataService.onResetAllFieldsClick();
}

function setEmailPdfView(context: TotalComponent) {
    context.currentPdfModal = "EmailModal";
    // if (context.localStorage.get(context.constants.PDF_GENERATION_PRE_FORM_DATA)) {
    //   context.currentPdfModal = "quoteModel";
    // }
    // else {
    //   context.currentPdfModal = "EmailModal";
    // }
}

function getRateType(context: TotalComponent) {
    context.rateTypeDataSubscripton = context.dataService.rateTypeDataObservable.subscribe(rateTypes => {
        try {
            if (rateTypes) {
                const allRateTypes = JSON.parse(rateTypes);
                context.ownerComponentAvaialable = allRateTypes.hasOwnProperty("ownerRateType");
                context.secondLoanComponentAvaialable = allRateTypes.hasOwnProperty("scndLoanRateType");
                // if (!context.ownerComponentAvaialable) {
                //   document.getElementById('loanAmount').style.background = 'none'
                // }else{
                //   document.getElementById('loanAmount').style.background = 'url(../../../icons/plus-symbol-white.svg)
                // no-repeat transparent left center/18px auto;'
                // }

            }
        } catch (error) {

        }

    });
}

function getSelectedState(context: TotalComponent) {
    context.selectedStateSubscription = context.dataService.shareSelectedStateObservable.subscribe(selectedState => {
        if (selectedState) {
            context.selectedStateName = selectedState;
        }
    });
}

function registerResetAllFieldsForCal(context: TotalComponent) {
    context.resetFieldsForCalculatedPremiumSubscription = context.dataService.resetFieldsOnCalculatedPremiumObservable.subscribe(
        function () {
            context.premiumCalculated = false;
            resetFields(context);
        });
}

function resetFields(context: TotalComponent) {
    context.calculatedPremiumData = new ShareCalculatedPremiumModel();
    context.disableEmailPdf = true;
    calculateTotal(context);
}

