<div class="container">
  <section>
    <h2 class="h2Color">Second Loan
      <a href="#collapseSecondLoan" data-toggle="collapse" aria-expanded="true" aria-controls="collapseSecondLoan"
        [ngClass]="{'':showComponent,'show':!showComponent}"
        (click)="onShowHideClick($event)">{{showComponent?'Collapse':'Expand'}}</a>
    </h2>
    <main id="collapseSecondLoan" class="collapse show">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Loan Amount</label>
            <input currencyMask type="text" class="form-control" placeholder="0" [(ngModel)]="secondLoanAmount"
              (ngModelChange)="onCoverageAmountChange($event)" [options]="{ precision: 0 }" maxlength="11">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Rate Type</label>
            <select class="form-control" (change)="onLoanRateTypeDropDownChanged($event.target.value)"
              [(ngModel)]="selectedRateTypeIndex">
              <option *ngFor="let item of secondLoanRateTypes;let i = index" [value]="i">{{item.description}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="row">
        <div class="col-sm-12">
          <div class="endorsement-box" *ngIf="basicConfigJson.availableSecondLoanEndorsements">
            <label>Endorsements:</label>
            <div class="clearfix"></div>
            <input type="text" class="form-control large" placeholder="" [(ngModel)]="selectedEndorsementsIdsArray"
              readonly>
            <button type="button" class="btn btn-primary btn-lg btn-endorse" data-toggle="modal"
              data-target="#EndorsementsModal" (click)="onLoanOpenEndorsementClick($event)">Select Endorsements</button>
          </div>
        </div>
      </div>
      <div class="calcualation-box">
        <div class="row">
          <div class="amount-box one" *ngIf="basicConfigJson.availableSecondLoanEndorsements">
            <span class="amt-value">
              <span currencyMask *ngIf="calculatedPremiumData.premiumScnd % 1 ===0 ; else showDecimal">{{(calculatedPremiumData.premiumScnd)}}</span>
            </span>
            <ng-template #showDecimal>
              <span currencyMask>{{(calculatedPremiumData.premiumScnd)| number:'1.2-2'}}
            </span>
          </ng-template>
            <span class="amt-label">Premium Amount</span>
          </div>
          <div class="amount-box two" *ngIf="basicConfigJson.availableSecondLoanEndorsements">
            <span class="amt-value">
              <span currencyMask *ngIf="calculatedPremiumData.premiumSEndors % 1 ===0 ; else showDecimal">{{(calculatedPremiumData.premiumSEndors)}}</span>
            </span>
            <ng-template #showDecimal>
              <span currencyMask>{{(calculatedPremiumData.premiumSEndors)| number:'1.2-2'}}
            </span>
          </ng-template>
            <span class="btn-align" title="View Endorsment Premium Details" data-toggle="tooltip" data-placement="top">
              <button type="button" class="btn btn-primary btn-endor-pre" data-toggle="modal"
                data-target="#Endorsementspremium" (click)="onShowCalculatedEndorsementClick($event)"></button>
            </span>
            <span class="amt-label">Endorsement Amount</span>
          </div>
          <div class="amount-box three">
            <span class="amt-value">
              <span
                currencyMask *ngIf="(calculatedPremiumData.premiumScnd+calculatedPremiumData.premiumSEndors) % 1 ===0 ; else showDecimal">{{(calculatedPremiumData.premiumScnd+calculatedPremiumData.premiumSEndors)}}</span>
            </span>
            <ng-template #showDecimal>
              <span currencyMask>{{(calculatedPremiumData.premiumScnd+calculatedPremiumData.premiumSEndors)| number:'1.2-2'}}
            </span>
          </ng-template>
            <span class="amt-label">Total Second Loan Amount</span>
          </div>
        </div>
      </div>
    </main>
  </section>
</div>