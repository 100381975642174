
import { DefaultUrlSerializer, UrlTree } from "@angular/router";
import * as states from "../Constants/States";
import { Injectable } from "@angular/core";
@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        // Optional Step: Do some stuff with the url if needed.

        // If you lower it in the optional step
        // you don't need to use "toLowerCase"
        // when you pass it down to the next function
        if (url && url[1]) {
            url = url.replace(/ /g, "");
            url = states.stateArray[url.substr(1, url.length).toLowerCase()];
        }
        return super.parse(url);
    }
}
