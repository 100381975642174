export class RateTypeModel {
    ownerRateTypeCode: string;
    loanRateTypeCode: string;
    scndLoanRateTypecode: string;
    description: string;
    simo: any;
    priorPolicyAmountFlag: boolean;
    priorPolicyDateFlag: boolean;
    regionList: string;
}
