import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import * as html2pdf from "html2pdf.js";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subscription } from "rxjs";
import { API } from "../../Constants/API";
import { BasicConfigStatewise } from "../../Constants/BasicConfigStatewise";
import { Constants } from "../../Constants/Constants";
import { BasicConfigModel } from "../../Models/BasicConfigModel";
import { EndorsementModel } from "../../Models/EndorsementModel";
import { PdfFormModel } from "../../Models/PdfFormModel";
import { ShareCalculatedPremiumModel } from "../../Models/ShareCalculatedPremiumModel";
import { ShareCplDataModel } from "../../Models/ShareCplDataModel";
import { SharePremiumDataModel } from "../../Models/SharePremiumDataModel";
import { ApiService } from "../../services/apiService/api.service";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";


@Component({
    selector: "app-premium-rate-quotes",
    templateUrl: "./premium-rate-quotes.component.html",
    styleUrls: ["./premium-rate-quotes.component.css"]
})
export class PremiumRateQuotesComponent implements OnInit, OnDestroy {

    pdfFormModel: PdfFormModel = new PdfFormModel();

    hideAppSecondLoan = true;
    margins = {
        top: 70,
        bottom: 40,
        left: 30,
        width: 500
    };
    onwerPremiumAllData: SharePremiumDataModel = new SharePremiumDataModel();
    loanPremiumAllData: SharePremiumDataModel = new SharePremiumDataModel();
    secondLoanPremiumAllData: SharePremiumDataModel = new SharePremiumDataModel();
    cplAllData: ShareCplDataModel = new ShareCplDataModel();
    calculatedPremiumData: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    @Input()
    basicConfigJson: BasicConfigModel;

    ownerTotal = 0;
    loanTotal = 0;
    secondLoanTotal = 0;
    cplTotal = 0;
    grandTotal = 0;
    ownerEndorsements: Array<EndorsementModel> = new Array<EndorsementModel>();
    loanEndorsements: Array<EndorsementModel> = new Array<EndorsementModel>();
    secondLoanEndorsements: Array<EndorsementModel> = new Array<EndorsementModel>();

    rateTypeDataSubscription: Subscription = null;
    ownerSubscription: Subscription = null;
    loanSubscription: Subscription = null;
    secondLoanSubscription: Subscription = null;
    cplSubscription: Subscription = null;
    onApiSuccessCalculatedPremiumSubscription: Subscription = null;
    onGeneratePdfClickSubscription: Subscription = null;

    constructor(public commonFunctions: CommonFunctions,
        public api: API,
        public dataservice: DataServiceService,
        private basicConfig: BasicConfigStatewise,
        public apiService: ApiService,
        @Inject(LOCAL_STORAGE) public localStorage: WebStorageService,
        public constants: Constants) { }

    ngOnInit() {

        registerAndGetAllPremiumData(this);
    }

    onDownloadPdfClick() {
        const data = document.getElementById("quote");
        const opt = {
            margin: 0.2,
            filename: "myfile.pdf",
            image: { type: "jpeg", quality: .98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
        };
        const worker = html2pdf().from(data).set(opt).save();

    }

    ngOnDestroy(): void {
        if (this.rateTypeDataSubscription && !this.rateTypeDataSubscription.closed) {
            this.rateTypeDataSubscription.unsubscribe();
        }
        if (this.ownerSubscription && !this.ownerSubscription.closed) {
            this.ownerSubscription.unsubscribe();
        }
        if (this.loanSubscription && !this.loanSubscription.closed) {
            this.loanSubscription.unsubscribe();
        }
        if (this.secondLoanSubscription && !this.secondLoanSubscription.closed) {
            this.secondLoanSubscription.unsubscribe();
        }
        if (this.cplSubscription && !this.cplSubscription.closed) {
            this.cplSubscription.unsubscribe();
        }
        if (this.onApiSuccessCalculatedPremiumSubscription && !this.onApiSuccessCalculatedPremiumSubscription.closed) {
            this.onApiSuccessCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.onGeneratePdfClickSubscription && !this.onGeneratePdfClickSubscription.closed) {
            this.onGeneratePdfClickSubscription.unsubscribe();
        }
    }

}

function getInputFromLocalStorage(context: PremiumRateQuotesComponent) {
    const inputFormData = context.localStorage.get(context.constants.pdfGenerationPreFormData);
    if (inputFormData) {
        context.pdfFormModel = JSON.parse(inputFormData);
    }
}

function registerAndGetAllPremiumData(context: PremiumRateQuotesComponent) {
    context.ownerSubscription = context.dataservice.onOwnerDataChangedObservable.subscribe(onwerData => {
        context.onwerPremiumAllData = onwerData;

    });

    context.loanSubscription = context.dataservice.onLoanDataChangedObservable.subscribe(loanData => {
        context.loanPremiumAllData = loanData;
        context.hideAppSecondLoan = !context.loanPremiumAllData.secondLoanAdded;
    });

    context.secondLoanSubscription = context.dataservice.onSecondLoanDataChangedObservable.subscribe(secondloanData => {
        context.secondLoanPremiumAllData = secondloanData;
    });

    context.cplSubscription = context.dataservice.onCplDataChangedObservable.subscribe(cplData => {
        context.cplAllData = cplData;
    });

    context.onApiSuccessCalculatedPremiumSubscription = context.dataservice.onApiSuccessCalculatedPremiumObservable.subscribe(
        calculatedPremium => {
            context.calculatedPremiumData = calculatedPremium;
            getAllEndorsementDetail(context);
            calculateTotal(context);

        });

    context.onGeneratePdfClickSubscription = context.dataservice.sharePdfPreFormDataObservable.subscribe(data => {
        getInputFromLocalStorage(context);
        calculateTotal(context);
    });
}
function getAllEndorsementDetail(context: PremiumRateQuotesComponent) {
    getOwnerEndorsementDetail(context);
    getLoanEndorsementDetail(context);
    getSecondLoanEndorsementDetail(context);
}

function calculateTotal(context: PremiumRateQuotesComponent) {
    context.ownerTotal = context.calculatedPremiumData.premiumOwner + context.calculatedPremiumData.premiumOEndors;
    context.loanTotal = context.calculatedPremiumData.premiumLoan + context.calculatedPremiumData.premiumLEndors;
    context.secondLoanTotal = context.calculatedPremiumData.premiumScnd + context.calculatedPremiumData.premiumSEndors;
    context.cplTotal = context.calculatedPremiumData.premiumLenderCPL + context.calculatedPremiumData.premiumBuyerCPL +
        context.calculatedPremiumData.premiumSellerCPL + context.calculatedPremiumData.premiumCPL;

    context.grandTotal = context.ownerTotal + context.loanTotal + context.secondLoanTotal + context.cplTotal;
}

function getOwnerEndorsementDetail(context: PremiumRateQuotesComponent) {
    if (context.onwerPremiumAllData) {
        context.ownerEndorsements = context.commonFunctions.getEndorsementDetail(context.onwerPremiumAllData.selectedEndorsementsArray,
            context.calculatedPremiumData.premiumOEndorsDetail);
    }
}

function getLoanEndorsementDetail(context: PremiumRateQuotesComponent) {
    if (context.loanPremiumAllData) {
        context.loanEndorsements = context.commonFunctions.getEndorsementDetail(context.loanPremiumAllData.selectedEndorsementsArray,
            context.calculatedPremiumData.premiumLEndorsDetail);
    }
}

function getSecondLoanEndorsementDetail(context: PremiumRateQuotesComponent) {
    if (context.secondLoanPremiumAllData) {
        context.secondLoanEndorsements = context.commonFunctions.getEndorsementDetail(
            context.secondLoanPremiumAllData.selectedEndorsementsArray, context.calculatedPremiumData.premiumSEndorsDetail);
    }
}
