<div class="modal fade" id="EmailModal" tabindex="-1" role="dialog" aria-labelledby="EmailModalLabel"
  aria-hidden="true">
  <div class="modal-dialog EmailModal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="EmailModalLabel">Premium Rate Quote</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closePdfFormModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body form1">


        <form name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
          <div class="form-group">
            <label for="exampleInputEmail1">File Number:</label>
            <input type="text" placeholder="File Number" name="file number" class="form-control"
              id="formGroupExampleInput" [(ngModel)]="pdfFormModel.fileNumber" [ngbTypeahead]="search"
              (ngModelChange)="onFileNumberChange($event)" #instance="ngbTypeahead"
              [ngModelOptions]="{standalone: true}">
          </div>


          <div class="form-group">
            <label for="exampleInputEmail1">Seller:</label>
            <input type="text" placeholder="Seller" name="seller" class="form-control" id="formGroupExampleInput"
              [(ngModel)]="pdfFormModel.seller">
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Buyer:</label>
            <input type="text" placeholder="Buyer" name="buyer" class="form-control" id="formGroupExampleInput"
              [(ngModel)]="pdfFormModel.buyer">
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Reference:</label>
            <input type="text" placeholder="Reference" name="reference" class="form-control" id="formGroupExampleInput"
              [(ngModel)]="pdfFormModel.reference">
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Property:</label>
            <input type="text" placeholder="Property" name="property" class="form-control" id="formGroupExampleInput"
              [(ngModel)]="pdfFormModel.property">
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">State:</label>
            <input type="text" placeholder="State" name="state" class="form-control" id="exampleFormControlSelect1"
              [(ngModel)]="pdfFormModel.selectedState">
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">County:</label>
            <input type="text" placeholder="County" name="county" class="form-control" id="formGroupExampleInput"
              [(ngModel)]="pdfFormModel.county">
          </div>
          <div class="modal-footer">
            <div class="col-12 text-center">
              <button type="button" class="btn btn-success" (click)="onDownloadPdfClick()">Download PDF</button>
              <div class="form-group top-margin-one-rem">
                <input type="text" placeholder="Email Address" #email="ngModel" name="email" class="form-control"
                  id="formGroupExampleInput" [(ngModel)]="pdfFormModel.email" required email>
              </div>
              <button type="button" #closeModal class="btn calculate-btn" (click)="onEmailPdfClick()"
                [disabled]="email.errors?.required || email.errors?.email">E-Mail
                PDF</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
