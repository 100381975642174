import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { CommonFunctions } from "../../Utils/CommonFunctions";

@Component({
    selector: "app-state-model",
    templateUrl: "./state-model.component.html",
    styleUrls: ["./state-model.component.css"]
})
export class StateModelComponent implements OnInit {
    @Output()
    onstateSelected: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("closeAStateModal")
    closeAStateModal: ElementRef;
    // states = ["Alabama", "Georgia", "Florida", "Missouri", "Mississippi", "Southcarolina", "Minnesota"];
    // states = ["Alabama", "Georgia", "Florida", "Missouri", "South Carolina", "Mississippi", "Colorado", "Minnesota","Kansas"];
    // states = ["Alabama", "Colorado", "Florida", "Georgia", "Kansas", "Minnesota", "Mississippi", "Missouri","Nevada", "New Mexico", "South Carolina", "Texas", "Wisconsin", "Utah"];
    states = ["Alabama", "Colorado", "Florida", "Georgia", "Kansas", "Minnesota", "Mississippi", "Missouri", "Nevada", "South Carolina", "Texas", "Wisconsin", "Utah"];
    radioChange = radioChange;
    applyClick = applyClick;
    selectedState = "";
    stateSearchText;

    constructor(private commonFunctions: CommonFunctions) { }

    ngOnInit() {


        initViews();
    }
}

function initViews() {

}
function radioChange($event) {
    this.commonFunctions.printLog("selected state " + $event, true);
    this.selectedState = $event;
}

function applyClick() {
    if (this.selectedState) {
        this.closeAStateModal.nativeElement.click();
        this.onstateSelected.emit(this.selectedState);
    } else {
        alert("please select state first");
    }
}
