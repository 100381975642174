<div class="container">
  <section>
    <h2>Owner
      <a href="#collapseOwner" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOwner"
        [ngClass]="{'':showComponent,'show':!showComponent}"
        (click)="onShowHideClick($event)">{{showComponent?'Collapse':'Expand'}}</a>
    </h2>
    <main id="collapseOwner" class="collapse show">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Coverage Amount</label>
            <input currencyMask type="text" class="form-control" placeholder="0"
              (ngModelChange)="onCoverageAmountChange($event)" [(ngModel)]="coverageAmount" [options]="{ precision: 0 }"
              maxlength="11" [disabled]="disableCoverageAmount">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Rate Type</label>
            <select class="form-control" (change)="onOwnerRateTypeDropDownChangedChanged($event.target.value)"
              [(ngModel)]="selectedRateTypeIndex" [disabled]="disableRateType">
              <option *ngFor="let item of ownerRateTypes;let i = index" [value]="i">{{item.description}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group" *ngIf="basicConfigJson.availableOwnerReissue&&!hideOwnerPriorPolicyInput">
            <label>Prior Policy Amount</label>
            <input currencyMask type="text" class="form-control" placeholder="0" [(ngModel)]="priorPolicyAmount"
              (ngModelChange)="onPriorPolicyAmountChange($event)" [options]="{ precision: 0 }" maxlength="11">
          </div>
        </div>
        <!-- changes by me start here -->
        <div class="col-md-4" *ngIf="selectedRateTypeModel.priorPolicyDateFlag">
          <div class="form-group">
            <label>Effective Date</label>
            <input class="form-control calendar-text-box" placeholder="mm-dd-yyyy" name="dp" [(ngModel)]="effectiveDate"
              ngbDatepicker #d="ngbDatepicker" [maxDate]="maxEffectiveDate" [minDate]="minEffectiveDate"
              (click)="d.toggle()" (ngModelChange)="onEffectiveDateChanged($event)" readonly>
          </div>
        </div>
        <!-- end here -->
      </div>
      <div class="divider"></div>
      <div class="row">
        <div class="col-sm-12">
          <div class="endorsement-box" *ngIf="basicConfigJson.availableOwnerEndorsements">
            <label>Endorsements:</label>
            <div class="clearfix"></div>
            <input type="text" class="form-control large" placeholder="" [(ngModel)]="selectedEndorsementsIdsArray"
              (ngModelChange)="onEndorsementAmountChange($event)" readonly [disabled]="disableEndorsementBtn">
            <button type="button" class="btn btn-primary btn-lg btn-endorse" data-toggle="modal"
              data-target="#EndorsementsModal" (click)="onOwnerOpenEndorsementClick($event)"
              [disabled]="disableEndorsementBtn">Select Endorsements</button>
          </div>
        </div>
      </div>
      <div class="calcualation-box">
        <div class="row">
          <div class="amount-box one" *ngIf="basicConfigJson.availableOwnerEndorsements">
            <span class="amt-value">
              <span currencyMask *ngIf="calculatedPremiumData.premiumOwner % 1 ===0 ; else showDecimal">{{(calculatedPremiumData.premiumOwner)}}</span>
            </span>
            <ng-template #showDecimal>
              <span currencyMask>{{(calculatedPremiumData.premiumOwner)| number:'1.2-2'}}
            </span>
            </ng-template>
            
            <span class="amt-label">Premium Amount</span>
          </div>
          <div class="amount-box two" *ngIf="basicConfigJson.availableOwnerEndorsements">

            <span class="amt-value">
              <span currencyMask *ngIf="calculatedPremiumData.premiumOEndors % 1 === 0; else showDecimal" >{{(calculatedPremiumData.premiumOEndors)}}</span>
            </span>
            <ng-template #showDecimal>
              <span currencyMask>{{(calculatedPremiumData.premiumOEndors)| number:'1.2-2'}}
            </span>
            </ng-template>
            <span class="btn-align" title="View Endorsment Premium Details" data-toggle="tooltip" data-placement="top">
              <button type="button" class="btn btn-primary btn-endor-pre" data-toggle="modal"
                data-target="#Endorsementspremium" (click)="onShowCalculatedEndorsementClick($event)"
                [disabled]="disableEndorsementBtn"></button>
            </span>

            <span class="amt-label">Endorsement Amount</span>
          </div>
          <div class="amount-box three">
            <span class="amt-value">
              <span
                currencyMask *ngIf = "(calculatedPremiumData.premiumOwner+calculatedPremiumData.premiumOEndors) % 1===0 ; else showDecimal">{{(calculatedPremiumData.premiumOwner+calculatedPremiumData.premiumOEndors)}}</span>
            </span>
            <ng-template #showDecimal>
              <span currencyMask>{{(calculatedPremiumData.premiumOwner+calculatedPremiumData.premiumOEndors)| number:'1.2-2'}}
            </span>
            </ng-template>
            <span class="amt-label">Total Owner Amount</span>
          </div>
        </div>
      </div>
    </main>
  </section>
</div>