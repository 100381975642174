<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="EmailModalLabel" aria-hidden="true">
    <div class="modal-dialog EmailModal" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="EmailModalLabel">Email PDF</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
              <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-success">Send Mail</button>
          </div>
        </div>
      </div>
    </div>
  </div>