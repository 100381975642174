import { Component, OnInit, OnDestroy } from "@angular/core";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";
import { EndorsementModel } from "../../Models/EndorsementModel";
import { Subscription } from "rxjs";

@Component({
    selector: "app-calculated-endorsements-detail",
    templateUrl: "./calculated-endorsements-detail.component.html",
    styleUrls: ["./calculated-endorsements-detail.component.css"]
})
export class CalculatedEndorsementsDetailComponent implements OnInit, OnDestroy {


    callFrom = "";
    endorsements: Array<EndorsementModel> = [];
    calculatedEndorsementDetailSubscription: Subscription;
    constructor(private dataService: DataServiceService,
        private commonFunctions: CommonFunctions) { }

    ngOnInit() {
        this.calculatedEndorsementDetailSubscription = this.dataService.calculatedEndorsementDetailObservable.subscribe(endormentData => {
            this.callFrom = endormentData.callFrom;
            this.endorsements = endormentData.endorsements;
        });

    }
    ngOnDestroy(): void {
        if (this.calculatedEndorsementDetailSubscription && !this.calculatedEndorsementDetailSubscription.closed) {
            this.calculatedEndorsementDetailSubscription.unsubscribe();
        }
    }
    getClass(index): any {

        if (index % 2 === 0) {
            return "dark-row";
        } else {
            return "light-row";
        }
    }
}
