import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-browser-incompatibility",
    templateUrl: "./browser-incompatibility.component.html",
    styleUrls: ["./browser-incompatibility.component.css"]
})
export class BrowserIncompatibilityComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
