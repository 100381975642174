import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CallWebApi } from 'src/app/ApiCallInterface/CallWebApi';
import { BasicConfigStatewise } from 'src/app/Constants/BasicConfigStatewise';
import { Constants } from 'src/app/Constants/Constants';
import { BasicConfigModel } from 'src/app/Models/BasicConfigModel';
import { SharePremiumDataModel } from 'src/app/Models/SharePremiumDataModel';
import { CommonFunctions } from 'src/app/Utils/CommonFunctions';
import { DataServiceService } from 'src/app/services/dataService/data-service.service';
import * as states from "../../Constants/States";
import { DatePipe } from '@angular/common';
import { ShareCalculatedPremiumModel } from 'src/app/Models/ShareCalculatedPremiumModel';
import { ShareCplDataModel } from 'src/app/Models/ShareCplDataModel';

@Component({
  selector: 'app-newmexico',
  templateUrl: './newmexico.component.html',
  styleUrls: ['./newmexico.component.css']
})
export class NewmexicoComponent implements OnInit {
  hideAppOwner = true;
  hideAppLoan = true;  
  hideAppSecondLoan = true;
  rateTypes: any;
  selectedPropertyType = "";
  ownerTotalAmount = 0;

  basicConfigJson: BasicConfigModel;
  ownerPremiumAllData: SharePremiumDataModel;
  loanPremiumAllData: SharePremiumDataModel;
  secondLoanPremiumAllData: SharePremiumDataModel = new SharePremiumDataModel();  
  cplAllData: ShareCplDataModel = new ShareCplDataModel();

  rateTypeDataSubscription: Subscription = null;
  ownerSubscription: Subscription = null;
  loanSubscription: Subscription = null;
  secondLoanSubscription: Subscription = null;
  cplSubscription: Subscription = null;

  constructor(private basicConfig: BasicConfigStatewise,
              public commonFunctions: CommonFunctions,
              public dataservice: DataServiceService,
              public constants: Constants,
              public callWebApi: CallWebApi,
              public datePipe: DatePipe) {
  
  }

  ngOnInit(): void {
    this.basicConfigJson = this.basicConfig.getNewMexicoBasicConfig();
    this.rateTypeDataSubscription = this.dataservice.rateTypeDataObservable.subscribe(rateTypes => {
      try{
        this.rateTypes = JSON.parse(rateTypes);
        this.selectedPropertyType = this.rateTypes.propertyTypecode;
        this.hidePremiumType();
        registerAndGetAllPremiumData(this);
      }
      catch(error){

      }
    })
  }

  onCalculateClick($event){
    if (validateData(this)){
        this.callWebApi.callPremiumCalculateApi(createParamsForCalculatePremium(this), states.NEWMEXICO_ID, this);
    }
  }

  onSuccess(response: any){
    this.dataservice.onHideShowLoader(false);
    this.dataservice.onCalculatedDataApiSuccess(response);
  }

  onError(errorCode: number, errorMsg: string){
    const shareModal: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    this.dataservice.onCalculatedDataApiSuccess(shareModal);
    this.commonFunctions.showAlertDialog(this.constants.alertErrorTitle, errorMsg);
  }

  hidePremiumType() {
    if (this.rateTypes.hasOwnProperty("ownerRateType")) {
        this.hideAppOwner = false;
    } else {
        this.hideAppOwner = true;
    }
    if (this.rateTypes.hasOwnProperty("loanRateType")) {
        this.hideAppLoan = false;
    } else {
        this.hideAppLoan = true;
    }
    if (this.rateTypes.hasOwnProperty("scndLoanRateType")) {
        this.hideAppSecondLoan = true;
    } else {
        this.hideAppSecondLoan = true;
    }
  }

  onOwnerTotalAmountChanged(totalAmount: number) {
    this.ownerTotalAmount = totalAmount;
    this.commonFunctions.printLog("total amount " + this.ownerTotalAmount, true);
  }

  ngOnDestroy(): void {
    if (this.rateTypeDataSubscription && !this.rateTypeDataSubscription.closed) {
        this.rateTypeDataSubscription.unsubscribe();
    }
    if (this.ownerSubscription && !this.ownerSubscription.closed) {
        this.ownerSubscription.unsubscribe();
    }
    if (this.loanSubscription && !this.loanSubscription.closed) {
        this.loanSubscription.unsubscribe();
    }
    if (this.secondLoanSubscription && !this.secondLoanSubscription.closed) {
        this.secondLoanSubscription.unsubscribe();
    }
    if (this.cplSubscription && !this.cplSubscription.closed) {
        this.cplSubscription.unsubscribe();
    }
    this.commonFunctions.printLog("New Mexico destroyed", true);
  }
}


function registerAndGetAllPremiumData(context: NewmexicoComponent) {
  context.ownerSubscription = context.dataservice.onOwnerDataChangedObservable.subscribe(ownerData => {
      if (ownerData) {
          context.ownerPremiumAllData = ownerData;
      }
  });

  context.loanSubscription = context.dataservice.onLoanDataChangedObservable.subscribe(loanData => {
      if (loanData) {
          context.loanPremiumAllData = loanData;
          context.hideAppSecondLoan = !context.loanPremiumAllData.secondLoanAdded;
          context.commonFunctions.printLog("added second loan " + context.loanPremiumAllData.secondLoanAdded, true);
      }
  });

  context.secondLoanSubscription = context.dataservice.onSecondLoanDataChangedObservable.subscribe(secondloanData => {
      if (secondloanData) {
          context.secondLoanPremiumAllData = secondloanData;
      }
  });

  context.cplSubscription = context.dataservice.onCplDataChangedObservable.subscribe(cplData => {
      if (cplData) {
          context.cplAllData = cplData;
      }
  });
}


function validateData (context: NewmexicoComponent){
    //Owner Page Validation
    if (context.ownerPremiumAllData.selectedRateType !== context.constants.na) {
        if (context.ownerPremiumAllData.selectedEndorsementsArray.length > 0 && context.ownerPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorOwnerCoverageAmount);
            return false;
        }
        if (context.ownerPremiumAllData.selectedRateTypeCode !== context.constants.none &&
            context.ownerPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorOwnerCoverageAmount);
            return false;
        }
        if (context.ownerPremiumAllData.coverageAmount > 0 && context.ownerPremiumAllData.selectedRateTypeCode === context.constants.none) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorOwnerRateType);
            return false;
        }
        if (context.ownerPremiumAllData.enablePriorPolicyAmount &&
            (!context.ownerPremiumAllData.priorPolicyAmount || context.ownerPremiumAllData.priorPolicyAmount <= 0)) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorPriorPolicyAmount);
            return false;
        }
        if (context.ownerPremiumAllData.effectiveDateAvailable && !context.ownerPremiumAllData.effectiveDate) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorOwnerEffectiveDate);
            return false;
        }
    }

    // Loan page validation
    if (context.loanPremiumAllData.selectedRateTypeCode !== context.constants.na) {
        if (context.loanPremiumAllData.selectedEndorsementsArray.length > 0 && context.loanPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorLoanCoverageAmount);
            return false;
        }

        if (context.loanPremiumAllData.selectedRateTypeCode !== context.constants.none && context.loanPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorLoanCoverageAmount);
            return false;
        }
        if (context.loanPremiumAllData.coverageAmount > 0 && context.loanPremiumAllData.selectedRateTypeCode === context.constants.none) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorLoanRateType);
            return false;
        }

        if (context.loanPremiumAllData.effectiveDateAvailable && !context.loanPremiumAllData.effectiveDate) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorLoanEffectiveDate);
            return false;
        }
    }    

    /* Simo validation */
    if (context.loanPremiumAllData.simultaneous === context.constants.yes) {
        if (!checkTwoCoverageAmountEntered(context)) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.twoAmountMustSelected);
            return false;
        }
    }
    if (context.ownerPremiumAllData.coverageAmount > 0 && context.loanPremiumAllData.coverageAmount > 0) {
        if (context.loanPremiumAllData.simultaneous === context.constants.no) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.simultanoeusMustForTwo);
            return false;
        }
    }

    // Second loan page validation
    if (!context.hideAppSecondLoan && context.secondLoanPremiumAllData.selectedRateTypeCode !== context.constants.na) {        
        if (context.secondLoanPremiumAllData.selectedEndorsementsArray.length > 0 && context.secondLoanPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle,
                context.constants.errorSecondLoanCoverageAmount);
            return false;
        }

        if (context.secondLoanPremiumAllData.selectedRateTypeCode !== context.constants.none &&
            context.secondLoanPremiumAllData.coverageAmount <= 0) {
            context.commonFunctions.showAlertDialog(
                context.constants.alertErrorTitle, context.constants.errorSecondLoanCoverageAmount);
            return false;
        }
        if (context.secondLoanPremiumAllData.coverageAmount > 0 &&
            context.secondLoanPremiumAllData.selectedRateTypeCode === context.constants.none) {
            context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorSecondLoanRateType);
            return false;
        }
        if (context.secondLoanPremiumAllData.enablePriorPolicyAmount &&
            (!context.secondLoanPremiumAllData.priorPolicyAmount || context.secondLoanPremiumAllData.priorPolicyAmount <= 0)) {
            context.commonFunctions.showAlertDialog(
                context.constants.alertErrorTitle, context.constants.errorPriorPolicyAmount);
            return false;
        }
    }    

    if (context.ownerPremiumAllData.selectedRateTypeCode !== context.constants.na &&
        context.loanPremiumAllData.selectedRateTypeCode !== context.constants.na &&
        !context.ownerPremiumAllData.coverageAmount && !context.loanPremiumAllData.coverageAmount) {
        context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, context.constants.errorNoDataEntered);
        return false;
    }    

    return true;
}

function createParamsForCalculatePremium(context: NewmexicoComponent){
    if (context.ownerPremiumAllData.coverageAmount == null){
        context.ownerPremiumAllData.coverageAmount = 0;
    }
    if (context.loanPremiumAllData.coverageAmount == null){
        context.loanPremiumAllData.coverageAmount = 0;
    }
    if (context.secondLoanPremiumAllData.coverageAmount == null){
        context.secondLoanPremiumAllData.coverageAmount = 0;
    }        

    let paramsString = "";

    /* Selected Property Type & Simultaneous */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.propertyType, context.selectedPropertyType, false);
    // not required paramsString = context.commonFunctions.createParams(paramsString, context.constants.region, context.constants.regionValue, false);
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.simultaneous, context.loanPremiumAllData.simultaneous, false);

    /* Owner */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.ownerRateType,
        context.ownerPremiumAllData.selectedRateTypeCode, false); /* ratetype */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.ownerRateTypeCode,
        context.ownerPremiumAllData.selectedRateType, false); /* ratetypecode */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.ownerCoverageAmount,
        context.ownerPremiumAllData.coverageAmount, false); /* coverageamount */    
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.ownerReissueAmount,
        context.ownerPremiumAllData.priorPolicyAmount, false); /* reissuecoverageamount */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.priorEffectiveDate,
        context.ownerPremiumAllData.effectiveDate, false); /* prioreffectivedate */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.ownerEndorsements,
        context.commonFunctions.convertEndorsementsToUrlParams(context.ownerPremiumAllData.selectedEndorsementsArray), false); /* ownerendors */        

    /* Loan */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.loanRateType,
        context.loanPremiumAllData.selectedRateTypeCode, false); /* loanratetype */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.loanRateTypeCode,
        context.loanPremiumAllData.selectedRateType, false); /* loanratetypecode */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.loanCoverageAmount,
        context.loanPremiumAllData.coverageAmount, false); /* loancoverageamount */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.loanReissueAmount,
        context.loanPremiumAllData.priorPolicyAmount, false); /* loanreissuecoverageamount */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.loanPriorEffectiveDate,
        context.loanPremiumAllData.effectiveDate, false); /* loanprioreffectivedate */    
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.loanEndorsements,
        context.commonFunctions.convertEndorsementsToUrlParamsNV(context.loanPremiumAllData.selectedEndorsementsArray), false); /* loanendors */

    /* Second Loan */
    if (context.loanPremiumAllData.secondLoanAdded) {
        paramsString = context.commonFunctions.createParams(paramsString, context.constants.secondLoanRateType,
            context.secondLoanPremiumAllData.selectedRateTypeCode, false);
        paramsString = context.commonFunctions.createParams(paramsString, context.constants.secondLoanRateTypeCode,
            context.secondLoanPremiumAllData.selectedRateType, false);
        paramsString = context.commonFunctions.createParams(paramsString, context.constants.secondLoanCoverageAmount,
            context.secondLoanPremiumAllData.coverageAmount, false);
        paramsString = context.commonFunctions.createParams(paramsString, context.constants.secondLoanEndorsements,
            context.commonFunctions.convertEndorsementsToUrlParamsNV(context.secondLoanPremiumAllData.selectedEndorsementsArray), false);
    }

    /* CPL */
    paramsString = context.commonFunctions.createParams(paramsString, context.constants.lenderCpl, context.cplAllData.lenderCpl, true); /* commitment values passing in lenderCpl */

    console.log(paramsString);
    return paramsString;
}

function checkTwoCoverageAmountEntered(context: NewmexicoComponent) {    
    let coverageEnteredCount = 0;
    if (context.ownerPremiumAllData.coverageAmount > 0) {
        coverageEnteredCount++;
    }
    if (context.loanPremiumAllData.coverageAmount > 0) {
        coverageEnteredCount++;
    }
    if (context.secondLoanPremiumAllData.coverageAmount > 0) {
        coverageEnteredCount++;
    }

    if (coverageEnteredCount >= 2) {
        return true;
    } else {
        return false;
    }
}