
<div class="container">
    <section>
        <h2 class="h2Color">Commitment
            <a href="#collapseCommitment" data-toggle="collapse" area-expanded="true" aria-controls="collapseCommitment" [ngClass]="{'':showComponent,'show':!showComponent}" (click)="onShowHideClick($event)">{{showComponent?'Collapse':'Expand'}}</a>
        </h2>
        <main id="collapseCommitment" class="collapse show">
            <div class="row justify-content-md-center">
                <div class="col-md-3 col-lg-2">
                    <div class="form-group" >              
                    <input currencyMask type="text" class="form-control" placeholder="0" maxlength="1" [(ngModel)]="shareCplDataModel.lenderCpl" (ngModelChange)="onCplAmountChange($event)" [options]="{ precision: 0 }">
                    </div>
                </div>
            </div>
            <div class="calcualation-box">
                <div class="divider"></div>
                <div class="row justify-content-md-center">
                    <div class="amount-box">
                        <span class="amt-value">
                            <span currencyMask>{{totalCplAmount}}</span>
                        </span>
                        <span class="amt-label">Total Commitment Amount</span>
                    </div>
                </div>
            </div>            
        </main>
    </section>
</div>  
