/* eslint-disable @typescript-eslint/indent */
import { Inject, Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Constants } from "../Constants/Constants";
import { AlertDialogComponent } from "../customDialog/alert-dialog/alert-dialog.component";
import { EndorsementModel } from "../Models/EndorsementModel";
import { RateTypeModel } from "../Models/RateTypeModel";
import { DecimalPipe } from "@angular/common";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
const UNDERSCORE = "_";
@Injectable({
    providedIn: "root"
})

export class CommonFunctions {

    noneRateTypeModel: RateTypeModel = null;
    constructor(@Inject(LOCAL_STORAGE) private localStorage: WebStorageService,
        private constants: Constants,
        private decimalPipe: DecimalPipe,
        public alertModalService: NgbModal) { }

    printLog(message: any, show: boolean) {

        if (show) {
            console.log(message);
        }

    }
    xml2json(xmlObject, tab) {
        const X = {
            toObj: function (xml) {
                let o = {};
                if (xml.nodeType === 1) {   // element node ..
                    if (xml.attributes.length) {  // element with attributes  ..

                        for (let i = 0; i < xml.attributes.length; i++) {
                            o[xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue || "").toString();
                        }
                    }
                    if (xml.firstChild) { // element has child nodes ..
                        let textChild = 0, cdataChild = 0, hasElementChild = false;
                        for (let n = xml.firstChild; n; n = n.nextSibling) {
                            if (n.nodeType === 1) {
                                hasElementChild = true;
                            } else if (n.nodeType === 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
                                textChild++;
                            } else if (n.nodeType === 4) {
                                cdataChild++;
                            } // cdata section node
                        }
                        if (hasElementChild) {
                            if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
                                X.removeWhite(xml);
                                for (let n = xml.firstChild; n; n = n.nextSibling) {
                                    if (n.nodeType === 3) {  // text node

                                        o["#text"] = X.escape(n.nodeValue);
                                    } else if (n.nodeType === 4) {  // cdata node

                                        o["#cdata"] = X.escape(n.nodeValue);
                                    } else if (o[n.nodeName]) {  // multiple occurence of element ..
                                        if (o[n.nodeName] instanceof Array) {
                                            o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                                        } else {
                                            o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                                        }
                                    } else {  // first occurence of element..

                                        o[n.nodeName] = X.toObj(n);
                                    }
                                }
                            } else { // mixed content
                                if (!xml.attributes.length) {
                                    o = X.escape(X.innerXml(xml));
                                } else {
                                    o["#text"] = X.escape(X.innerXml(xml));
                                }
                            }
                        } else if (textChild) { // pure text
                            if (!xml.attributes.length) {
                                o = X.escape(X.innerXml(xml));
                            } else {
                                o["#text"] = X.escape(X.innerXml(xml));
                            }
                        } else if (cdataChild) { // cdata
                            if (cdataChild > 1) {
                                o = X.escape(X.innerXml(xml));
                            } else {
                                for (let n = xml.firstChild; n; n = n.nextSibling) {
                                    o["#cdata"] = X.escape(n.nodeValue);
                                }
                            }
                        }
                    }
                    if (!xml.attributes.length && !xml.firstChild) {
                        o = null;
                    }
                } else if (xml.nodeType === 9) { // document.node
                    o = X.toObj(xml.documentElement);
                } else {
                    this.showAlertDialog(this.constants.ALERT_ERROR_TITLE, "unhandled node type: " + xml.nodeType);
                }
                return o;
            },
            toJson: function (o, name, ind) {
                let jsonString = name ? ("\"" + name + "\"") : "";
                if (o instanceof Array) {
                    for (let i = 0, n = o.length; i < n; i++) {
                        o[i] = X.toJson(o[i], "", ind + "\t");
                    }
                    jsonString += (name ? ":[" : "[") + (o.length > 1
                        ? ("\n" + ind + "\t" + o.join(",\n" + ind + "\t") + "\n" + ind) : o.join("")) + "]";
                } else if (o == null) {
                    jsonString += (name && ":") + "null";
                } else if (typeof (o) == "object") {
                    const arr = [];
                    // for (const m in o) {
                    for (let m = 0, n = o.length; m < n; m++) {
                        arr[arr.length] = X.toJson(o[m], m, ind + "\t");
                    }
                    jsonString += (name ? ":{" : "{") + (arr.length > 1 ? ("\n" + ind + "\t" + arr.join(",\n" + ind + "\t")
                        + "\n" + ind) : arr.join("")) + "}";
                } else if (typeof (o) == "string") {
                    jsonString += (name && ":") + "\"" + o.toString() + "\"";
                } else {
                    jsonString += (name && ":") + o.toString();
                }
                return json;
            },
            innerXml: function (node) {
                let s = "";
                if ("innerHTML" in node) {
                    s = node.innerHTML;
                } else {
                    const asXml = function (n) {
                        if (n.nodeType === 1) {
                            s += "<" + n.nodeName;
                            for (let i = 0; i < n.attributes.length; i++) {
                                s += " " + n.attributes[i].nodeName + "=\"" + (n.attributes[i].nodeValue || "").toString() + "\"";
                            }
                            if (n.firstChild) {
                                s += ">";
                                for (let c = n.firstChild; c; c = c.nextSibling) {
                                    s += asXml(c);
                                }
                                s += "</" + n.nodeName + ">";
                            } else {
                                s += "/>";
                            }
                        } else if (n.nodeType === 3) {
                            s += n.nodeValue;
                        } else if (n.nodeType === 4) {
                            s += "<![CDATA[" + n.nodeValue + "]]>";
                        }
                        return s;
                    };
                    for (let c = node.firstChild; c; c = c.nextSibling) {
                        s += asXml(c);
                    }
                }
                return s;
            },
            escape: function (txt) {
                return txt.replace(/[\\]/g, "\\\\")
                    .replace(/[\"]/g, "\\\"")
                    .replace(/[\n]/g, "\\n")
                    .replace(/[\r]/g, "\\r");
            },
            removeWhite: function (e) {
                if (e != null) {
                    e.normalize();
                    for (let n = e.firstChild; n;) {
                        if (n.nodeType === 3) {  // text node
                            if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
                                const nxt = n.nextSibling;
                                e.removeChild(n);
                                n = nxt;
                            } else {
                                n = n.nextSibling;
                            }
                        } else if (n.nodeType === 1) {  // element node
                            X.removeWhite(n);
                            n = n.nextSibling;
                        } else {                   // any other node

                            n = n.nextSibling;
                        }
                    }
                    return e;
                } else {
                    return xmlObject;
                }
            }
        };
        if (xmlObject.nodeType === 9) { // document node

            xmlObject = xmlObject.documentElement;
        }
        const json = X.toJson(X.toObj(X.removeWhite(xmlObject)), xmlObject.nodeName, "\t");
        return "{\n" + tab + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}";
    }
    json2xml(o, tab) {
        // eslint-disable-next-line prefer-const
        let toXml = function (v, name, ind) {
            if (v instanceof Array) {
                for (let i = 0, n = v.length; i < n; i++) {
                    xml += ind + toXml(v[i], name, ind + "\t") + "\n";
                }
            } else if (typeof (v) == "object") {
                let hasChild = false;
                xml += ind + "<" + name;
                for (const m in v) {
                    if (m.charAt(0) === "@") {
                        xml += " " + m.substr(1) + "=\"" + v[m].toString() + "\"";
                    } else {
                        hasChild = true;
                    }
                }
                xml += hasChild ? ">" : "/>";
                if (hasChild) {
                    for (const m in v) {
                        if (m === "#text") {
                            xml += v[m];
                        } else if (m === "#cdata") {
                            xml += "<![CDATA[" + v[m] + "]]>";
                        } else if (m.charAt(0) !== "@") {
                            xml += toXml(v[m], m, ind + "\t");
                        }
                    }
                    xml += (xml.charAt(xml.length - 1) === "\n" ? ind : "") + "</" + name + ">";
                }
            } else {
                xml += ind + "<" + name + ">" + v.toString() + "</" + name + ">";
            }
            return xml;
        }, xml = "";
        // for (const m in o) {
        for (let m = 0, n = o.length; m < n; m++) {
            xml += toXml(o[m], m, "");
        }
        return tab ? xml.replace(/\t/g, tab) : xml.replace(/\t|\n/g, "");
    }

    createParams(appendedString: string, key: string, value: any, lastParam: boolean) {
        if (!lastParam) {
            appendedString += key + "^" + value + ",";
        } else {
            appendedString += key + "^" + value;
        }

        return appendedString;
    }
    convertEndorsementsToUrlParams(endorsements: Array<EndorsementModel>) {
        let endorsementsString = "";
        endorsements.forEach((element, index) => {
            if (index < endorsements.length - 1) {
                endorsementsString = endorsementsString + element.endorsementCode + "-" + element.proposedRate + "|";
            } else {
                endorsementsString = endorsementsString + element.endorsementCode + "-" + element.proposedRate;
            }
        });



        return endorsementsString;
    }

    convertEndorsementsToUrlParamsNV(endorsements: Array<EndorsementModel>) {
        let endorsementsString = "";
        endorsements.forEach((element, index) => {
            if (index < endorsements.length - 1) {
                endorsementsString = endorsementsString + element.endorsementCode + "-" + valueCheck(element.proposedRate) + "|";
            } else {
                endorsementsString = endorsementsString + element.endorsementCode + "-" + valueCheck(element.proposedRate);
            }
        });

        function valueCheck(rate) {
            if(rate === false) {
                return null;
            } else if(rate === true) {
                return null;
            } else if(typeof rate === 'number') {
                return rate;
            }
        }

        return endorsementsString;
    }

    getNoneRateTypeModel() {
        if (!this.noneRateTypeModel) {
            createNoneTypeRateModel(this);
        }

        return this.noneRateTypeModel;
    }

    getEndorsements(endorsementFor: string, propertyTypeList: string, rateTypeList: string, region: string) {
        const selectedState = this.localStorage.get(this.constants.selectedState);
        const completeKey = selectedState + UNDERSCORE + endorsementFor;
        const allEndorsements: Array<EndorsementModel> = (this.localStorage.get(completeKey)) ? JSON.parse(
            this.localStorage.get(completeKey)) : null;
        const endorsements: Array<EndorsementModel> = getEndorsementsStateWise(allEndorsements, propertyTypeList, rateTypeList, region);
        return endorsements;
    }
    setEndorsements(endorsementFor: string, endorsements: string) {
        const selectedState = this.localStorage.get(this.constants.selectedState);
        const completeKey = selectedState + UNDERSCORE + endorsementFor;
        this.localStorage.set(completeKey, endorsements);
    }

    setSelectedEndorsementIds(endorsementFor: string, endorsementIds: string, propertyType: string) {
        const selectedState = this.localStorage.get(this.constants.selectedState);
        const completeKey = selectedState + UNDERSCORE + endorsementFor + UNDERSCORE + propertyType + "_ids";
        this.localStorage.set(completeKey, endorsementIds);
    }


    getSelectedEndorsementsIds(endorsementFor: string, propertyType: string) {
        let selectedIds: Array<string> = [];
        const selectedState = this.localStorage.get(this.constants.selectedState);
        const completeKey = selectedState + UNDERSCORE + endorsementFor + UNDERSCORE + propertyType + "_ids";
        const ids: string = this.localStorage.get(completeKey);
        if (ids) {
            selectedIds = ids.split(" , ");
        }
        return selectedIds;
    }

    checkEndorsements(endorsementFor: string) {
        const selectedState = this.localStorage.get(this.constants.selectedState);
        const completeKey = selectedState + UNDERSCORE + endorsementFor;
        if (this.localStorage.get(completeKey)) {
            return true;
        } else {
            return false;
        }
    }
    getEndorsementDetail(selectedEndorsements: Array<EndorsementModel>, calculatedEndorsementsDetail: string) {
        const calculatedEndorsements: Array<EndorsementModel> = [];
        if (calculatedEndorsementsDetail) {
            const splittedEndorsements = calculatedEndorsementsDetail.split("|");
            splittedEndorsements.forEach(element => {
                const singleEndorsementDetail = element.split("-");
                const endorsementCode = singleEndorsementDetail[0];
                const endorsementCalcualtedPremium = singleEndorsementDetail[1];
                selectedEndorsements.forEach(selectedelement => {
                    if (selectedelement.endorsementCode === endorsementCode) {
                        selectedelement.proposedRate = Number(endorsementCalcualtedPremium);
                        calculatedEndorsements.push(selectedelement);
                    }
                });
            });
        }

        return calculatedEndorsements;
    }

    getRateTypes(rateTypes: Array<RateTypeModel>, region: string) {

        const tempRateTypes: Array<RateTypeModel> = new Array<RateTypeModel>();
        if (region) {
            rateTypes.forEach(element => {
                const regionListArray: string[] = element.regionList.split(",");
                if (element.simo != null && element.simo !== "?" && (regionListArray[0] === "" || regionListArray.indexOf(region) !== -1)) {
                    tempRateTypes.push(element);
                }
            });
        } else {
            rateTypes.forEach(element => {
                if (element.simo !== null && element.simo !== "?") {

                    tempRateTypes.push(element);

                }
            });
        }
        tempRateTypes.unshift(this.getNoneRateTypeModel());
        return tempRateTypes;
    }

    getSimoRateTypes(rateTypes: Array<RateTypeModel>, region: string) {
        const tempRateTypes: Array<RateTypeModel> = new Array<RateTypeModel>();
        if (region) {
            rateTypes.forEach(element => {
                const regionListArray: string[] = element.regionList.split(",");
                if (element.simo || element.simo == null || element.simo === "?" &&
                    (regionListArray[0] === "" || regionListArray.indexOf(region) !== -1)) {
                    tempRateTypes.push(element);
                }
            });
        } else {
            rateTypes.forEach(element => {
                if (element.simo || element.simo == null || element.simo === "?") {
                    tempRateTypes.push(element);
                }
            });
        }
        tempRateTypes.unshift(this.getNoneRateTypeModel());
        return tempRateTypes;
    }

    showAlertDialog(title: string, msg: string) {
        const modal = this.alertModalService.open(AlertDialogComponent);
        modal.componentInstance.name = msg;
        modal.componentInstance.title = title;
    }
    getFormattedAmount(value) {
        // let formattedValue: string;
        // if (!Number.isInteger(value)) {
        //   formattedValue = value.toFixed(2);
        // }
        // else {
        //   formattedValue = value.toString();
        // }


        return this.decimalPipe.transform(value);
    }
    getEncryptedPassword(pUnencrypted: string) {
        // pUnencrypted = "TestingPassword"
        const passwordCeilingNumber = Math.ceil(pUnencrypted.length / 26);
        let tLength: number = passwordCeilingNumber + 3;
        const encryptedPass = new Array<any>(tLength + Math.max(pUnencrypted.length, 128));
        const tStart = getRandomInt(1, 26);
        // let tStart = 11;
        encryptedPass[0] = (tStart + 96);
        const tOffset = getRandomInt(1, 26);
        // let tOffset = 19;
        encryptedPass[1] = (tOffset + 96);
        for (let i = 0; i < passwordCeilingNumber; i++) {
            encryptedPass[i + 2] = (pUnencrypted.substr((i * 26), 26).length + 96);
        }
        encryptedPass[tLength - 1] = ("=".charCodeAt(0));
        for (let i = tLength; i < tStart + tLength - 1; i++) {
            if (getRandomInt(1, 2) === 1) {
                encryptedPass[i] = (getRandomInt(97, 122));
            } else {
                encryptedPass[i] = (getRandomInt(65, 90));
            }

        }

        tLength = tStart + tLength - 1;
        for (let i = 0; i < pUnencrypted.length; i++) {
            let tAsc = (pUnencrypted.substr(i, 1)).charCodeAt(0);
            if (tAsc >= 65 && tAsc <= 90) {
                tAsc = tAsc + tOffset;
                if (tAsc > 90) {
                    tAsc = tAsc - 26;
                }
            }
            if (tAsc >= 97 && tAsc <= 122) {
                tAsc = tAsc + tOffset;
                if (tAsc > 122) {
                    tAsc = tAsc - 26;
                }
            }
            encryptedPass[tLength + i] = (tAsc);
        }
        tLength = tLength + pUnencrypted.length;
        for (let i = tLength; i < encryptedPass.length; i++) {
            if (getRandomInt(1, 2)) {
                encryptedPass[i] = (getRandomInt(97, 122));
            } else {
                encryptedPass[i] = (getRandomInt(65, 90));
            }

        }
        this.printLog(encryptedPass, true);
        const encryptedString = btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedPass)));
        let decryptedPass = atob(encryptedString);
        decryptedPass = decryptedPass.charCodeAt.call(decryptedPass, 0);
        return encryptedString;



    }

}


function createNoneTypeRateModel(context: CommonFunctions) {
    context.noneRateTypeModel = {
        description: "Please Select Rate Type",
        loanRateTypeCode: "none",
        ownerRateTypeCode: "none",
        scndLoanRateTypecode: "none",
        simo: true,
        priorPolicyAmountFlag: false,
        priorPolicyDateFlag: false,
        regionList: ""
    };
}

function getEndorsementsStateWise(allEndorsements: Array<EndorsementModel>, propertyTypeList: string,
    rateTypeList: string, region: string) {

    const endorsements: Array<EndorsementModel> = [];
    if (allEndorsements) {
        if (region) {
            allEndorsements.forEach(element => {

                const regionListArray: string[] = element.regionList.split(",");
                if (regionListArray[0] === "" || regionListArray.indexOf(region) !== -1) {
                    setEndorsement(element);
                }

            });
        } else {
            allEndorsements.forEach(element => {
                setEndorsement(element);
            });
        }
    }
    return endorsements;

    function setEndorsement(element: EndorsementModel) {
        const propertyTypeArray = element.propertyTypeList.split(",");
        const rateTypeArray = element.rateTypeList.split(",");;
        if (propertyTypeArray[0] === "" && rateTypeArray[0] === "") {
            element.visible = true;
            endorsements.push(element);
        } else if (propertyTypeArray[0] !== "" && rateTypeArray[0] !== "") {
            if (propertyTypeList && propertyTypeArray.indexOf(propertyTypeList) !== -1 &&
                rateTypeList && rateTypeArray.indexOf(rateTypeList) !== -1) {
                element.visible = true;
                endorsements.push(element);
            }
        } else if (propertyTypeList && propertyTypeArray.indexOf(propertyTypeList) !== -1) {
            element.visible = true;
            endorsements.push(element);
        } else if (rateTypeList && rateTypeArray.indexOf(rateTypeList) !== -1) {
            element.visible = true;
            endorsements.push(element);
        } else {
            element.visible = false;
            endorsements.push(element);
        }
    }
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getHexValueOfNumber(number) {
    // let hexNumber = number.toString(16);
    // if (hexNumber.length % 2) {
    //     hexNumber = '0' + hexNumber;
    // }
    return number;
}

function _arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

function parseHexString(str) {
    const result = [];
    while (str.length >= 8) {
        result.push(parseInt(str.substring(0, 8), 16));

        str = str.substring(8, str.length);
    }

    return result;
}
