export class ShareCalculatedPremiumModel {
    createdRequestString = "";
    premiumOwner = 0;
    premiumLoan = 0;
    premiumOEndors = 0;
    premiumLEndors = 0;
    premiumScnd = 0;
    premiumSEndors = 0;
    premiumCPL = 0;
    premiumLenderCPL = 0;
    premiumBuyerCPL = 0;
    premiumSellerCPL = 0;
    success = "";
    cardSetID = 0;
    effectiveDate = "";
    premiumOEndorsDetail = "";
    premiumLEndorsDetail = "";
    premiumSEndorsDetail = "";
    totalPremium: number;
}
