<div class="modal fade" id="Endorsementspremium" tabindex="-1" role="dialog" aria-labelledby="EndorsementsModalLabel" aria-hidden="true">
  <div class="modal-dialog Endorsementspremium" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="EndorsementsModalLabel">Endorsements Premium Detail</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="EndorsementsSearchBox">
          <h5 class="modal-title text-left" id="EndorsementsModalLabel">{{callFrom}}</h5>
        </div>
        <div class="EndorsementsList table-responsive-md">

          <table class="table table-hover table-sm">
            <thead>
              <tr class="top-row">
                <th scope="col">Code</th>
                <th scope="col">Description</th>
                <th scope="col">Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let endorsement of endorsements;let i=index" [ngClass]="getClass(i)">
                <th scope="row">{{endorsement.endorsementCode}}</th>
                <td>{{endorsement.description}}</td>
                <td> {{endorsement.proposedRate}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-center">
            <button type="button" class="btn cls-btn" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
