import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-alert-dialog",
    templateUrl: "./alert-dialog.component.html",
    styleUrls: ["./alert-dialog.component.css"]
})
export class AlertDialogComponent implements OnInit {

    @Input() name;
    @Input() title;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

}
