import { Injectable } from "@angular/core";
@Injectable({
    providedIn: "root"
})
export class API {
    baseUrl = "../assets/";
    albama = this.baseUrl + "AlabamaRateUI.json";
    georgia = this.baseUrl + "GeorgiaRateUI.json";
    georgiaV2 = this.baseUrl + "georgiarateuiv2.json";
    florida = this.baseUrl + "floridarateui.json";
    missouri = this.baseUrl + "missourirateui.json";
    mississippi = this.baseUrl + "mississippirateui.json";
    southCarolina = this.baseUrl + "southcarolinarateui.json";
    minnesota = this.baseUrl + "minnesotarateui.json";
    colorado = this.baseUrl + "coloradorateui.json";
    kansas = this.baseUrl + "kansasrateui.json";
    texas = this.baseUrl + "texasrateui.json";
    wisconsin = this.baseUrl + "wisconsinrateui.json";
    utah = this.baseUrl + "utahrateui.json";
    nevada = this.baseUrl + "nevadarateui.json";
    newmexico = this.baseUrl + "newmexicorateui.json";
    newmexicoV2 = this.baseUrl + "newmexicorateuiv2.json";
    newmexicoV3 = this.baseUrl + "newmexicorateuiv3.json";
    credential = this.baseUrl + "credential.json";
    states = this.baseUrl + "states.json";
    apiBaseUrl = "https://compass.alliantnational.com:8118/do/action/WService=";
    // we call and pass the app mode(dev,beta,prod) from CallWebApi.ts
    getBaseApiUrlJson(appMode: string) {
        return this.apiBaseUrl + appMode + "/act?I0=JSON&I3=rateCalculate&stateID=%s&UiInput=%s&I4=RCW";
    }
    // we call and pass the app mode(dev,beta,prod) from CallWebApi.ts
    getGeneratePdfUrlJson(appMode: string) {
        return this.apiBaseUrl + appMode + "/act?I0=json&I3=rateQuoteReport&state=%s&ResultsAttrib=%s&UiInfo=%s&InputParam=%s,"
            + "batchProcess^true&email=%s&download=%s&I4=RCW";
    }

    /// For development purpose leaving this in comment

    // ALABAMA_ONLINE = "http://compass.alliantnational.com:8008/RCM/alabamarateui.json";

    // BASE_API_URL_JSON = "https://compass.alliantnational.com:8118/do/action/WService=beta/get?I0=JSON&I1=%s&I2=%s
    // &I3=rateCalculate&stateID=%s&UiInput=%s&version=%s";
    // BASE_API_URL_XML = "https://compass.alliantnational.com:8118/do/action/WService=beta/get?I1=%s&I2=%s&I3=rateCalculate&stateID=%s
    // &UiInput=%s&version=%s";

    // GENERATE_PDF_URL_JSON = "https://compass.alliantnational.com:8118/do/action/WService=beta/get?I0=json&I1=%s&I2=%s&I3=
    // rateQuoteReport&state=%s&ResultsAttrib=%s&UiInfo=%s&InputParam=%s,batchProcess^true&email=%s&download=%s";
}
