import {
    Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef,
    OnDestroy, Output, EventEmitter, ViewChild, ElementRef
} from "@angular/core";
import { RegionModel } from "../../Models/RegionModel";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-region-model",
    templateUrl: "./region-model.component.html",
    styleUrls: ["./region-model.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionModelComponent implements OnInit, OnDestroy {

    constructor(private dataService: DataServiceService, private cdr: ChangeDetectorRef) { }
    regionsSubscriptions: Subscription = null;
    regionsArray: Array<RegionModel> = [];
    selectedIndex = -1;

    @Output()
    regionChange: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("closeARegionModal")
    closeARegionModal: ElementRef;

    ngOnInit() {

        this.regionsSubscriptions = this.dataService.allRegionsObservable.subscribe((reasons: Array<RegionModel>) => {
            this.regionsArray = reasons;
            this.cdr.markForCheck();
        });
    }
    radioChange(index: number) {
        this.selectedIndex = index;
    }
    onApplyClick() {
        if (this.selectedIndex >= 0) {
            this.regionChange.emit(this.selectedIndex);
            this.closeARegionModal.nativeElement.click();
        } else {
            alert("please select Region first");
        }
    }
    ngOnDestroy(): void {
        if (this.regionsSubscriptions && !this.regionsSubscriptions.closed) {
            this.regionsSubscriptions.unsubscribe();
        }
    }

}
