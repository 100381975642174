<div>
  <div class="unsupportedBrowserError" *ngIf="!browserSupported">
    <span>This site has been optimized for latest versions of Chrome, Firefox, Safari, Edge and IE11 and may not appear correctly in the older versions of browsers. 
    </span>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
  <div class="container-fluid main-header">

    <div>
      <header>

        <div class="row">

          <div class="col-3  logo-main">
            <img src="../../../assets/Alliant_National_Logo5.png" />
          </div>
          <div class="col-9">
            <h1 class="text-right">{{getDisplayFormattedState()}} Rate Calculator</h1>
          </div>
        </div>
      </header>
    </div>
  </div>
  <div class="container-fluid search-box">
    <div class="container">
      <form>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="icon-location">Selected State</label>
              <div class="state_name_box">
                <label *ngIf="getDisplayFormattedState() !== 'South Carolina' && getDisplayFormattedState() !== 'New Mexico'">{{getVisibleNameOfState(stateName)}}</label>
                <label *ngIf="getDisplayFormattedState() === 'South Carolina'">South Carolina</label>
                <label *ngIf="getDisplayFormattedState() === 'New Mexico'">New Mexico</label>

                <button type="button" class="btn btn-primary btn-lg btn-state" data-toggle="modal"
                  data-target="#StateModal">Change
                  State</button>
              </div>
            </div>
          </div>

          <div class="col-md-4" *ngIf="basicConfigJson&&basicConfigJson.regionAvailable" style="width: 100%;">
            <div class="form-group">
              <label class="icon-location">Region</label>
              <div class="state_name_box">
                <label class="region-select" placement="bottom" ngbPopover="{{selectedRegionDescription}}"
                  #p="ngbPopover" (document:click)="p.close()" (click)="$event.stopPropagation()"
                  title="{{selectedRegionDescription}}">{{selectedRegionDescription}}</label>

                <button type="button" class="btn btn-primary btn-lg btn-state" data-toggle="modal"
                  data-target="#RegionModal">Change Region</button>
              </div>
            </div>
          </div>
          <div [ngClass]="getPropertTypeClass()" style="width: 100%;">
            <div class="form-group">
              <label class="icon-property">Property Type</label>
              <select #stateDropdown class="form-control select2-selection minimal" style="width: 100%;"
                (change)="onPropertyTypeChange($event.target.value)">
                <option *ngFor="let item of propertyTypeArray;let i = index" [value]="i">{{item.description}}</option>
              </select>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
<!-- Model box for state ) -->
<app-state-model (onstateSelected)="onstateSelected($event)"></app-state-model>
<app-region-model (regionChange)="onRegionChange($event)"></app-region-model>
<router-outlet></router-outlet>