import { Injectable } from "@angular/core";
import * as states from "../Constants/States";
import { ShareCalculatedPremiumModel } from "../Models/ShareCalculatedPremiumModel";
@Injectable({
    providedIn: "root"
})

export class PremiumParser {
    shareCalculatedPremiumModel: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    constructor() { }

    parseResponse(response: any, stateId: string) {
        this.createCalculatedModel(response, stateId);
        return this.shareCalculatedPremiumModel;
    }

    createCalculatedModel(result, stateId: string) {
        this.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
        switch (stateId) {
            case states.ALABAMA_ID:
                addValueToAlabamaCalculatedModel(this, result);
                break;
            case states.GEORGIA_ID:
                addValueToGeorgiaCalculatedModel(this, result);
                break;
            case states.FLORIDA_ID:
                addValueToFloridaCalculatedModel(this, result);
                break;
            case states.MISSOURI_ID:
                addValueToMissouriCalculatedModel(this, result);
                break;
            case states.MISSISSIPPI_ID:
                addValueToMississippiCalculatedModel(this, result);
                break;
            case states.SOUTH_CAROLINA_ID:
                addValueToSouthCarolinaCalculatedModel(this, result);
                break;
            case states.COLORADO_ID:
                addValueToColoradoCalculatedModel(this, result);
                break;
            case states.MINNESOTA_ID:
                addValueToMinnesotaCalculatedModel(this, result);
                break;
            case states.KANSAS_ID:
                addValueToKansasCalculatedModel(this, result);
                break;
            case states.TEXAS_ID:
                addValueToTexasCalculatedModel(this, result);
                break;
            case states.WISCONSIN_ID:
                addValueToWisconsinCalculatedModel(this, result);
                break;
            case states.UTAH_ID:
                addValueToUtahCalculatedModel(this, result);
                break;
            case states.NEVADA_ID:
                addValueToNevadaCalculatedModel(this, result);
                break;
            case states.NEWMEXICO_ID:
                addValueToNewMexicoCalculatedModel(this, result);
                break;
            default:
                break;
        }
    }

}



function addValueToAlabamaCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumLenderCPL")) {
        context.shareCalculatedPremiumModel.premiumLenderCPL = json.premiumLenderCPL;
    }
    if (json.hasOwnProperty("premiumBuyerCPL")) {
        context.shareCalculatedPremiumModel.premiumBuyerCPL = json.premiumBuyerCPL;
    }
    if (json.hasOwnProperty("premiumSellerCPL")) {
        context.shareCalculatedPremiumModel.premiumSellerCPL = json.premiumSellerCPL;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}

function addValueToGeorgiaCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumSimo")) {
        const premiumSimo = json.premiumSimo;
        const premiumLoan = json.premiumLoan;
        context.shareCalculatedPremiumModel.premiumLoan = premiumLoan + premiumSimo;
    } else if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumScndLoan")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScndLoan;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumCPL")) {
        context.shareCalculatedPremiumModel.premiumCPL = json.premiumCPL;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}

function addValueToFloridaCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}

function addValueToMissouriCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumSimo")) {
        const premiumSimo = json.premiumSimo;
        const premiumLoan = json.premiumLoan;
        context.shareCalculatedPremiumModel.premiumLoan = premiumLoan + premiumSimo;
    } else if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }

    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("premiumCPL")) {
        context.shareCalculatedPremiumModel.premiumCPL = json.premiumCPL;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}

function addValueToMississippiCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumLenderCPL")) {
        context.shareCalculatedPremiumModel.premiumLenderCPL = json.premiumLenderCPL;
    }
    if (json.hasOwnProperty("premiumBuyerCPL")) {
        context.shareCalculatedPremiumModel.premiumBuyerCPL = json.premiumBuyerCPL;
    }
    if (json.hasOwnProperty("premiumSellerCPL")) {
        context.shareCalculatedPremiumModel.premiumSellerCPL = json.premiumSellerCPL;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}

function addValueToSouthCarolinaCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumLenderCPL")) {
        context.shareCalculatedPremiumModel.premiumLenderCPL = json.premiumLenderCPL;
    }
    if (json.hasOwnProperty("premiumBuyerCPL")) {
        context.shareCalculatedPremiumModel.premiumBuyerCPL = json.premiumBuyerCPL;
    }
    if (json.hasOwnProperty("premiumSellerCPL")) {
        context.shareCalculatedPremiumModel.premiumSellerCPL = json.premiumSellerCPL;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}
function addValueToColoradoCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumLenderCPL")) {
        context.shareCalculatedPremiumModel.premiumLenderCPL = json.premiumLenderCPL;
    }
    if (json.hasOwnProperty("premiumBuyerCPL")) {
        context.shareCalculatedPremiumModel.premiumBuyerCPL = json.premiumBuyerCPL;
    }
    if (json.hasOwnProperty("premiumSellerCPL")) {
        context.shareCalculatedPremiumModel.premiumSellerCPL = json.premiumSellerCPL;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
    if (json.hasOwnProperty("totalPremium")) {
        context.shareCalculatedPremiumModel.totalPremium = json.totalPremium;
    }
}
function addValueToMinnesotaCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}

function addValueToKansasCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}
function addValueToTexasCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}
function addValueToWisconsinCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}
function addValueToUtahCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}

function addValueToNevadaCalculatedModel(context: PremiumParser, json: any) {
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumLenderCPL")) {
        context.shareCalculatedPremiumModel.premiumLenderCPL = json.premiumLenderCPL;
    }
    if (json.hasOwnProperty("premiumBuyerCPL")) {
        context.shareCalculatedPremiumModel.premiumBuyerCPL = json.premiumBuyerCPL;
    }
    if (json.hasOwnProperty("premiumSellerCPL")) {
        context.shareCalculatedPremiumModel.premiumSellerCPL = json.premiumSellerCPL;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}

function addValueToNewMexicoCalculatedModel(context: PremiumParser, json: any){
    context.shareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    if (json.hasOwnProperty("premiumOwner")) {
        context.shareCalculatedPremiumModel.premiumOwner = json.premiumOwner;
    }
    if (json.hasOwnProperty("premiumLoan")) {
        context.shareCalculatedPremiumModel.premiumLoan = json.premiumLoan;
    }
    if (json.hasOwnProperty("premiumOEndors")) {
        context.shareCalculatedPremiumModel.premiumOEndors = json.premiumOEndors;
    }
    if (json.hasOwnProperty("premiumLEndors")) {
        context.shareCalculatedPremiumModel.premiumLEndors = json.premiumLEndors;
    }
    if (json.hasOwnProperty("premiumScnd")) {
        context.shareCalculatedPremiumModel.premiumScnd = json.premiumScnd;
    }
    if (json.hasOwnProperty("premiumSEndors")) {
        context.shareCalculatedPremiumModel.premiumSEndors = json.premiumSEndors;
    }
    if (json.hasOwnProperty("premiumLenderCPL")) {
        context.shareCalculatedPremiumModel.premiumLenderCPL = json.premiumLenderCPL;
    }
    if (json.hasOwnProperty("premiumBuyerCPL")) {
        context.shareCalculatedPremiumModel.premiumBuyerCPL = json.premiumBuyerCPL;
    }
    if (json.hasOwnProperty("premiumSellerCPL")) {
        context.shareCalculatedPremiumModel.premiumSellerCPL = json.premiumSellerCPL;
    }
    if (json.hasOwnProperty("premiumOEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumOEndorsDetail = json.premiumOEndorsDetail;
    }
    if (json.hasOwnProperty("premiumLEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumLEndorsDetail = json.premiumLEndorsDetail;
    }
    if (json.hasOwnProperty("premiumSEndorsDetail")) {
        context.shareCalculatedPremiumModel.premiumSEndorsDetail = json.premiumSEndorsDetail;
    }
    if (json.hasOwnProperty("success")) {
        context.shareCalculatedPremiumModel.success = json.success;
    }
    if (json.hasOwnProperty("cardSetID")) {
        context.shareCalculatedPremiumModel.cardSetID = json.cardSetID;
    }
    if (json.hasOwnProperty("effectiveDate")) {
        context.shareCalculatedPremiumModel.effectiveDate = json.effectiveDate;
    }
}


