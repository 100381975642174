import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";
import { BasicConfigModel } from "../../Models/BasicConfigModel";
import { ShareCplDataModel } from "../../Models/ShareCplDataModel";
import { ShareCalculatedPremiumModel } from "../../Models/ShareCalculatedPremiumModel";
import { Subscription } from "rxjs";

@Component({
    selector: "app-cpl",
    templateUrl: "./cpl.component.html",
    styleUrls: ["./cpl.component.css"]
})
export class CplComponent implements OnInit, OnDestroy {
    @Input()
    basicConfigJson: BasicConfigModel;
    totalCplAmount = 0;

    shareCplDataModel: ShareCplDataModel = new ShareCplDataModel();
    calculatedPremiumData: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    showComponent = true;

    rateTypeDataSubscripton: Subscription;
    onApiSuccessCalculatedPremiumSubscription: Subscription;
    reselAllFieldsSubscription: Subscription;
    resetFieldsForCalculatedPremiumSubscription: Subscription;

    premiumCalculated = false;
    resetFieldsFromThis = false;

    constructor(public dataService: DataServiceService,
        public commonFunctions: CommonFunctions) { }

    ngOnInit() {
        getCalculatedPremium(this);
        registerPropertyTypeChangeObservable(this);
        registerResetAllFields(this);
        registerResetAllFieldsForCal(this);
    }

    onLenderCplAmountChange(event) {
        this.resetDataAfterCalcuatePremium();
        this.calculatedPremiumData.premiumLenderCPL = event;
        sendDataToStatePage(this);
    }

    onBuyerCplAmountChange(event) {
        this.resetDataAfterCalcuatePremium();
        this.calculatedPremiumData.premiumBuyerCPL = event;
        sendDataToStatePage(this);
    }
    onSellerCplAmountChange(event) {
        this.resetDataAfterCalcuatePremium();
        this.calculatedPremiumData.premiumSellerCPL = event;
        sendDataToStatePage(this);
    }
    onShowHideClick(event) {
        this.showComponent = !this.showComponent;
    }

    public resetDataAfterCalcuatePremium() {
        if (this.premiumCalculated) {
            this.premiumCalculated = false;
            this.resetFieldsFromThis = true;
            resetFields(this);
            this.dataService.onResetFieldsForCalculatedPremium();
        }
    }

    getAmount(value: number) {
        return this.commonFunctions.getFormattedAmount(value);
    }

    ngOnDestroy(): void {

        if (this.rateTypeDataSubscripton && !this.rateTypeDataSubscripton.closed) {
            this.rateTypeDataSubscripton.unsubscribe();
        }
        if (this.onApiSuccessCalculatedPremiumSubscription && !this.onApiSuccessCalculatedPremiumSubscription.closed) {
            this.onApiSuccessCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.reselAllFieldsSubscription && !this.reselAllFieldsSubscription.closed) {
            this.reselAllFieldsSubscription.unsubscribe();
        }
        if (this.resetFieldsForCalculatedPremiumSubscription && !this.resetFieldsForCalculatedPremiumSubscription.closed) {
            this.resetFieldsForCalculatedPremiumSubscription.unsubscribe();
        }
    }
}

function sendDataToStatePage(context: CplComponent) {
    context.dataService.onCplDataChanged(createCplDataJson(context));
}

function createCplDataJson(context: CplComponent) {
    context.shareCplDataModel.lenderCpl = context.calculatedPremiumData.premiumLenderCPL ? context.calculatedPremiumData.premiumLenderCPL
        : 0;
    context.shareCplDataModel.buyerCpl = context.calculatedPremiumData.premiumBuyerCPL ? context.calculatedPremiumData.premiumBuyerCPL
        : 0;
    context.shareCplDataModel.sellerCpl = context.calculatedPremiumData.premiumSellerCPL ? context.calculatedPremiumData.premiumSellerCPL
        : 0;
    return context.shareCplDataModel;
}

function getCalculatedPremium(context: CplComponent) {
    context.onApiSuccessCalculatedPremiumSubscription = context.dataService.onApiSuccessCalculatedPremiumObservable.subscribe(
        calculatedPremium => {
            // context.calculatedPremiumData = calculatedPremium;
            context.premiumCalculated = true;
            context.totalCplAmount = calculatedPremium.premiumLenderCPL + calculatedPremium.premiumBuyerCPL +
                calculatedPremium.premiumSellerCPL;
        });
}

function registerPropertyTypeChangeObservable(context: CplComponent) {
    context.rateTypeDataSubscripton = context.dataService.rateTypeDataObservable.subscribe(endorsements => {
        resetFields(context);
    });
}

function registerResetAllFields(context: CplComponent) {
    context.reselAllFieldsSubscription = context.dataService.reselAllFieldsObservable.subscribe(function () {
        context.calculatedPremiumData = new ShareCalculatedPremiumModel();
        resetFields(context);
    });
}

function registerResetAllFieldsForCal(context: CplComponent) {
    context.resetFieldsForCalculatedPremiumSubscription = context.dataService.resetFieldsOnCalculatedPremiumObservable.subscribe(
        function () {
            context.premiumCalculated = false;
            if (!context.resetFieldsFromThis) {
                resetFields(context);
            } else {
                context.resetFieldsFromThis = false;
            }
        });
}

function resetFields(context: CplComponent) {
    //
    context.totalCplAmount = 0;
    sendDataToStatePage(context);

}


