<div class="modal fade" id="StateModal" tabindex="-1" role="dialog" aria-labelledby="StateModalLabel" aria-hidden="true">
  <div class="modal-dialog EndorsementsModal StateModal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="StateModalLabel">Select Your State</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeAStateModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center EndorsementsSearchBox">
          <form class="form-inline">
            <input class="form-control mr-sm-2" type="search" placeholder="Search State" aria-label="Search"
              [(ngModel)]="stateSearchText" name="search">
          </form>
        </div>
        <div class="state-list-box">
          <ul>
            <li *ngFor="let item of states | stateSearch:stateSearchText">
              <div class="form-check form-check-inline">
                <label>
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Alaska"
                    (change)="radioChange(item)">{{item}}
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-success" (click)="applyClick()">Apply</button>
            <button type="button" class="btn hide-second-loan-btn" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
