import { Pipe, PipeTransform } from "@angular/core";
import { EndorsementModel } from "../../Models/EndorsementModel";

@Pipe({
    name: "endorsementsSearch"
})
export class EndorsementsSearchPipe implements PipeTransform {

    transform(value: Array<EndorsementModel>, args?: any): any {
        if (!value) {
            return null;
        }
        if (!args) {
            return value;
        }
        args = args.toLowerCase();

        return value.filter(function (item) {
            return JSON.stringify(item.description).toLowerCase().includes(args)
                || JSON.stringify(item.endorsementCode).toLowerCase().includes(args);
        });
    }

}
