import { Component, Inject, Input, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subscription } from "rxjs";
import { Constants } from "../../Constants/Constants";
import { BasicConfigModel } from "../../Models/BasicConfigModel";
import { EndorsementModel } from "../../Models/EndorsementModel";
import { RateTypeModel } from "../../Models/RateTypeModel";
import { ShareCalculatedPremiumModel } from "../../Models/ShareCalculatedPremiumModel";
import { SharePremiumDataModel } from "../../Models/SharePremiumDataModel";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";

@Component({
    selector: "app-loan",
    templateUrl: "./loan.component.html",
    styleUrls: ["./loan.component.css"]

})
export class LoanComponent implements OnInit, OnDestroy {


    @Input()
    loanRateTypes: Array<RateTypeModel> = [];
    @Input()
    basicConfigJson: BasicConfigModel;
    tempLoanRatesArray: Array<RateTypeModel> = [];
    loanAmount = 0;
    tempLoanEndorsements: Array<EndorsementModel> = new Array<EndorsementModel>();
    loanEndorsement: Array<EndorsementModel> = new Array<EndorsementModel>();
    selectedEndorsementsArray: Array<EndorsementModel> = [];
    hideLoanPriorPolicyInput = false;
    selectedEndorsementsIdsArray = "";
    endorsementsTotal = 0;
    priorPolicyAmount = 0;
    ownerAmount = 0;
    selectedRateTypeCode = "";
    selectedRateType = "";
    hideSecondLoanButton = true;
    secondLoanVisible = false;
    showSecondButtonText = "";
    loanAllData: SharePremiumDataModel = new SharePremiumDataModel();
    calculatedPremiumData: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    showComponent = true;
    selectedRateTypeIndex = 0;
    selectedRateTypeModel: RateTypeModel = new RateTypeModel();
    effectiveDate: any;
    effectiveDateInString: string;
    maxEffectiveDate: NgbDateStruct = null;
    minEffectiveDate: NgbDateStruct = null;
    selectedPropertyType = "";
    disableCoverageAmount = false;
    disableRateType = false;
    disableEndorsementBtn = false;
    disableSecondLoanBtn = false;
    simoCheck = false;
    resetFieldsFromThis = false;
    premiumCalculated = false;
    selectedEndorsementsIds: Array<string> = [];
    selectedRegionId: string;

    endorsementDataSubscription: Subscription;
    getSelectedEndorsementsSubscription: Subscription;
    rateTypeDataSubscripton: Subscription;
    onOwnerDataChangedSubscription: Subscription;
    onApiSuccessCalculatedPremiumSubscription: Subscription;
    reselAllFieldsSubscription: Subscription;
    secondLoanSubscription: Subscription;
    selectedRegionSubscription: Subscription;
    resetFieldsForCalculatedPremiumSubscription: Subscription;

    showSecondLoanBtnCss = "";
    selectedState = "";
    ownerComponentAvaialable = true;
    secondLoanComponentAvaialable = true;

    nevadaLoanParcel: string;

    constructor(public commonFunctions: CommonFunctions,
        public dataService: DataServiceService,
        public constants: Constants,
        public cdr: ChangeDetectorRef,
        @Inject(LOCAL_STORAGE) public localStorage: WebStorageService) {
        this.showSecondButtonText = constants.showSecondLoan;
        this.showSecondLoanBtnCss = "btn btn-success second-loan-btn";
    }

    ngOnInit() {
        this.secondLoanVisible = false;
        const date = new Date();
        this.maxEffectiveDate = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
        this.minEffectiveDate = { year: 1950, month: 1, day: 1 };
        this.selectedState = this.localStorage.get(this.constants.selectedState);
        getSelectedRegion(this);
        getAndSetLoanRateType(this, false);
        getAllEndorsements(this);
        getSelectedEndorsements(this);
        getRateType(this);
        getOwnerChangedData(this);
        getSecondLoanChangedData(this);
        getCalculatedPremium(this);
        registerResetAllFields(this);
        resetFields(this);
        registerResetAllFieldsForCal(this);
    }

    onLoanRateTypeDropDownChanged(index) {
        if(this.loanRateTypes[index].description === "Builder's Loan") {
            this.nevadaLoanParcel = "builder";
        }
        this.onLoanRateTypeChanged(index);
        setAndGetEndorsements(false, "", this);
    }

    onLoanRateTypeChanged(index) {
        if (this.loanRateTypes.length > 0) {
            this.resetDataAfterCalcuatePremium(true);
            this.selectedRateTypeModel = this.loanRateTypes[index];
            this.hideLoanPriorPolicyInput = !this.selectedRateTypeModel.priorPolicyAmountFlag;
            this.selectedRateTypeCode = this.selectedRateTypeModel.loanRateTypeCode;
            this.selectedRateType = this.selectedRateTypeModel.description;
            if (this.hideLoanPriorPolicyInput) {
                this.priorPolicyAmount = 0;
            }

            if (!this.selectedRateTypeModel.priorPolicyDateFlag) {
                this.effectiveDateInString = "";
            }
            this.calculateAndSendTotalAmount();
        }
    }

    onLoanOpenEndorsementClick(event) {
        getSelectedEndorsementsIdsFromLocalStorage(this);
        const endorsementsWithTypeFlag = {
            endorsements: this.loanEndorsement,
            endorsementType: this.constants.endorsementTypeLoan,
            tempEndorsement: this.tempLoanEndorsements,
            selectedEndorsementsIds: this.selectedEndorsementsIds
        };
        this.dataService.onShowEndorsementDataChanged(JSON.stringify(endorsementsWithTypeFlag));
    }

    onCoverageAmountChange(value) {
        this.resetDataAfterCalcuatePremium(false);
        this.loanAmount = value;
        checksimo(this);
    }

    onPriorPolicyAmountChange(value) {
        this.resetDataAfterCalcuatePremium(false);
        this.priorPolicyAmount = value;
        this.calculateAndSendTotalAmount();
    }

    onEffectiveDateChanged(value) {
        this.resetDataAfterCalcuatePremium(false);
        getEffectiveDate(this, value);
        this.calculateAndSendTotalAmount();
    }

    onShowHideSecondButtonClick() {
        // if (this.secondLoanVisible)
        this.dataService.clearSecondLoanFields(true);
        this.secondLoanVisible = !this.secondLoanVisible;
        this.changeHideShowSecondLoanButtonText();
        this.calculateAndSendTotalAmount();
        this.cdr.markForCheck();
    }

    changeHideShowSecondLoanButtonText() {
        if (!this.secondLoanVisible) {
            this.showSecondButtonText = this.constants.showSecondLoan;
            this.showSecondLoanBtnCss = "btn btn-success second-loan-btn";

        } else {
            this.showSecondButtonText = this.constants.hideSecondLoan;
            this.showSecondLoanBtnCss = "btn hide-second-loan-btn second-loan-btn";

        }
    }

    calculateAndSendTotalAmount() {
        const json = createJsonForSendDataToStatePage(this);
        this.dataService.onLoanDataChanged(json);
    }
    onShowHideClick(event) {
        this.showComponent = !this.showComponent;
    }
    onShowCalculatedEndorsementClick(event) {
        const calculatedEndorsements = this.commonFunctions.getEndorsementDetail(this.selectedEndorsementsArray,
            this.calculatedPremiumData.premiumLEndorsDetail);
        const calculatedEndoPremDetail = {
            endorsements: calculatedEndorsements,
            callFrom: this.constants.loan
        };
        this.dataService.onShowCalculatedEndorsementDetailClick(calculatedEndoPremDetail);

    }

    onSimoCheckChange(event) {
        this.resetDataAfterCalcuatePremium(false);
        this.simoCheck = event;
        performSimo(this, event);
        this.commonFunctions.printLog(event, true);
    }

    public resetDataAfterCalcuatePremium(fromRateType: boolean) {
        if (this.premiumCalculated) {
            this.premiumCalculated = false;
            this.resetFieldsFromThis = true;
            resetFieldsAfterCalculation(this, fromRateType);
            this.dataService.onResetFieldsForCalculatedPremium();
        }
    }

    getAmount(value: number) {
        return this.commonFunctions.getFormattedAmount(value);
    }

    ngOnDestroy(): void {
        if (this.endorsementDataSubscription && !this.endorsementDataSubscription.closed) {
            this.endorsementDataSubscription.unsubscribe();
        }
        if (this.getSelectedEndorsementsSubscription && !this.getSelectedEndorsementsSubscription.closed) {
            this.getSelectedEndorsementsSubscription.unsubscribe();
        }
        if (this.rateTypeDataSubscripton && !this.rateTypeDataSubscripton.closed) {
            this.rateTypeDataSubscripton.unsubscribe();
        }
        if (this.onOwnerDataChangedSubscription && !this.onOwnerDataChangedSubscription.closed) {
            this.onOwnerDataChangedSubscription.unsubscribe();
        }
        if (this.onApiSuccessCalculatedPremiumSubscription && !this.onApiSuccessCalculatedPremiumSubscription.closed) {
            this.onApiSuccessCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.reselAllFieldsSubscription && !this.reselAllFieldsSubscription.closed) {
            this.reselAllFieldsSubscription.unsubscribe();
        }
        if (this.secondLoanSubscription && !this.secondLoanSubscription.closed) {
            this.secondLoanSubscription.unsubscribe();
        }
        if (this.resetFieldsForCalculatedPremiumSubscription && !this.resetFieldsForCalculatedPremiumSubscription.closed) {
            this.resetFieldsForCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.selectedRegionSubscription && !this.selectedRegionSubscription.closed) {
            this.selectedRegionSubscription.unsubscribe();
        }
    }
}

function getSelectedRegion(context: LoanComponent) {
    context.selectedRegionSubscription = context.dataService.shareSelectedRegionObservable.subscribe((selectedRegion: string) => {
        context.selectedRegionId = selectedRegion;
        if (context.loanRateTypes && context.loanRateTypes.length > 0) {
            context.loanEndorsement = context.commonFunctions.getEndorsements(context.constants.loanEndorsements,
                context.selectedPropertyType, context.selectedRateTypeCode, context.selectedRegionId);
            context.loanRateTypes = context.commonFunctions.getRateTypes(context.tempLoanRatesArray, context.selectedRegionId);
        }
    });
}

function getAllEndorsements(context: LoanComponent) {
    context.endorsementDataSubscription = context.dataService.endorsementDataObservable.subscribe(endorsements => {
        try {
            if (endorsements) {
                // resetFields(context);
                const loanEndorsements = JSON.stringify(JSON.parse(endorsements).loanEndorsements);
                context.tempLoanEndorsements = JSON.parse(loanEndorsements);
                const saveEndorsement = !context.commonFunctions.checkEndorsements(context.constants.loanEndorsements);
                setAndGetEndorsements(saveEndorsement, loanEndorsements, context);
            }

        } catch (error) {

        }
    });
}

function getSelectedEndorsementsIdsFromLocalStorage(context: LoanComponent) {
    context.selectedEndorsementsIds = context.commonFunctions.getSelectedEndorsementsIds(context.constants.loanEndorsements,
        context.selectedPropertyType);
}

function getSelectedEndorsements(context: LoanComponent) {
    context.getSelectedEndorsementsSubscription = context.dataService.getSelectedEndorsementsObservable.subscribe(
        endorsementsWithSelectedEndorsementsArray => {
            try {
                context.resetDataAfterCalcuatePremium(false);
                const parsedEndorsements = JSON.parse(endorsementsWithSelectedEndorsementsArray);
                if (parsedEndorsements.endorsementType === context.constants.endorsementTypeLoan) {
                    context.loanEndorsement = parsedEndorsements.endorsements;
                    setSelectedEndorsements(context, parsedEndorsements);
                }
            } catch (error) {

            }
        });
}

function setSelectedEndorsements(context: LoanComponent, parsedEndorsements: any) {
    context.selectedEndorsementsIdsArray = parsedEndorsements.selectedArray;
    // setAndGetEndorsements(true, JSON.stringify(parsedEndorsements.endorsements), context);
    context.commonFunctions.setSelectedEndorsementIds(context.constants.loanEndorsements, context.selectedEndorsementsIdsArray,
        context.selectedPropertyType);
    context.endorsementsTotal = parsedEndorsements.endorsementsTotal;
    context.selectedEndorsementsArray = parsedEndorsements.selectedEndorsementsArray;
    context.calculateAndSendTotalAmount();
}

function setAndGetEndorsements(setEndorsements: boolean, loanEndorsements: string, context: LoanComponent) {
    if (setEndorsements) {
        context.commonFunctions.setEndorsements(context.constants.loanEndorsements, loanEndorsements);
    }
    context.loanEndorsement = context.commonFunctions.getEndorsements(context.constants.loanEndorsements, context.selectedPropertyType,
        context.selectedRateTypeCode, context.selectedRegionId);
}

function getRateType(context: LoanComponent) {
    context.rateTypeDataSubscripton = context.dataService.rateTypeDataObservable.subscribe(rateTypes => {
        try {
            if (rateTypes) {
                const allRateTypes = JSON.parse(rateTypes);
                context.ownerComponentAvaialable = allRateTypes.hasOwnProperty("ownerRateType");
                context.secondLoanComponentAvaialable = allRateTypes.hasOwnProperty("scndLoanRateType");
                if (allRateTypes.hasOwnProperty("loanRateType")) {
                    context.selectedPropertyType = allRateTypes.propertyTypecode;
                    context.tempLoanRatesArray = allRateTypes.loanRateType;
                    context.loanRateTypes = context.commonFunctions.getRateTypes(context.tempLoanRatesArray, context.selectedRegionId);
                    context.onLoanRateTypeChanged(0);
                }
            }
        } catch (error) {

        }

    });
}

function getOwnerChangedData(context: LoanComponent) {
    context.onOwnerDataChangedSubscription = context.dataService.onOwnerDataChangedObservable.subscribe(ownerChangedDataJson => {
        if (ownerChangedDataJson) {
            if (context.ownerAmount !== ownerChangedDataJson.coverageAmount) {
                context.ownerAmount = ownerChangedDataJson.coverageAmount;
                enableDisableFields(context, context.ownerAmount > 0);
                checksimo(context);

            }
        }
    });
}

function getSecondLoanChangedData(context: LoanComponent) {
    context.secondLoanSubscription = context.dataService.onSecondLoanDataChangedObservable.subscribe(secondloanData => {
        if (secondloanData) {
            if (secondloanData.coverageAmount > 0 || secondloanData.selectedEndorsementsArray.length > 0
                || secondloanData.selectedRateTypeCode !== "none") {
                context.disableSecondLoanBtn = true;
            } else {
                context.disableSecondLoanBtn = false;
            }
        }
    });
}

function checksimo(context: LoanComponent) {

    if (context.basicConfigJson.manualSimo) {
        performSimo(context, context.simoCheck);
    } else {
        const simo = context.ownerAmount && context.ownerAmount > 0 && context.loanAmount && context.loanAmount > 0;
        performSimo(context, simo);
    }

}


function performSimo(context: LoanComponent, simo: boolean) {
    context.changeHideShowSecondLoanButtonText();
    if (simo) {
        context.hideSecondLoanButton = false;
        getAndSetLoanRateType(context, true);
    } else {
        context.disableSecondLoanBtn = false;
        context.secondLoanVisible = false;
        context.hideSecondLoanButton = true;
        getAndSetLoanRateType(context, false);
    }
}

function getAndSetLoanRateType(context: LoanComponent, showOnlySimo: boolean) {
    let filteredLoanRates: Array<RateTypeModel> = [];
    const simo = showOnlySimo ? context.constants.yes : context.constants.no;
    if (simo !== context.loanAllData.simultaneous) {
        if (showOnlySimo) {
            filteredLoanRates = context.commonFunctions.getSimoRateTypes(context.tempLoanRatesArray, context.selectedRegionId);
        } else {
            filteredLoanRates = context.commonFunctions.getRateTypes(context.tempLoanRatesArray, context.selectedRegionId);
        }
        context.loanRateTypes = filteredLoanRates;
        context.selectedRateTypeIndex = getSelectedRateTypeIndex(context);
        context.onLoanRateTypeChanged(context.selectedRateTypeIndex);
    } else {
        context.calculateAndSendTotalAmount();
    }
}
function getSelectedRateTypeIndex(context: LoanComponent) {
    let selectedIndex = 0;
    context.loanRateTypes.forEach((element, index) => {
        if (element.loanRateTypeCode === context.selectedRateTypeCode) {
            selectedIndex = index;
        }
    });

    return selectedIndex;
}
function createJsonForSendDataToStatePage(context: LoanComponent) {
    context.loanAllData.selectedEndorsementsArray = context.selectedEndorsementsArray;
    context.loanAllData.coverageAmount = context.loanAmount;
    context.loanAllData.priorPolicyAmount = context.priorPolicyAmount;
    context.loanAllData.selectedRateTypeCode = context.selectedRateTypeCode;
    context.loanAllData.secondLoanAdded = context.secondLoanVisible;
    context.loanAllData.simultaneous = context.hideSecondLoanButton ? context.constants.no : context.constants.yes;
    context.loanAllData.effectiveDate = context.effectiveDateInString;
    context.loanAllData.selectedRateType = context.selectedRateType;
    context.loanAllData.enablePriorPolicyAmount = !context.hideLoanPriorPolicyInput;
    context.loanAllData.effectiveDateAvailable = context.selectedRateTypeModel.priorPolicyDateFlag;

    return context.loanAllData;
}

function registerResetAllFields(context: LoanComponent) {
    context.reselAllFieldsSubscription = context.dataService.reselAllFieldsObservable.subscribe(function () {
        resetFields(context);
    });
}




function registerResetAllFieldsForCal(context: LoanComponent) {
    context.resetFieldsForCalculatedPremiumSubscription = context.dataService.resetFieldsOnCalculatedPremiumObservable.subscribe(
        function () {
            context.premiumCalculated = false;
            if (!context.resetFieldsFromThis) {
                resetFieldsAfterCalculation(context, false);
            } else {
                context.resetFieldsFromThis = false;
            }
        });
}

function resetFields(context: LoanComponent) {
    context.selectedEndorsementsArray = [];
    context.endorsementsTotal = 0;
    context.selectedEndorsementsIdsArray = "";
    context.priorPolicyAmount = 0;
    context.loanAmount = 0;
    context.secondLoanVisible = false;
    context.simoCheck = false;
    context.hideSecondLoanButton = true;
    checksimo(context);
    context.calculatedPremiumData = new ShareCalculatedPremiumModel();
    context.onLoanRateTypeChanged(0);
    context.selectedRateTypeIndex = 0;
    context.selectedRegionId = "";
}

function resetFieldsAfterCalculation(context: LoanComponent, fromRateType: boolean) {
    //   context.selectedEndorsementsArray = [];
    //  // context.loanEndorsement = context.tempLoanEndorsements;
    //   context.endorsementsTotal = 0;
    //   context.selectedEndorsementsIdsArray = "";
    //   context.priorPolicyAmount = 0;
    //   context.loanAmount = 0;
    //   context.secondLoanVisible = false;
    //   context.simoCheck = false;
    //   context.hideSecondLoanButton = true;
    //   checksimo(context);
    context.calculatedPremiumData = new ShareCalculatedPremiumModel();
    // if (!fromRateType) {
    //   context.onLoanRateTypeChanged(0);
    //   context.selectedRateTypeIndex = 0;
    // }
}


function getCalculatedPremium(context: LoanComponent) {
    context.onApiSuccessCalculatedPremiumSubscription = context.dataService.onApiSuccessCalculatedPremiumObservable.subscribe(
        calculatedPremium => {
            context.calculatedPremiumData = calculatedPremium;
            context.premiumCalculated = true;
        });
}

function getEffectiveDate(context: LoanComponent, value) {
    if (value.day < 10) {
        value.day = "0" + value.day;
    }

    if (value.month < 10) {
        value.month = "0" + value.month;
    }

    value.year = JSON.stringify(value.year).substring(2, 4);
    context.effectiveDateInString = value.month + "/" + value.day + "/" + value.year;
    // context.effectiveDate = context.effectiveDateInString
}

function enableDisableFields(context: LoanComponent, disableAllFields: boolean) {
    if (!disableAllFields) {
        disablefields(context, false);
    } else {
        if (context.selectedPropertyType === "C" && context.basicConfigJson.disableOtherPremiumOnConstruction) {
            resetFields(context);
            disablefields(context, true);
        } else {
            disablefields(context, false);
        }
    }
}

function disablefields(context: LoanComponent, disable: boolean) {
    context.disableCoverageAmount = disable;
    context.disableRateType = disable;
    context.disableEndorsementBtn = disable;
}
