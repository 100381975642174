import { Injectable } from "@angular/core";
import { BasicConfigModel } from "../Models/BasicConfigModel";
@Injectable({
    providedIn: "root"
})
export class BasicConfigStatewise {
    private basicConfigModel: BasicConfigModel;

    getAlabamaBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = true;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = true;
        this.basicConfigModel.availableLenderCpl = true;
        this.basicConfigModel.availableBuyerCpl = true;
        this.basicConfigModel.availableSellerCpl = true;
        this.basicConfigModel.manualSimo = false;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;

        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getGeorgiaBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = true;
        this.basicConfigModel.availableOwnerEndorsements = false;
        this.basicConfigModel.availableLoanEndorsements = false;
        this.basicConfigModel.availableSecondLoanEndorsements = false;
        this.basicConfigModel.availableOwnerReissue = false;
        this.basicConfigModel.availableLoanReissue = false;
        this.basicConfigModel.availableSecondLoanReissue = false;
        this.basicConfigModel.availableOnlyCpl = true;
        this.basicConfigModel.availableLenderCpl = false;
        this.basicConfigModel.availableBuyerCpl = false;
        this.basicConfigModel.availableSellerCpl = false;
        this.basicConfigModel.manualSimo = false;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getGeorgiaV2BasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = true;
        this.basicConfigModel.availableOwnerEndorsements = false;
        this.basicConfigModel.availableLoanEndorsements = false;
        this.basicConfigModel.availableSecondLoanEndorsements = false;
        this.basicConfigModel.availableOwnerReissue = false;
        this.basicConfigModel.availableLoanReissue = false;
        this.basicConfigModel.availableSecondLoanReissue = false;
        this.basicConfigModel.availableOnlyCpl = true;
        this.basicConfigModel.availableLenderCpl = false;
        this.basicConfigModel.availableBuyerCpl = false;
        this.basicConfigModel.availableSellerCpl = false;
        this.basicConfigModel.manualSimo = false;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getFloridaBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = false;
        this.basicConfigModel.availableCplComponent = false;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = false;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = false;
        this.basicConfigModel.availableOnlyCpl = false;
        this.basicConfigModel.availableLenderCpl = false;
        this.basicConfigModel.availableBuyerCpl = false;
        this.basicConfigModel.availableSellerCpl = false;
        this.basicConfigModel.manualSimo = false;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getMissouriBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = true;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = true;
        this.basicConfigModel.availableOnlyCpl = true;
        this.basicConfigModel.availableLenderCpl = false;
        this.basicConfigModel.availableBuyerCpl = false;
        this.basicConfigModel.availableSellerCpl = false;
        this.basicConfigModel.manualSimo = false;
        this.basicConfigModel.disableOtherPremiumOnConstruction = true;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getMississippiBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = true;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = true;
        this.basicConfigModel.availableLenderCpl = true;
        this.basicConfigModel.availableBuyerCpl = true;
        this.basicConfigModel.availableSellerCpl = true;
        this.basicConfigModel.manualSimo = true;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getSouthCarolinaiBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = true;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = false;
        this.basicConfigModel.availableOnlyCpl = false;
        this.basicConfigModel.availableLenderCpl = true;
        this.basicConfigModel.availableBuyerCpl = true;
        this.basicConfigModel.availableSellerCpl = true;
        this.basicConfigModel.manualSimo = true;
        this.basicConfigModel.disableOtherPremiumOnConstruction = true;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getMinnesotaBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = false;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = false;
        this.basicConfigModel.availableOnlyCpl = false;
        this.basicConfigModel.availableLenderCpl = false;
        this.basicConfigModel.availableBuyerCpl = false;
        this.basicConfigModel.availableSellerCpl = false;
        this.basicConfigModel.manualSimo = false;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getColoradoBasicConfig() {
        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = true;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = false;
        this.basicConfigModel.availableOnlyCpl = false;
        this.basicConfigModel.availableLenderCpl = true;
        this.basicConfigModel.availableBuyerCpl = true;
        this.basicConfigModel.availableSellerCpl = true;
        this.basicConfigModel.manualSimo = true;
        this.basicConfigModel.disableOtherPremiumOnConstruction = true;
        this.basicConfigModel.regionAvailable = true;

        return this.basicConfigModel;
    }

    getKansasBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = false;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = true;
        this.basicConfigModel.availableLenderCpl = false;
        this.basicConfigModel.availableBuyerCpl = false;
        this.basicConfigModel.availableSellerCpl = false;
        this.basicConfigModel.manualSimo = true;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getTexasBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = false;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = true;
        this.basicConfigModel.availableLenderCpl = false;
        this.basicConfigModel.availableBuyerCpl = false;
        this.basicConfigModel.availableSellerCpl = false;
        this.basicConfigModel.manualSimo = true;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getWisconsinBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = false;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = true;
        this.basicConfigModel.availableLenderCpl = false;
        this.basicConfigModel.availableBuyerCpl = false;
        this.basicConfigModel.availableSellerCpl = false;
        this.basicConfigModel.manualSimo = true;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getUtahBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = false;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = true;
        this.basicConfigModel.availableLenderCpl = false;
        this.basicConfigModel.availableBuyerCpl = false;
        this.basicConfigModel.availableSellerCpl = false;
        this.basicConfigModel.manualSimo = true;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;
        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getNevadaBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = true;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = true;
        this.basicConfigModel.availableLenderCpl = true;
        this.basicConfigModel.availableBuyerCpl = true;
        this.basicConfigModel.availableSellerCpl = true;
        this.basicConfigModel.manualSimo = false;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;

        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }

    getNewMexicoBasicConfig() {

        this.basicConfigModel = new BasicConfigModel();
        this.basicConfigModel.availableOwnerComponent = true;
        this.basicConfigModel.availableLoanComponent = true;
        this.basicConfigModel.availableSecondLoanComponent = true;
        this.basicConfigModel.availableCplComponent = true;
        this.basicConfigModel.availableOwnerEndorsements = true;
        this.basicConfigModel.availableLoanEndorsements = true;
        this.basicConfigModel.availableSecondLoanEndorsements = true;
        this.basicConfigModel.availableOwnerReissue = true;
        this.basicConfigModel.availableLoanReissue = true;
        this.basicConfigModel.availableSecondLoanReissue = true;
        this.basicConfigModel.availableLenderCpl = true;
        this.basicConfigModel.availableBuyerCpl = true;
        this.basicConfigModel.availableSellerCpl = true;
        this.basicConfigModel.manualSimo = true;
        this.basicConfigModel.disableOtherPremiumOnConstruction = false;

        this.basicConfigModel.regionAvailable = false;

        return this.basicConfigModel;
    }
}
