"use strict";
export const BROWSER_CHROME = "chrome";
export const BROWSER_FIREFOX = "firefox";
export const BROWSER_EDGE = "edge";
export const BROWSER_INTERNET_EXPLORER = "ie";
export const IOS = "ios";

export const MIN_SUPPORT_CHROME = 70;
export const MIN_SUPPORT_FIREFOX = 62;
export const MIN_SUPPORT_INTERNET_EXPLORER = 11;
export const MIN_SUPPORT_EDGE = 42;
export const MIN_SUPPORT_IOS = 7;
