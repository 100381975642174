import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "./services/apiService/api.service";
import { CommonFunctions } from "./Utils/CommonFunctions";
import { Constants } from "./Constants/Constants";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
    title = "app";


    constructor(private apiService: ApiService,
        private commonFunctions: CommonFunctions,
        private activatedRoute: ActivatedRoute,
        public constants: Constants,
        @Inject(LOCAL_STORAGE) public localStorage: WebStorageService,) {
        checkAppVersion(this);

    }

    ngOnInit() {

        this.activatedRoute.params.subscribe(params => {
            this.commonFunctions.printLog("current Url " + this.activatedRoute.snapshot.url.join(""), true);
            this.commonFunctions.printLog("params " + params, true);
            this.sendDataToHomeView(this.activatedRoute.snapshot.url.join(""));
        });

        // this.activatedRoute.queryParams.subscribe(map => {
        //   let state = map['state'];
        //   this.commonFunctions.printLog("params " + state, true);
        //   if (state) {
        //     this.dataservice.onStateChangedFromUrl(state);
        //   }
        //   else {
        //     state = stateNames.LAUNCHING_PATH;
        //     //this.location.go(state);
        //   }
        //   // if (state) {
        //   //   this.router.navigate(['states'], { queryParams: { statename: state } });
        //   // }
        //   // else {
        //   //   this.router.navigate(['states'], { queryParams: { statename: 'all' } });
        //   // }


        // });

    }

    sendDataToHomeView(state: string) {
        // if (state) {
        //   this.dataservice.onStateChangedFromUrl(state);
        // }
        // // else {
        // //   state = stateNames.LAUNCHING_PATH;
        // //   //this.location.go(state);
        // // }

        // this.router.navigate([state], { queryParams: { statename: state } });
    }
}
function checkAppVersion(context: AppComponent) {
    const version: number = context.localStorage.get(context.constants.currentAppVersionKey);
    if (version !== context.constants.currentAppVersion) {
        localStorage.clear();
        context.localStorage.set(context.constants.currentAppVersionKey, context.constants.currentAppVersionKey);
    }
}
