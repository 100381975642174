import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";
import { BasicConfigModel } from "../../Models/BasicConfigModel";
import { ShareCplDataModel } from "../../Models/ShareCplDataModel";
import { ShareCalculatedPremiumModel } from "../../Models/ShareCalculatedPremiumModel";
import { Subscription } from "rxjs";

@Component({
    selector: "app-only-cpl",
    templateUrl: "./only-cpl.component.html",
    styleUrls: ["./only-cpl.component.css"]
})
export class OnlyCplComponent implements OnInit, OnDestroy {

    @Input()
    basicConfigJson: BasicConfigModel;
    totalCplAmount = 0;

    shareCplDataModel: ShareCplDataModel = new ShareCplDataModel();
    calculatedPremiumData: ShareCalculatedPremiumModel = new ShareCalculatedPremiumModel();
    showComponent = true;
    premiumCalculated = false;
    resetFieldsFromThis = false;
    cpl: any = 0;

    rateTypeDataSubscripton: Subscription;
    onApiSuccessCalculatedPremiumSubscription: Subscription;
    reselAllFieldsSubscription: Subscription;
    resetFieldsForCalculatedPremiumSubscription: Subscription;

    constructor(public dataService: DataServiceService,
        public commonFunctions: CommonFunctions) { }

    ngOnInit() {
        getCalculatedPremium(this);
        registerPropertyTypeChangeObservable(this);
        registerResetAllFields(this);
        registerResetAllFieldsForCal(this);
    }

    onCplAmountChange(event) {
        this.resetDataAfterCalcuatePremium();
        this.cpl = event;
        this.calculatedPremiumData.premiumCPL = event;
        sendDataToStatePage(this);
    }

    onShowHideClick(event) {
        this.showComponent = !this.showComponent;
    }


    public resetDataAfterCalcuatePremium() {
        if (this.premiumCalculated) {
            this.premiumCalculated = false;
            this.resetFieldsFromThis = true;
            resetFields(this);
            this.dataService.onResetFieldsForCalculatedPremium();
        }
    }

    ngOnDestroy(): void {

        if (this.rateTypeDataSubscripton && !this.rateTypeDataSubscripton.closed) {
            this.rateTypeDataSubscripton.unsubscribe();
        }
        if (this.onApiSuccessCalculatedPremiumSubscription && !this.onApiSuccessCalculatedPremiumSubscription.closed) {
            this.onApiSuccessCalculatedPremiumSubscription.unsubscribe();
        }
        if (this.reselAllFieldsSubscription && !this.reselAllFieldsSubscription.closed) {
            this.reselAllFieldsSubscription.unsubscribe();
        }
        if (this.resetFieldsForCalculatedPremiumSubscription && !this.resetFieldsForCalculatedPremiumSubscription.closed) {
            this.resetFieldsForCalculatedPremiumSubscription.unsubscribe();
        }
    }
}

function sendDataToStatePage(context: OnlyCplComponent) {
    context.dataService.onCplDataChanged(createCplDataJson(context));
}

function createCplDataJson(context: OnlyCplComponent) {
    context.commonFunctions.printLog(context.cpl, true);
    context.shareCplDataModel.cpl = context.cpl;
    return context.shareCplDataModel;
}

function getCalculatedPremium(context: OnlyCplComponent) {
    context.onApiSuccessCalculatedPremiumSubscription = context.dataService.onApiSuccessCalculatedPremiumObservable.subscribe(
        calculatedPremium => {
            context.calculatedPremiumData = calculatedPremium;
            context.premiumCalculated = true;
            context.totalCplAmount = context.calculatedPremiumData.premiumCPL;
        });
}

function registerPropertyTypeChangeObservable(context: OnlyCplComponent) {
    context.rateTypeDataSubscripton = context.dataService.rateTypeDataObservable.subscribe(endorsements => {
        resetFields(context);
    });
}

function registerResetAllFields(context: OnlyCplComponent) {
    context.reselAllFieldsSubscription = context.dataService.reselAllFieldsObservable.subscribe(function () {
        resetFields(context);
    });
}
function registerResetAllFieldsForCal(context: OnlyCplComponent) {
    context.resetFieldsForCalculatedPremiumSubscription = context.dataService.resetFieldsOnCalculatedPremiumObservable.subscribe(
        function () {
            context.premiumCalculated = false;
            if (!context.resetFieldsFromThis) {
                resetAllFieldsAfterCalculation(context);
            } else {
                context.resetFieldsFromThis = false;
            }
        });
}


function resetFields(context: OnlyCplComponent) {
    context.calculatedPremiumData = new ShareCalculatedPremiumModel();
    context.totalCplAmount = 0;
    context.cpl = 0;
    sendDataToStatePage(context);

}

function resetAllFieldsAfterCalculation(context: OnlyCplComponent) {
    context.calculatedPremiumData = new ShareCalculatedPremiumModel();
    context.totalCplAmount = 0;
    sendDataToStatePage(context);
}
