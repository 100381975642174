import { Injectable } from "@angular/core";
@Injectable({
    providedIn: "root"
})
export class Constants {
    endorsementTypeOwner = 0;
    endorsementTypeLoan = 1;
    endorsementTypeSecondLoan = 2;
    showSecondLoan = "SHOW SECOND LOAN";
    hideSecondLoan = "HIDE SECOND LOAN";
    none = "none";
    na = "NA";

    // parameters keys
    ownerRateTypeCode = "ratetypecode";
    ownerRateType = "ratetype";
    ownerCoverageAmount = "coverageamount";
    ownerReissueAmount = "reissuecoverageamount";

    loanCoverageAmount = "loancoverageamount";
    loanRateTypeCode = "loanratetypecode";
    loanRateType = "loanratetype";
    loanReissueAmount = "loanreissuecoverageamount";


    secondLoanRateTypeCode = "secondloanratetypecode";
    secondLoanRateType = "secondloanratetype";
    secondLoanCoverageAmount = "secondloancoverageamount";

    ownerEndorsements = "ownerendors";
    loanEndorsements = "loanendors";
    secondLoanEndorsements = "secondloanendors";

    effectiveDate = "effectivedate";
    priorEffectiveDate = "prioreffectivedate";
    loanPriorEffectiveDate = "loanprioreffectivedate";
    simultaneous = "simultaneous";
    propertyType = "propertytype";

    currentAppVersionKey = "current_app_version_key";
    region = "region";
    regionValue = "";

    cpl = "cpl";
    lenderCpl = "lendercpl";
    buyerCpl = "buyercpl";
    sellerCpl = "sellercpl";

    selectedState = "selected_state";

    owner = "owner";
    loan = "loan";
    secondLoan = "second loan";

    pdfGenerationPreFormData = "prf_generation_pre_form_data";
    pdfFormDataWithFileNumber = "pdf_form_data_with_file_number";

    fileNumber = "filenumber";
    seller = "seller";
    buyer = "buyer";
    reference = "reference";
    property = "property";
    state = "state";
    county = "county";
    batchProcess = "batchprocess";
    andSymbol = "&";
    email = "email";
    download = "download";

    pdfSent = "Premium rate quote has been sent to %s.";
    downloadPdfTitle = "%s_premium_rate_quote_for_file_%s";
    emailSentTitle = "Email sent";
    alertErrorTitle = "error";

    simultaneousRateTypeThreshold = 2;

    yes = "yes";
    no = "no";
    // error messages
    errorNoDataEntered = "Please enter coverage amount";
    errorOwnerCoverageAmount = "Owner coverage amount cannot be left blank";
    errorOwnerRateType = "Please select owner rate type";
    errorOwnerEffectiveDate = "Please enter owner effective date";
    errorLoanCoverageAmount = "Loan coverage amount cannot be left blank";
    errorLoanRateType = "Please select loan rate type";
    errorLoanEffectiveDate = "Please enter loan effective date";
    errorSecondLoanCoverageAmount = "Second loan coverage amount cannot be left blank";
    errorSecondLoanRateType = "Please select second loan rate type";
    errorNoInternetConnecton = "Kindly check your internet connection";
    errorPriorPolicyAmount = "Prior policy amount can not left blank";

    // georgia v2 error codes
    twoAmountMustSelected = "Atleast two amounts must be entered for simultaneous";
    simultanoeusMustForTwo = "Simultaneous must be selected for two amounts";

    // error codes
    noInternetConnectionErrorCode = 4;

    currentAppVersion = 1;




}
