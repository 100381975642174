import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef,
    Input, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy
} from "@angular/core";
import { Subscription } from "rxjs";
import { EndorsementModel } from "../../Models/EndorsementModel";
import { EndorsementsSearchPipe } from "../../Pipes/endorsement-search-pipe/endorsements-search.pipe";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";

@Component({

    selector: "app-endorsement-model",
    templateUrl: "./endorsement-model.component.html",
    styleUrls: ["./endorsement-model.component.css"]
})
export class EndorsementModelComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild("closeEndorsementModal")
    closeEndorsementModal: ElementRef;

    @ViewChild("selectAllCheck")
    selectAllCheck: ElementRef;
    @Input()
    endorsements: Array<EndorsementModel>;
    tempArray: EndorsementModel[];
    selectedEndorsementsArray: Array<EndorsementModel> = [];
    endorsementsType: any = 0;
    endorsementSearchText = "";
    selectedEndorsementsIdsArray: Array<string> = [];
    showEndorsementsSubscription: Subscription;
    tempEndorsementArray: Array<EndorsementModel> = [];
    endorsementDataSubscription: Subscription;
    endorsementProposedRateFlag = false;
    allEndorsementsChecked = true;
    model: any = {
        onColor: "primary",
        offColor: "secondary",
        offText: "Off",
        disabled: false,
        onText: "On",
        size: "Normal",
        value: true
    };

    constructor(
        private commonFunctions: CommonFunctions,
        public dataServiceService: DataServiceService,
        public detectChanges: ChangeDetectorRef
    ) { }

    ngOnInit() {
        getEndorsementProposedRateFlag(this);
        this.showEndorsementsSubscription = this.dataServiceService.showEndorsementObservable.subscribe(
            endorsements => {
                if (endorsements) {
                    this.endorsementSearchText = "";
                    const parsedEndorsementArrayWithType = JSON.parse(endorsements);
                    this.endorsementsType = parsedEndorsementArrayWithType.endorsementType;
                    this.endorsements = parsedEndorsementArrayWithType.endorsements;
                    this.tempEndorsementArray = parsedEndorsementArrayWithType.endorsements;
                    this.selectedEndorsementsIdsArray = parsedEndorsementArrayWithType.selectedEndorsementsIds;
                    this.onCalculatorTypeChanged();
                    // checkAllSelected(this);
                    detectChange(this);
                    this.commonFunctions.printLog("selected w length " + JSON.stringify(this.endorsements), false);
                }
            }
        );

        // var element = document.getElementById('selectAllCheck');

        // this.selectAllCheck.nativeElement.onchange(function (event) {
        //   this.commonFunctions.printLog("change event " + event, true);
        // });
    }

    ngAfterViewInit(): void {
        this.detectChanges.markForCheck();
    }
    onCalculatorTypeChanged() {
        this.selectedEndorsementsArray = [];

        this.endorsements.forEach(element => {
            if (this.selectedEndorsementsIdsArray.indexOf(element.endorsementCode) !== -1) {
                this.selectedEndorsementsArray.push(element);
            }
        });
    }

    onEndorsementCheckChanged(event, index, endorsementModel: EndorsementModel) {

        detectChange(this);
        const selectedEndorsementIndex = this.selectedEndorsementsIdsArray.indexOf(
            endorsementModel.endorsementCode
        );
        this.commonFunctions.printLog("index " + selectedEndorsementIndex, true);
        if (selectedEndorsementIndex > -1) {
            this.selectedEndorsementsIdsArray.splice(selectedEndorsementIndex, 1);
            this.selectedEndorsementsArray.splice(selectedEndorsementIndex, 1);
        } else {
            this.selectedEndorsementsIdsArray.push(endorsementModel.endorsementCode);
            this.selectedEndorsementsArray.push(endorsementModel);
        }
        checkAllSelected(this);
    }

    onApplyClick(event) {

        this.commonFunctions.printLog("Endorsement on Apply Clicked", true);
        let endorsementsTotal = 0;
        this.selectedEndorsementsArray.forEach(element => {
            endorsementsTotal = endorsementsTotal + element.proposedRate;
        });
        this.sendDataToCalculators(endorsementsTotal);
        this.closeEndorsementModal.nativeElement.click();
    }

    sendDataToCalculators(endorsementsTotal) {
        const selectedEndorsementsObject = {
            selectedArray: this.selectedEndorsementsIdsArray.join(" , "),
            endorsements: this.tempEndorsementArray,
            endorsementType: this.endorsementsType,
            endorsementsTotal: endorsementsTotal,
            selectedEndorsementsArray: this.selectedEndorsementsArray
        };
        this.dataServiceService.onSelectedEndorsementApplyClick(
            JSON.stringify(selectedEndorsementsObject)
        );
    }

    onClearClick(event) {
        this.selectedEndorsementsIdsArray = [];
        this.endorsements = this.tempEndorsementArray;
        this.selectedEndorsementsArray = [];
    }
    ngOnDestroy(): void {
        if (
            this.showEndorsementsSubscription &&
            !this.showEndorsementsSubscription.closed
        ) {
            this.showEndorsementsSubscription.unsubscribe();
        }
    }
    getVisibleEndorsement(value) {
        if (this.endorsementProposedRateFlag) {
            return true;
        } else {
            return false;
        }
    }

    onSelectUnselectAllCheckedChanged(checked) {
        this.allEndorsementsChecked = checked;
        this.commonFunctions.printLog("select changed " + this.allEndorsementsChecked, true);
        this.selectedEndorsementsIdsArray = [];
        this.selectedEndorsementsArray = [];

        const updatedEndorsements: EndorsementModel[] = [];
        this.updateEndorsements(updatedEndorsements);
    }

    private updateEndorsements(updatedEndorsements: EndorsementModel[]) {
        if (this.allEndorsementsChecked) {
            this.endorsements.forEach(element => {
                if (element.visible) {
                    this.selectedEndorsementsIdsArray.push(element.endorsementCode);
                    element.checked = true;
                    this.selectedEndorsementsArray.push(element);
                }
                updatedEndorsements.push(element);
            });
        } else {
            this.endorsements.forEach(element => {
                element.checked = false;
                updatedEndorsements.push(element);
            });
        }
        this.endorsements = updatedEndorsements;
    }

    onSearchChange() {
        if (this.endorsementSearchText) {
            this.endorsements = new EndorsementsSearchPipe().transform(this.tempEndorsementArray, this.endorsementSearchText);
        } else {
            this.endorsements = this.tempEndorsementArray;
        }

    }

    isEndorsementChecked(endorsement: EndorsementModel) {
        if (this.selectedEndorsementsIdsArray.indexOf(endorsement.endorsementCode) !== -1) {
            endorsement.checked = true;
        } else {
            endorsement.checked = false;
        }
        return endorsement.checked;
    }

}
function getEndorsementProposedRateFlag(context: EndorsementModelComponent) {
    context.endorsementDataSubscription = context.dataServiceService.endorsementDataObservable.subscribe(
        endorsements => {
            try {
                if (endorsements) {
                    context.endorsementProposedRateFlag = JSON.parse(endorsements).showPropsedRateFlag;
                }
            } catch (error) { }
        }
    );
}

function checkAllSelected(context: EndorsementModelComponent) {
    setTimeout(() => {

        if (context.selectedEndorsementsIdsArray.length === context.endorsements.length) {
            context.model.value = false;
            context.allEndorsementsChecked = false;
        } else {
            context.model.value = true;
            context.allEndorsementsChecked = true;
        }
        detectChange(context);
    }, 100);
}
function detectChange(context: EndorsementModelComponent) {
    context.detectChanges.detectChanges();
    context.detectChanges.markForCheck();
}

