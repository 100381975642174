import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { RequestResponseModel } from "../../Models/RequestResponseModel";
import { ShareCalculatedPremiumModel } from "../../Models/ShareCalculatedPremiumModel";
import { SharePremiumDataModel } from "../../Models/SharePremiumDataModel";
import { RegionModel } from "../../Models/RegionModel";

@Injectable({
    providedIn: "root"
})
export class DataServiceService {
    // All Endorsement
    private endorsementDataSubject = new BehaviorSubject("");
    endorsementDataObservable = this.endorsementDataSubject.asObservable();

    // Rate Type According to State
    private rateTypeSubject = new BehaviorSubject("");
    rateTypeDataObservable = this.rateTypeSubject.asObservable();

    // Endorsements which are going to show on Alert Box
    private showEndorsementSubject = new BehaviorSubject("");
    showEndorsementObservable = this.showEndorsementSubject.asObservable();

    // Selected Endorsements from user
    private onSelectedEndorsementsSubject = new Subject<string>();
    getSelectedEndorsementsObservable = this.onSelectedEndorsementsSubject.asObservable();

    // User Manually enter state
    private onStateEnteredSubject = new Subject<string>();
    getEnteredStateObservable = this.onStateEnteredSubject.asObservable();

    // on owner page data changed
    private onOwnerDataChangedSubject = new BehaviorSubject<SharePremiumDataModel>(null);
    onOwnerDataChangedObservable = this.onOwnerDataChangedSubject.asObservable();

    // on loan page Data changed
    private onLoanDataChangedSubject = new BehaviorSubject<SharePremiumDataModel>(null);
    onLoanDataChangedObservable = this.onLoanDataChangedSubject.asObservable();

    // on loan page Data changed
    private onSecondLoanDataChangedSubject = new BehaviorSubject<SharePremiumDataModel>(null);
    onSecondLoanDataChangedObservable = this.onSecondLoanDataChangedSubject.asObservable();

    // cpl page data
    private oncplsDataChangedSubject = new Subject<any>();
    onCplDataChangedObservable = this.oncplsDataChangedSubject.asObservable();

    // share Calculated Data
    private onApiSuccessCalculatedPremiumSubject = new Subject<ShareCalculatedPremiumModel>();
    onApiSuccessCalculatedPremiumObservable = this.onApiSuccessCalculatedPremiumSubject.asObservable();

    // hide show loader while doing long running tasks
    private hideShowLoaderSubject = new Subject<boolean>();
    hideShowLoaderObservable = this.hideShowLoaderSubject.asObservable();

    private resetAllFieldsSubject = new Subject<any>();
    reselAllFieldsObservable = this.resetAllFieldsSubject.asObservable();

    // when click on show calculated endorsement detail
    private calculatedEndorsementDetailSubject = new Subject<any>();
    calculatedEndorsementDetailObservable = this.calculatedEndorsementDetailSubject.asObservable();

    // share pdf pre form data
    private sharePdfPreFormDataSubject = new Subject<any>();
    sharePdfPreFormDataObservable = this.sharePdfPreFormDataSubject.asObservable();

    // share request response json to pdf form component
    private requestResponseSubject = new Subject<RequestResponseModel>();
    requestResponseObservable = this.requestResponseSubject.asObservable();

    private resetFieldsOnCalculatedPremiumSubject = new Subject<any>();
    resetFieldsOnCalculatedPremiumObservable = this.resetFieldsOnCalculatedPremiumSubject.asObservable();

    private shareSelectedStateSubject = new BehaviorSubject("");
    shareSelectedStateObservable = this.shareSelectedStateSubject.asObservable();

    private shareSelectedRegionSubject = new BehaviorSubject<string>("");
    shareSelectedRegionObservable = this.shareSelectedRegionSubject.asObservable();

    private allRegionsSubject = new Subject();
    allRegionsObservable = this.allRegionsSubject.asObservable();

    private clearSecondLoanFieldSubject = new BehaviorSubject(true);
    clearSecondLoanFieldObservable = this.clearSecondLoanFieldSubject.asObservable();

    constructor() { }
    onSelectedEndorsementApplyClick(endorsementsWithSelectedEndorsements: any) {
        this.onSelectedEndorsementsSubject.next(endorsementsWithSelectedEndorsements);
    }

    onEndorsementDataChanged(data: any) {
        this.endorsementDataSubject.next(data);
    }

    onRateTypeDataChanged(rateTypes: any) {
        this.rateTypeSubject.next(rateTypes);
    }

    onShowEndorsementDataChanged(endorsements: any) {
        this.showEndorsementSubject.next(endorsements);
    }

    onStateChangedFromUrl(statename: string) {
        this.onStateEnteredSubject.next(statename);
    }

    onOwnerDataChanged(ownerDataChanged: any) {
        this.onOwnerDataChangedSubject.next(ownerDataChanged);
    }

    onLoanDataChanged(loanChangedData: any) {
        this.onLoanDataChangedSubject.next(loanChangedData);
    }

    onSecondLoanDataChanged(secondLoanChangedData: any) {
        this.onSecondLoanDataChangedSubject.next(secondLoanChangedData);
    }

    onCplDataChanged(cplData: any) {
        this.oncplsDataChangedSubject.next(cplData);
    }

    onCalculatedDataApiSuccess(calculatedData: ShareCalculatedPremiumModel) {
        this.onApiSuccessCalculatedPremiumSubject.next(calculatedData);
    }

    onHideShowLoader(showLoader: boolean) {
        this.hideShowLoaderSubject.next(showLoader);
    }

    onResetAllFieldsClick() {
        this.resetAllFieldsSubject.next();
    }

    onShowCalculatedEndorsementDetailClick(endorsementData: any) {
        this.calculatedEndorsementDetailSubject.next(endorsementData);
    }
    onGeneratePdfClick() {
        this.sharePdfPreFormDataSubject.next();
    }

    setRequestResponse(requestResponseData: RequestResponseModel) {
        this.requestResponseSubject.next(requestResponseData);
    }

    onResetFieldsForCalculatedPremium() {
        this.resetFieldsOnCalculatedPremiumSubject.next();
    }

    onStateSelected(selectedState: string) {
        this.shareSelectedStateSubject.next(selectedState);
    }
    shareSelectedRegion(selectedRegion: string) {
        this.shareSelectedRegionSubject.next(selectedRegion);
    }

    sendAllRegionsToReasonsModel(regions: Array<RegionModel>) {
        this.allRegionsSubject.next(regions);
    }

    clearSecondLoanFields(clear: boolean) {
        this.clearSecondLoanFieldSubject.next(clear);
    }
}
