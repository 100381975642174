import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-email-model",
    templateUrl: "./email-model.component.html",
    styleUrls: ["./email-model.component.css"]
})
export class EmailModelComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
