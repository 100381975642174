<div style="overflow: hidden;">
    <app-home-view [basicConfigJson]="basicConfigJson"></app-home-view>
    <form>
      <div class="row">
        <app-owner [hidden]="hideAppOwner" [basicConfigJson]="basicConfigJson"
          (onOwnerTotalAmountChanged)="onOwnerTotalAmountChanged($event)" *ngIf="basicConfigJson.availableOwnerComponent">
        </app-owner>
  
        <app-loan [hidden]="hideAppLoan" [basicConfigJson]="basicConfigJson"
          *ngIf="basicConfigJson.availableLoanComponent"></app-loan>
  
        <app-second-loan [basicConfigJson]="basicConfigJson" [hidden]="hideAppSecondLoan"></app-second-loan>
  
        <app-cpl [basicConfigJson]="basicConfigJson"></app-cpl>
  
        <app-total [basicConfigJson]="basicConfigJson" (calculateClick)="onCalculateClick($event)"></app-total>
      </div>
    </form>
  </div>
  
  
  <!-- Model box for Endorsements ) -->
  
  <app-endorsement-model ></app-endorsement-model>
  <!-- Model box for Email ) -->
  <app-email-model></app-email-model>
  <!--detailed endorsements model-->
  <app-calculated-endorsements-detail></app-calculated-endorsements-detail>



